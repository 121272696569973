import { Component, Input, OnInit } from '@angular/core';
import moment, { Moment } from 'moment';
import { BcAccountsService, School } from 'src/app/bc-accounts/bc-accounts.service';
import { AssessmentType, BcAssessmentsService, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { AccountType } from 'src/app/constants/account-types';
import { BreadcrumbsService } from 'src/app/core/breadcrumbs.service';
import { LangService } from 'src/app/core/lang.service';
import { IMenuTabConfig } from 'src/app/ui-partial/menu-bar/menu-bar.component';
import { getG4SecureMaterialList, getG7SecureMaterialList, getGradMinistrySpecificSecureMaterialList, getGeneralGradSecureMaterialList, getGeneralFsaSecureMaterialList, getG4SampleMaterialList, getG7SampleMaterialList, MaterialList } from './data';
import { ActivatedRoute, Router } from '@angular/router';
import { SECURE_LOGIN_SYS_FLAG } from 'src/app/ui-whitelabel-bc-landing/view-secure-login/model';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';

enum AssessmentMaterialsTab {
  SECURE = 'Secure',
  SAMPLE = 'Sample'
}

@Component({
  selector: 'bc-assessment-materials',
  templateUrl: './bc-assessment-materials.component.html',
  styleUrls: ['./bc-assessment-materials.component.scss']
})
export class BcAssessmentMaterialsComponent implements OnInit {

  @Input() breadcrumbProxy: any;

  testWindow: TestWindow;
  testWindows: TestWindow[];

  isLoading: boolean = false;

  tabs: IMenuTabConfig<AssessmentMaterialsTab>[] = [
    { id: AssessmentMaterialsTab.SECURE, caption: 'Secure Assessments Materials' }
  ];
  initialTab = AssessmentMaterialsTab.SECURE;
  selectedTab = AssessmentMaterialsTab.SECURE;
  AssessmentMaterialsTab = AssessmentMaterialsTab;

  secureMaterialLists: MaterialList[] = [];

  expandAll: boolean = false;

  asmtAvailability: Record<SECURE_LOGIN_SYS_FLAG, boolean>;
  currentAccountType: AccountType;
  currentSchool: School;
  isOffShore: boolean;

  isFsa: boolean;
  constructor(
    private bcAssessments: BcAssessmentsService,
    private bcAccounts: BcAccountsService,
    private breadcrumbsService: BreadcrumbsService,
    private lang: LangService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private routes: RoutesService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    console.log("bc-assessment-materials.component.ts");
    this.auth.user().subscribe(userInfo => {
      if (userInfo) {
        this.currentAccountType = userInfo.accountType;
        this.initMaterials();
        this.updateBreadcrumb();
      }
      //   const {school}  = this.route.snapshot.queryParams;
      //   if(school){
      //     this.bcAccounts.getSchoolDetail(school).then(data => {
      //       // this.isFsa = data.isFsa;
      //       this.updateBreadcrumb();
      //       this.initMaterials();
      //     })
      //   }else {
      //     this.initMaterials();
      //     this.updateBreadcrumb();
      //   }
      // }
    })
  }

  onPasswordSheetClick = async (forceLang) => {
    if(!this.passwordInfoSheet?.is_for_sa || !this.passwordInfoSheet?.is_active_for_sa) return;
    return await this.auth.apiFind(this.routes.PASSWORD_INFO_SHEET, {query: {test_window_id: this.testWindow.id, get_url: true, schoolAdmin: this.auth.isSchoolAdmin(), lang: forceLang}} );
  }
  passwordInfoSheet;
  checkStatus = async () => {
    const res = await this.auth.apiFind(this.routes.PASSWORD_INFO_SHEET, {query: {test_window_id: this.testWindow.id}} );
    this.passwordInfoSheet = res;
  }

  initMaterials(){
    this.bcAssessments.asmtAvailabilitySub().subscribe(async availability => {
      this.asmtAvailability = availability;

      await this.updateMaterialLists();
    })
  }

  isGradMinistryAdmin() {
    return this.auth.isGradMinistryAdmin(this.currentAccountType);
  }

  isGradSchoolAdmin() {
    return this.isSchoolAdmin() && !this.isFSA();
  }

  isSchoolAdmin() {
    return this.auth.isSchoolAdmin();
  }

  isNotGrad() {
    return !this.isGradSchoolAdmin() && !this.isGradMinistryAdmin()
  }

  isGrad() {
    return this.isGradSchoolAdmin() || this.isGradMinistryAdmin()
  }

  getTestWindowTitle(testWindow: TestWindow): string {
    return this.bcAssessments.getTestWindowTitle(testWindow);
  }

  onSelectedTestWindowChange(testWindow: TestWindow) {
    this.testWindow = testWindow;
    this.checkStatus();
    this.updateMaterialLists();
  }

  private async updateMaterialLists() {
    if (!this.testWindow || !this.asmtAvailability) return;

    const disabledList = [];
    const hiddenList = [];

    // Hidden list for both GradSchoolAdmin and GradMinistryAdmin
    hiddenList.push('grad_session_password_ltf12');

    // Disable list for both GradSchoolAdmin and GradMinistryAdmin
    // disabledList.push('grad_session_password');
    disabledList.push('grad_session_password_ltf12');

    let secureMaterialLists;
    if (this.isGradSchoolAdmin()) {
      const selectedSchoolGroupId = this.route.snapshot.queryParamMap.get('school');
      const schools = await this.bcAccounts.getSchoolsSimpleDetails([parseInt(selectedSchoolGroupId)]);
      const selectedSchool = schools[0];

      if (selectedSchool.isOOP) {
        hiddenList.push('grad_session_password');
        hiddenList.push('grad_session_password_ltf12');
      }

      secureMaterialLists = [
        getGeneralGradSecureMaterialList(disabledList, hiddenList)
      ];
    }
    else if (this.isGradMinistryAdmin()) {
      secureMaterialLists = [
        getGeneralGradSecureMaterialList(disabledList, hiddenList),
        getGradMinistrySpecificSecureMaterialList()
      ];
    }
    else {
      secureMaterialLists = [
        getGeneralFsaSecureMaterialList(),
        this.getMaterialListWithFixedColumnAvailability(getG4SecureMaterialList),
        this.getMaterialListWithFixedColumnAvailability(getG7SecureMaterialList),
      ];

      const isAvailable = this.asmtAvailability[SECURE_LOGIN_SYS_FLAG.FSA];
      secureMaterialLists[1].columnAvailable[3] = isAvailable;
      secureMaterialLists[2].columnAvailable[3] = isAvailable;
    }
    this.secureMaterialLists = secureMaterialLists;
  }

  isLinkDisabled(link:{isDisabled?:boolean, isDelayedAccess?:boolean, isRestricted?: boolean}, i: number){
    // if(this.isOffShore && i == 1){
    //   return true;
    // }
    if (link.isDisabled){
      return true;
    }
    if(link.isRestricted){
      if(!this.passwordInfoSheet?.is_for_sa || !this.passwordInfoSheet?.is_active_for_sa) return true;
    }
    if (link.isDelayedAccess){
      const isAsmtAvail = this.isFSA() ? this.bcAssessments.isFsaAdminAvailable() : this.bcAssessments.isGradAdminAvailable() ;
      if (!isAsmtAvail){
        return true;
      }
    }
    return false
  }

  async selectTab(tab: AssessmentMaterialsTab) {
    this.selectedTab = tab;
    this.expandAll = true;
    this.toggleExpandAll();
    this.updateBreadcrumb();
  }

  toggleExpanded(list: MaterialList) {
    list.expanded = !list.expanded;
    if (!this.getMaterialListsByAssessment().find(ml => ml.expanded)) this.expandAll = false;
    else if (!this.getMaterialListsByAssessment().find(ml => !ml.expanded)) this.expandAll = true;
  }

  async tra(slug: string, index: number, forcedLang?:string, isDynamicLang?:string, link?:{isDisabled?:boolean, isDelayedAccess?:boolean, isRestricted?: boolean}) {
    let url = "";
    if(link.isRestricted){
      url = await this.onPasswordSheetClick(forcedLang);
    }else{
      if(isDynamicLang){
        url = this.lang.tra(slug);
      }
      if (forcedLang){
        url = this.lang.tra(slug, forcedLang);
      }
      if (index == 0) {
        url = this.lang.tra(slug, "en");
      } else {
        url = this.lang.tra(slug, "fr");
      }  
    }
    const newlink = document.createElement('a');
    newlink.setAttribute('target', '_blank');
    newlink.setAttribute('href', url);
    document.body.appendChild(newlink);
    newlink.click();
    newlink.remove();
  }

  langTra(slug: string, forcedLang?:string){
    return this.lang.tra(slug, forcedLang);
  }

  toggleExpandAll() {
    if (this.selectedTab === AssessmentMaterialsTab.SECURE) {
      this.expandAll = !this.expandAll;
      this.getMaterialListsByAssessment().map(ml => ml.expanded = this.expandAll)
    }
  }

  getReleaseDate(): Moment | null {
    if (!this.testWindow) return null
    return moment(this.testWindow.dateStart).subtract(2, 'weeks');
  }

  getReleaseDateString(): string {
    const releaseDate = this.getReleaseDate();
    if (!releaseDate) return '';
    return releaseDate.format('DD/MM/YYYY');
  }

  getMaterialListWithFixedColumnAvailability(getMaterialList: () => MaterialList): MaterialList {
    const ml = getMaterialList();
    ml.columnAvailable[0] = this.getReleaseDate().isSameOrBefore(moment(), 'date');
    return ml
  }

  getMaterialListsByAssessment(): MaterialList[] {
    if (this.selectedTab === AssessmentMaterialsTab.SECURE) {
      // console.log(this.secureMaterialLists);
      return this.secureMaterialLists;
    }
    return null;
  }

  getBaseRoute() {
    if (this.isGradMinistryAdmin()) return `/${this.lang.c()}/${AccountType.MINISTRY_ADMIN}`;
    else return `/${this.lang.c()}/${AccountType.SCHOOL_ADMIN}`;
  }

  getDashboardRoute() {
    if (this.isGradMinistryAdmin()) return `/${this.lang.c()}/${AccountType.MINISTRY_ADMIN}/bc-grad/dashboard`;
    else if (this.isGradSchoolAdmin()) return `/${this.lang.c()}/${AccountType.SCHOOL_ADMIN}/bc-grad/dashboard`;
    else return `/${this.lang.c()}/${AccountType.SCHOOL_ADMIN}/bc-fsa/dashboard`;
  }

  getBaseViewRoute() {
    if (this.isGradMinistryAdmin()) return `${this.getBaseRoute()}/bc-grad/assessment_materials`;
    else if (this.isGradSchoolAdmin()) return `/${this.getBaseRoute()}/bc-grad/assessment_materials`;
    else return `${this.getBaseRoute()}/bc-fsa/assessment_materials`;
  }

  getViewRoute() {
    return this.getBaseViewRoute();
  }

  isFSA(): boolean {
    return this.route.snapshot.data['schoolType'] === 'BC_FSA';
  }

  updateBreadcrumb() {
    this.breadcrumbProxy.clear();

    if (this.isGradMinistryAdmin()) {
      this.breadcrumbProxy.push(this.breadcrumbsService._CURRENT(this.lang.tra('ma_ministryTitle') + this.lang.tra('sa_grad_bcgrad'), this.getDashboardRoute()));
    } else if (this.isSchoolAdmin()) {
      let admin = this.lang.tra('sa_grad_dashboard_school_admin');
      if (!this.isFSA()) admin += this.lang.tra('sa_grad_bcgrad');
      else admin += this.lang.tra('sa_fsa_bcfsa');
      this.breadcrumbProxy.push(this.breadcrumbsService._CURRENT(admin, this.getDashboardRoute()));
    }
    this.breadcrumbProxy.push(this.breadcrumbsService._CURRENT(this.lang.tra('sa_assessment_material_bc'), this.getBaseViewRoute()));
  }

  onSelectedSchoolChange(school: School) {
    this.bcAccounts.getSchoolDetail(school.groupId).then(schoolInfo => {
      this.bcAccounts.getDistrictDetail(schoolInfo.district.groupId).then(districtInfo => {
        this.isOffShore = districtInfo.is_offshore
      })
    })
  }
}
