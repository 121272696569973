<div class="assessment-materials">
    <div [ngClass]="{'hideFromGrad': isGrad() }" class="header-container">
        <!-- fka sa-header-layout -->
        <bc-header-layout [title]="'sa_assessment_material_bc'"
            [imgSrc]="'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/assessment_materials/1610134259593/assessment_materials.svg'"
            (school)="onSelectedSchoolChange($event)" (testWindow)="onSelectedTestWindowChange($event)">
        </bc-header-layout>
    </div>

    <div>
        <div>
            <button *ngIf="!this.isGradSchoolAdmin()" class="hide-lists-button" (click)="toggleExpandAll()">
                <ng-container *ngIf="expandAll">
                    <tra slug="sa_am_hide_all"></tra>
                </ng-container>
                <ng-container *ngIf="!expandAll">
                    <tra slug="sa_am_show_all"></tra>
                </ng-container>
            </button>

            <div class="material-list" *ngFor="let list of getMaterialListsByAssessment()">

                <button *ngIf="!this.isGradSchoolAdmin()" class="expand-button" (click)="toggleExpanded(list)">
                    <i class="fa fa-caret-down" [class.expanded]="list.expanded"></i>
                </button>
                <div *ngIf="!this.isGradSchoolAdmin()" class="list-title" (click)="toggleExpanded(list)">
                    <tra [slug]="list.title"></tra>
                </div>
                
                <button *ngIf="!this.isGradSchoolAdmin()" class="expand-button">
                </button>
                <div *ngIf="this.isGradSchoolAdmin()" class="list-title list-title-noCursor">
                    <tra [slug]="list.title"></tra>
                </div>

                <div class="list-subtitle" *ngIf="list.subtitle">
                    <tra [slug]="list.subtitle"></tra>
                </div>
                <table *ngIf="list.expanded" class="list-table">
                    <tbody>
                        <tr *ngFor="let row of list.rows, index as i">
                            <td>
                                <tra [slug]="row"></tra>
                            </td>
                            <td>
                                <div *ngFor="let col of list.columns, index as j">
                                    <div *ngIf="j % 2 == 0">
                                        <!-- <div class="unavailable-column" *ngIf="!list.columnAvailable[i]"><tra slug="sa_am_unavail_release_data"></tra>
                                            {{getReleaseDateString()}}</div> -->
                                        <div class="list-links">
                                            <div class="links-header">
                                                <tra [slug]="col"></tra>
                                            </div>
                                            <ul>
                                                <ng-container *ngFor="let link of list.data[i][j]">
                                                    <li *ngIf="!link.isHidden">
                                                        <ng-container *ngIf="isLinkDisabled(link, i)">
                                                            <a class="links unavailable"><tra-md [isCondensed]="true" [slug]="link.name" [langOverride]="link.forceLang"></tra-md></a>
                                                        </ng-container>
                                                        <ng-container *ngIf="!isLinkDisabled(link, i)">
                                                            <a (click)="tra(link.link, i, link.forceLang, link.isDynamicLang, link)" target="_blank"
                                                                rel="noreferrer noopener" class="links"><tra-md [isCondensed]="true" [slug]="link.name"  [langOverride]="link.forceLang"></tra-md></a>
                                                            <!-- [class.unavailable]="!list.columnAvailable[j]" -->
                                                        </ng-container>
                                                    </li>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div *ngFor="let col of list.columns, index as j">
                                    <div *ngIf="j % 2 == 1">
                                        <!-- <div class="unavailable-column" *ngIf="!list.columnAvailable[i]"><tra slug="sa_am_unavail_release_data"></tra>
                                            {{getReleaseDateString()}}</div> -->
                                        <div class="list-links">
                                            <div class="links-header">
                                                <tra [slug]="col"></tra>
                                            </div>
                                            <ul>
                                                <ng-container *ngFor="let link of list.data[i][j]">
                                                    <li *ngIf="!link.isHidden">
                                                        <ng-container *ngIf="isLinkDisabled(link, i)">
                                                            <a class="links unavailable"><tra-md [isCondensed]="true" [slug]="link.name"  [langOverride]="link.forceLang"></tra-md></a>
                                                        </ng-container>
                                                        <ng-container *ngIf="!isLinkDisabled(link, i)">
                                                            <a (click)="tra(link.link, i, link.forceLang, link.isDynamicLang, link)" target="_blank"
                                                                rel="noreferrer noopener" class="links"><tra-md [isCondensed]="true" [slug]="link.name"  [langOverride]="link.forceLang"></tra-md></a>
                                                            <!-- [class.unavailable]="!list.columnAvailable[j]" -->
                                                        </ng-container>
                                                    </li>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>