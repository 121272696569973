<div class="split-example ex-pixel" id="page" [ngStyle]="{'font-size': zoomLevel+'em'}" aria-hidden="false" aria- #page>
    <as-split unit="pixel" direction="horizontal" gutterSize="30" (dragEnd)="log($event)" style="height:100vh;">
        <as-split-area size="*" minSize="300" style="position:relative;overflow-x:scroll;" id="split-area">
            <div class="screen" id="screen">
                <div class="undo-redo-bar">
                    <button *ngIf="removeStack.length > 0 && !review" class="button btn is-small is-grey" (click)="undo()">Undo block deletion&nbsp;<i class="fas fa-undo-alt"></i></button>
                    <button *ngIf="redoStack.length > 0 && !review" class="button btn is-small is-grey" (click)="redo()">Redo block deletion&nbsp;<i class="fas fa-redo-alt"></i></button>

                </div>
                <h1 *ngIf="!review" class="block-inserters" style="margin-top:2em; font-size:1em;">
                    Use the buttons below to respond to the question using text, tables, or freeform drawings.
                </h1>
                <h1 *ngIf="review" class="block-inserters" style="margin-top:2em; font-size:1em; padding-left:1em" aria-label="Review mode">
                    <div>
                        On this page you can review your work before moving on to the next question.
                    </div>
                    <div>
                        Go back and re-read each question followed by your answers.
                    </div>
                    <div>
                        If the question has more than one part, ensure you’ve answered each part of the question.
                    </div>
                    <div>
                        If you would like to edit your answers, click the “Go Back” button at the bottom of the screen. Once you are happy with your response, click the "Go to Next Question" button at the bottom of the screen. You will be able to review your answers to all of the questions before submitting.
                    </div>
                </h1>

                <div class="blocks" cdkDropList (cdkDropListDropped)="drop(blocks, $event);">
                    <div *ngFor="let block of blocks; let index = index;" cdkDrag [cdkDragDisabled]="review" id="blocks">
                        <div class="solution-block" id="{{ 'block' + index }}">
                            <button *ngIf="!review" class="button is-white is-handle" cdkDragHandle aria-label="DragBlock">
                                <i class="fas fa-sort" aria-hidden="true"></i>
                            </button>
                            <div class="solution-block-content" [ngSwitch]="block.blockType">
                                <div *ngSwitchCase="BlockTypes.TEXT" [class.hide-ckeditor-toolbar]="!block.isInFocus" #text class="ckdiv">
                                    <!-- <element-render-text-constr></element-render-text-constr> -->
                                    <ckeditor [editor]="Editor" [config]="ckEditorConfig" (ready)="onReady($event)" (focus)="block.isInFocus=true"></ckeditor>
                                </div>
                                <div *ngSwitchCase="BlockTypes.MATH">
                                    <capture-math></capture-math>
                                </div>
                                <div *ngSwitchCase="BlockTypes.TABLE" #table id="{{ 'tableBlock' + index }}">
                                    <element-render-authtool-table [block]="block" [index]="index" [tableBlock]="tableBlock" [review]="review"></element-render-authtool-table>
                                </div >
                                <div *ngSwitchCase="BlockTypes.DIAGRAM" [ngClass]="{'drawing': true}" #diagram>
                                    <element-render-drawing [mode]="mode" [id]="index"></element-render-drawing>
                                </div>
                            </div>
                            <button *ngIf="!review" class="button is-white is-handle" (click)="removeElement(blocks, index)">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="!review" #constrRespInput class="buttons block-inserters">
                    <button *ngFor="let block of availableBlocks" [disabled]="isBlockInsertBuffered(block.id)" (click)="insertBlock(block.id)" class="button btn block-selector">
              <div class="icon"><i  [ngClass]="block.icon"></i></div>
              <div> {{block.caption}} </div>
            </button>
                </div>
                <div *ngIf="blocks.length && !review" style="margin:0 5em; margin-bottom: 2em;">
                    <hr>
                    <div class='editing-review-button-container'>
                        <div class='editing-review-button-popup'>Review Your Work!</div>
                        <button class="button is-info review-button editing-review-button" (click)="toggleReview()">Review</button>
                        <!-- <button class="button" (click)="showNext()">Next Question</button> -->
                    </div>
                </div>


            </div>

            <div *ngIf="review" class="review">
                <div class="review__content">
                    <div style="margin:0 2.5em;">
                        <div class="review-button-container">
                            <button class="button is-info review-button" (click)="toggleReview()"><i class="fa fa-chevron-left" aria-hidden="true"></i><span class='left'>Go Back</span></button>
                            <button class="button is-info review-button" (click)="showNext()"><span class='right'>Go To Next Question</span><i class="fa fa-chevron-right" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </as-split-area>
        <as-split-area [size]="readingSelectionWidth" minSize="300" style="position:relative;overflow-x:scroll;">

            <div class="simulated-question-text">

                <div style="margin-bottom:1.5em; width:100%; display: flex; align-items: center; justify-content: space-between;">
                    <div style="display: flex; align-items: center; ">
                        <button class="button btn is-small is-grey" id="instr-btn" aria-controls="modal1" tabindex="0" (click)="toggleInstructions()" #popupBtn>Keyboard Shortcuts</button>
                    </div>
                    <div style="display: flex; align-items: center;">
                        <span style="font-size: 1rem;" *ngIf="zoomLevel !== 1">{{zoomLevel*100}}%</span>
                        <button class="button btn is-small" (click)="zoomLevel=zoomLevel+0.25" aria-disabled="false" [disabled]="isMaxZoom(zoomLevel)">Zoom In</button>
                        <button class="button btn is-small" (click)="zoomLevel=zoomLevel-0.25" aria-disabled="false" [disabled]="isMinZoom(zoomLevel)">Zoom Out</button>
                    </div>
                </div>
                <em>Read the question below and answer each part using the response blocks on the right side of the screen.</em>
                <br/>
                <p aria-roledescription="Questiontitle">High-efficiency appliances and fixtures can help reduce the amount of water we use.</p>
                <div style="padding:1em; border-radius:2em; border:0.15em solid #1a6eb7; margin-top:0.5em; margin-bottom:0.5em;">
                    <div style="font-weight: 600; text-align: center; line-height: 110%; font-size: 1.2em; color: #1a6eb7;">
                        Indoor Water Use with<br> High-Efficiency Appliances and Fixtures
                    </div>
                    <img alt="image alt text" style="width: 32em;" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/Screen%20Shot%202020-08-28%20at%208.07.52%20PM/1598661582381/Screen%20Shot%202020-08-28%20at%208.07.52%20PM.png">
                </div>
                <div>
                    <p>You want to reduce your personal water use to 1050 L/week. You install high-efficiency appliances and fixtures, and change your water-use habits.</p>
                    <p>Plan a water budget for yourself for 1 week that meets this goal using the high-efficiency appliances and fixtures.</p>
                    <p>Explain and justify your solution.</p>
                    <p>You must use everything in the table below at least once in the week. </p>
                </div>

                <table class="temp-stim-table">
                    <tr>
                        <th style="background-color: #d0e2f2"> High-Efficiency Appliances and Fixtures </th>
                    </tr>
                    <tr>
                        <td>
                            <div class="cell-content">
                                <div class="image-container">
                                    <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/Screen%20Shot%202020-08-31%20at%2012.29.04%20PM/1598891373342/Screen%20Shot%202020-08-31%20at%2012.29.04%20PM.png">
                                </div>
                                Shower and/or bath
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="cell-content">
                                <div class="image-container">
                                    <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/Screen%20Shot%202020-08-31%20at%2012.29.08%20PM/1598891443509/Screen%20Shot%202020-08-31%20at%2012.29.08%20PM.png">
                                </div>
                                Toilet
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="cell-content">
                                <div class="image-container">
                                    <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/Screen%20Shot%202020-08-31%20at%2012.29.11%20PM/1598891458160/Screen%20Shot%202020-08-31%20at%2012.29.11%20PM.png">
                                </div>
                                Tap
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="cell-content">
                                <div class="image-container">
                                    <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/Screen%20Shot%202020-08-31%20at%2012.29.14%20PM/1598891469718/Screen%20Shot%202020-08-31%20at%2012.29.14%20PM.png">
                                </div>
                                Dishwasher
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="cell-content">
                                <div class="image-container">
                                    <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/21/authoring/Screen%20Shot%202020-08-31%20at%2012.29.19%20PM/1598891479216/Screen%20Shot%202020-08-31%20at%2012.29.19%20PM.png">
                                </div>
                                Clothes Washer
                            </div>
                        </td>
                    </tr>
                </table>
                <p>This question can be answered on paper or in the space provided on the right. </p>
            </div>
        </as-split-area>
    </as-split>
</div>
<!---Instructions modal box begins here-->
<div class="instr-popup" #popup id="modal1" aria-modal="true" role=”dialog” aria-hidden="true">
    <div class="instr-popup__box">
        <div class="instr-popup__box--header">
            <div class="intsr-popup__box--header-contents" style="width: 100%;height: 100%;display: flex;align-items: center; justify-content: space-between;font-size: 1rem;">
                <h1 style="color:whitesmoke;">Keyboard Shortcuts</h1>
                <button style="border:none; background:transparent;" (click)="removeInstructions()" aria-label="closemodal" tabindex="0">
          <i class="fas fa-times cancel"  style="font-size:1.6rem; color:whitesmoke; cursor:pointer;"></i>
         </button>
            </div>
        </div>
        <div class="instr-popup__box--body">
            <br/>
            <p>Below is a list of keyboard shortcuts that can be used within the Numeracy Constructed Response tool:</p>
            <table>
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>PC</th>
                        <th>Mac</th>
                    </tr>
                </thead>
                    <tr>
                        <td>Browse clickable items moving forward</td>
                        <td colspan="2"><kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <td>Browse clickable items moving backward</td>
                        <td colspan="2"><kbd>Shift</kbd> + <kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <td>Select clickable item in focus</td>
                        <td colspan="2"><kbd>Enter</kbd></td>
                    </tr>
                    <tr>
                        <td>Close dropdown menus</td>
                        <td colspan="2"><kbd>Esc</kbd></td>
                    </tr>
                    <tr>
                        <td>Deselect menus and toolbars</td>
                        <td colspan="2"><kbd>Esc</kbd></td>
                    </tr>
                    <tr>
                        <td>Scroll down a webpage, a screen at a time</td>
                        <td><kbd>Space</kbd> or <kbd>pg dn</kbd></td>
                        <td><kbd>Shift</kbd> + <kbd>Space</kbd></td>
                    </tr>
                    <tr>
                        <td>Scroll up a webpage, a screen at a time</td>
                        <td><kbd>Shift</kbd> + <kbd>Space or pg up</kbd></td>
                        <td><kbd>Shift</kbd> + <kbd>Space</kbd></td>
                    </tr>
            </table>
            <br/>
            <p>Below is a list of keyboard shortcuts that can be used within the Add Text block:</p>
            <table>
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>PC</th>
                        <th>Mac</th>
                    </tr>
                </thead>
                <tr>
                    <td>Copy</td>
                    <td><kbd>Ctrl</kbd> + <kbd>C</kbd></td>
                    <td><kbd>&#x2318;</kbd> + <kbd>C</kbd></td>
                </tr>
                <tr>
                    <td>Paste</td>
                    <td><kbd>Ctrl</kbd> + <kbd>V</kbd></td>
                    <td><kbd>&#x2318;</kbd> + <kbd>V</kbd></td>
                </tr>
                <tr>
                    <td>Undo</td>
                    <td><kbd>Ctrl</kbd> + <kbd>Z</kbd></td>
                    <td><kbd>&#x2318;</kbd> + <kbd>Z</kbd></td>
                </tr>
                <tr>
                    <td>Redo</td>
                    <td><kbd>Ctrl</kbd> + <kbd>Y</kbd></td>
                    <td><kbd>&#x2318;</kbd> + <kbd>Y</kbd></td>
                </tr>
                <tr>
                    <td>Bold</td>
                    <td><kbd>Ctrl</kbd> + <kbd>B</kbd></td>
                    <td><kbd>&#x2318;</kbd> + <kbd>B</kbd></td>
                </tr>
                <tr>
                    <td>Italic</td>
                    <td><kbd>Ctrl</kbd> + <kbd>I</kbd></td>
                    <td><kbd>&#x2318;</kbd> + <kbd>I</kbd></td>
                </tr>
                <tr>
                    <td>Underline</td>
                    <td><kbd>Ctrl</kbd> + <kbd>U</kbd></td>
                    <td><kbd>&#x2318;</kbd> + <kbd>U</kbd></td>
                </tr>
                <tr>
                    <td>New paragraph</td>
                    <td colspan="2"><kbd>Esc</kbd></td>
                </tr>
                <tr>
                    <td>Move focus to the toolbar</td>
                    <td colspan="2"><kbd>Alt</kbd> + <kbd>F10</kbd></td>
                </tr>
                <tr>
                    <td>Navigate through the toolbar</td>
                    <td colspan="2"><kbd>&#x2191;</kbd> / <kbd>&#x2192;</kbd> / <kbd>&#x2193;</kbd> / <kbd>&#x2190;</kbd></td>
                </tr>
                <tr>
                    <td>Move focus to the toolbar</td>
                    <td colspan="2"><kbd>Enter</kbd></td>
                </tr>
            </table>
            <br/>
            <p>Below is a list of keyboard shortcuts that can be used within the Add a Table block:</p>
            <table>
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>PC</th>
                        <th>Mac</th>
                    </tr>
                </thead>
                    <tr>
                        <td>Move the selection to the next cell</td>
                        <td colspan="2"><kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <td>Move the selection to the previous cell</td>
                        <td colspan="2"><kbd>Shift</kbd> + <kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <td>Copy</td>
                        <td><kbd>Ctrl</kbd> + <kbd>C</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>C</kbd></td>
                    </tr>
                    <tr>
                        <td>Paste</td>
                        <td><kbd>Ctrl</kbd> + <kbd>V</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>V</kbd></td>
                    </tr>
                    <tr>
                        <td>Undo</td>
                        <td><kbd>Ctrl</kbd> + <kbd>Z</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>Z</kbd></td>
                    </tr>
                    <tr>
                        <td>Redo</td>
                        <td><kbd>Ctrl</kbd> + <kbd>Y</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>Y</kbd></td>
                    </tr>
            </table>
            <br/>
            <p>The following keyboard shortcuts are available for the Add a Diagram/Graph block, should you need or prefer them:</p>
            <table>
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>PC</th>
                        <th>Mac</th>
                    </tr>
                </thead>
                    <tr>
                        <td>Browse clickable items in the toolbar moving forward</td>
                        <td colspan="2"><kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <td>Browse clickable items in the toolbar moving backward</td>
                        <td colspan="2"><kbd>Shift</kbd> + <kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <td>Select an item in the toolbar (when the toolbar is in focus)</td>
                        <td colspan="2"><kbd>Enter</kbd></td>
                    </tr>                    
                    <tr>
                        <td>Acts as a mouse click if the diagram is focused</td>
                        <td colspan="2"><kbd>Enter</kbd></td>
                    </tr>
                    <tr>
                        <td>Enables keyboard-based cursor for drawing and other tools</td>
                        <td><kbd>Ctrl</kbd> + <kbd>M</kbd></td>
                        <td><kbd>Ctrl</kbd> + <kbd>M</kbd></td>
                    </tr>
                    <tr>
                        <td>Activate line tool</td>
                        <td colspan="2"><kbd>L</kbd></td>
                    </tr>
                    <tr>
                        <td>Draw a line on the diagonal</td>
                        <td colspan="2"><kbd>C</kbd> + <kbd>&#x2191;</kbd> / <kbd>&#x2192;</kbd> / <kbd>&#x2193;</kbd> / <kbd>&#x2190;</kbd></td>
                    </tr>
                    <tr>
                        <td>When pressed, selected shapes are moved to the front of the diagram</td>
                        <td colspan="2"><kbd>Home</kbd></td>
                    </tr>
                    <tr>
                        <td>Copy to clipboard</td>
                        <td><kbd>Ctrl</kbd> + <kbd>C</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>C</kbd></td>
                    </tr>
                    <tr>
                        <td>Cut the selection</td>
                        <td><kbd>Ctrl + X, Shift + Delete</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>X</kbd></td>
                    </tr>
                    <tr>
                        <td>Paste from clipboard</td>
                        <td><kbd>Ctrl + V, Shift + Insert</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>V</kbd></td>
                    </tr>
                    <tr>
                        <td>Duplicate the Selection</td>
                        <td colspan="2"><kbd>Ctrl</kbd> + <kbd>D</kbd></td>
                    </tr>
                    <tr>
                        <td>Delete the selection</td>
                        <td colspan="2"><kbd>Delete, Backspace</kbd></td>
                    </tr>
                    <tr>
                        <td>Nudge a shape in the downward direction</td>
                        <td colspan="2"><kbd>Ctrl</kbd> + <kbd>Down</kbd></td>
                    </tr>
                    <tr>
                        <td>Nudge a shape in the leftward direction</td>
                        <td colspan="2"><kbd>Ctrl</kbd> + <kbd>Left</kbd></td>
                    </tr>
                    <tr>
                        <td>Select all on the current page</td>
                        <td colspan="2"><kbd>Ctrl</kbd> + <kbd>A</kbd></td>
                    </tr>
                    <tr>
                        <td>Move the selected shapes under other shapes</td>
                        <td colspan="2"><kbd>Page Down</kbd></td>
                    </tr>
                    <tr>
                        <td>Send selected shapes to the back</td>
                        <td colspan="2"><kbd>End</kbd></td>
                    </tr>
                    <tr>
                        <td>Undo</td>
                        <td><kbd>Ctrl + Z</kbd></td>
                        <td><kbd>&#x2318;</kbd> + Z</td>
                    </tr>
            </table>
            <style>
                .keyboard-shortcuts th {
                    text-align: center;
                }
                
                .keyboard-shortcuts td:nth-of-type(1) {
                    text-align: right;
                }
                
                .keyboard-shortcuts td:nth-of-type(2),
                .keyboard-shortcuts td:nth-of-type(3) {
                    width: 30%;
                }
            </style>
        </div>
    </div>
</div>

<!-- End of modal -->