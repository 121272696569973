import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { LoginGuardService } from 'src/app/api/login-guard.service';

@Component({
  selector: 'element-render-authtool-table',
  templateUrl: './element-render-authtool-table.html',
  styleUrls: ['./element-render-authtool-table.scss']
})
export class ElementRenderAuthToolTable implements OnInit {
  
  @Input() block;
  @Input() tableBlock;
  @Input() index;
  @Input() review;
  @Output() authToolTableUpdated = new EventEmitter();
  
  tableCommands: any[] = [];
  fontSizeMax = 1.4;
  fontSizeMin = 0.6;
  commandPointer = -1;
  deletingBlock; 
  tableRow
  tableCol
  activeTableBlock
  colours = [
    "#FFF3B6", //yellow
    "#DBF1A5", //green
    "#FFC688", //orange
    "#B2ECF6", //blue
    "#FFD6CF", //pink
    "#D5CAFF", //purple
    "#FFFFFF", //white/clear
    "#dbdbdb", //grey
  ];
  showColorOptions = false;
  showBorderOptions = false;
  borderColours = [
    "#FFF3B6", //yellow
    "#DBF1A5", //green
    "#FFC688", //orange
    "#B2ECF6", //blue
    "#FFD6CF", //pink
    "#D5CAFF", //purple
    "#dbdbdb", //white/clear
    "#000000", //black
  ];
  showBorderColorOptions = false;

  constructor(
    private loginGuard: LoginGuardService,
  ) { }
  
  ngOnInit(): void {
    window.onclick = (event) => {
      // console.log(event.target.className)
      if(!event.target.className || typeof event.target.className.includes !== 'function' || !event.target.className.includes('border-dropdown-option') && !event.target.className.includes('border-color-dropdown-option')){
        this.showBorderOptions = false;
      }
      if(!event.target.className || typeof event.target.className.includes !== 'function' || !event.target.className.includes('color-dropdown-option')){
        this.showColorOptions = false;
      }
    }
  }

  resetCommand() {
    // console.log('resetting command... before: ', this.tableCommands)
    this.tableCommands.splice(this.commandPointer + 1, this.tableCommands.length - 1 - this.commandPointer);
  }

  pushCommand(command) {

    // console.log('commandPointer: ', this.commandPointer)
    
    if (this.commandPointer < this.tableCommands.length - 1) {
      this.resetCommand();
    }

    if (this.commandPointer === -1) {
      this.commandPointer = 0;
    } else {
      this.commandPointer = this.commandPointer + 1;
    }
    this.tableCommands.push(command);
    // console.log("Table: ", this.tableCommands, this.commandPointer);
  }

  reInsertColumn(command) {
    if (command.undo.block.rows.length === 0) {
      command.undo.deletedCells.forEach((cell) => {
        command.undo.block.rows.push({cells: [cell]})
      });
      return;
    }

    command.undo.block.rows.forEach((row, i) => {
      row.cells.push(command.undo.deletedCells[i]);
    });
    this.deletingBlock = command.undo.block;
  }

  reInsertRow(command) {
    const cells = [];
    const undo = command.undo;

    undo.deletedCells.forEach((cell, i) => {
      cells.push(cell);
    });
    undo.block.rows.push({cells});
  }

  undoTable() {
    const command = this.tableCommands[this.commandPointer];
    if (!command) {
      console.log("error", this.commandPointer)
      return;
    }

    this.commandPointer = this.commandPointer - 1;

    switch(command.type) {
      case "add_col":
        this.removeColumn(command.undo.block, true);
        break;
      case "add_row":
        this.removeRow(command.undo.block, true);
        break;
      case "delete_col":
        this.reInsertColumn(command);
        break;
      case "delete_row":
        this.reInsertRow(command);
        break;
      case "bold":
        const undoBold = command.undo;
        undoBold.block.rows[undoBold.row].cells[undoBold.col].bold = !undoBold.block.rows[undoBold.row].cells[undoBold.col].bold;
        break;
      case "underline":
        const undoUnderline = command.undo;
        undoUnderline.block.rows[undoUnderline.row].cells[undoUnderline.col].underline = !undoUnderline.block.rows[undoUnderline.row].cells[undoUnderline.col].underline;
        break;
      case "italic":
        const undoItalic = command.undo;
        undoItalic.block.rows[undoItalic.row].cells[undoItalic.col].italic = !undoItalic.block.rows[undoItalic.row].cells[undoItalic.col].italic;
        break;
      case "increase_font_size":
        const undoFontIncrease = command.undo;
        const row = undoFontIncrease.row;
        const col = undoFontIncrease.col;
        undoFontIncrease.block.rows[row].cells[col].fontSize = undoFontIncrease.block.rows[row].cells[col].fontSize - undoFontIncrease.decreasedBy;
        break;
      case "decrease_font_size":
        const undoFontDecrease = command.undo;
        const decreasedFontRow = undoFontDecrease.row;
        const decreasedFontCol = undoFontDecrease.col;
        undoFontDecrease.block.rows[decreasedFontRow].cells[decreasedFontCol].fontSize = undoFontDecrease.block.rows[decreasedFontRow].cells[decreasedFontCol].fontSize + undoFontDecrease.increasedBy;
        break;
      case "set_text":
        const undoSetText = command.undo;
        const setTextRow = undoSetText.row;
        const setTextCol = undoSetText.col;
        undoSetText.block.rows[setTextRow].cells[setTextCol].text = undoSetText.text;
        break;
      case "set_cell_color":
        const undoSetCellColor = command.undo;
        const setCellColorRow = undoSetCellColor.row;
        const setCellColorCol = undoSetCellColor.col;
        undoSetCellColor.block.rows[setCellColorRow].cells[setCellColorCol].colour = undoSetCellColor.color;
        break;
      case "border_all":
        this.setTableBorder_All(command.action.block, true, command.action.row, command.action.col);
        break;
      case "border_none":
        this.setTableBorder_All(command.action.block, true, command.action.row, command.action.col);
        break;
      case "border_left":
        this.setTableBorder_Left(command.action.block, true, command.action.row, command.action.col);
        break;
      case "border_right":
        this.setTableBorder_Right(command.action.block, true, command.action.row, command.action.col);
        break;
      case "border_top":
        this.setTableBorder_Top(command.action.block, true, command.action.row, command.action.col);
        break;
      case "border_bottom":
        this.setTableBorder_Bottom(command.action.block, true, command.action.row, command.action.col);
        break;
      case "set_cell_border_color":
        const undoSetCellBorderColor = command.undo;
        const setCellBorderColorRow = undoSetCellBorderColor.row;
        const setCellBorderColorCol = undoSetCellBorderColor.col;
        undoSetCellBorderColor.block.rows[setCellBorderColorRow].cells[setCellBorderColorCol].cellBorderColour = undoSetCellBorderColor.borderColor;
        break;
    }
  }

  redoTable() {

    this.commandPointer = this.commandPointer + 1;
    const command = this.tableCommands[this.commandPointer];

    if (!command) {
      console.log("error", this.commandPointer)
      return;
    }

    switch(command.type) {
      case "add_col":
        this.insertColumn(command.action.block, true);
        break;
      case "add_row":
        this.insertRow(command.action.block, true);
        break;
      case "delete_col":
        this.removeColumn(command.action.block, true);
        break;
      case "delete_row":
        this.removeRow(command.action.block, true);
        break;
      case "bold":
        const actionBold = command.action;
        actionBold.block.rows[actionBold.row].cells[actionBold.col].bold = !actionBold.block.rows[actionBold.row].cells[actionBold.col].bold;
        break;
      case "underline":
        const actionUnderline = command.action;
        actionUnderline.block.rows[actionUnderline.row].cells[actionUnderline.col].underline = !actionUnderline.block.rows[actionUnderline.row].cells[actionUnderline.col].underline;
        break;
      case "italic":
        const actionItalic = command.undo;
        actionItalic.block.rows[actionItalic.row].cells[actionItalic.col].italic = !actionItalic.block.rows[actionItalic.row].cells[actionItalic.col].italic;
        break;
        case "increase_font_size":
          const actionFontIncrease = command.action;
          const row = actionFontIncrease.row;
          const col = actionFontIncrease.col;
          actionFontIncrease.block.rows[row].cells[col].fontSize = actionFontIncrease.block.rows[row].cells[col].fontSize + actionFontIncrease.increasedBy;
          break;
        case "decrease_font_size":
          const actionFontDecrease = command.action;
          const decreasedFontRow = actionFontDecrease.row;
          const decreasedFontCol = actionFontDecrease.col;
          actionFontDecrease.block.rows[decreasedFontRow].cells[decreasedFontCol].fontSize = actionFontDecrease.block.rows[decreasedFontRow].cells[decreasedFontCol].fontSize - actionFontDecrease.decreasedBy;
          break;
        case "set_text":
          const actionSetText = command.action;
          const setTextRow = actionSetText.row;
          const setTextCol = actionSetText.col;
          actionSetText.block.rows[setTextRow].cells[setTextCol].text = actionSetText.text;
          break;
        case "set_cell_color":
          const actionSetCellColor = command.action;
          const setCellColorRow = actionSetCellColor.row;
          const setCellColorCol = actionSetCellColor.col;
          actionSetCellColor.block.rows[setCellColorRow].cells[setCellColorCol].colour = actionSetCellColor.color;
          break;
        case "border_all":
          this.setTableBorder_All(command.action.block, true, command.action.row, command.action.col);
          break;
        case "border_none":
          this.setTableBorder_All(command.action.block, true, command.action.row, command.action.col);
          break;
        case "border_left":
          this.setTableBorder_Left(command.action.block, true, command.action.row, command.action.col);
          break;
        case "border_right":
          this.setTableBorder_Right(command.action.block, true, command.action.row, command.action.col);
          break;
        case "border_top":
          this.setTableBorder_Top(command.action.block, true, command.action.row, command.action.col);
          break;
        case "border_bottom":
          this.setTableBorder_Bottom(command.action.block, true, command.action.row, command.action.col);
          break;
        case "set_cell_border_color":
          const actionSetCellBorderColor = command.action;
          const setCellBorderColorRow = actionSetCellBorderColor.row;
          const setCellBorderColorCol = actionSetCellBorderColor.col;
          actionSetCellBorderColor.block.rows[setCellBorderColorRow].cells[setCellBorderColorCol].cellBorderColour = actionSetCellBorderColor.borderColor;
          break;
    }
  }

  removeColumn = (block, isUndoRedo?) => {

    // Checking if the column has text already
    let hasText = false; 
    block.rows.forEach((row) => {
      if (row.cells[row.cells.length - 1].text !== undefined && row.cells[row.cells.length - 1].text.length !== 0) {
        hasText = true;
      }
    });

    if (hasText && !isUndoRedo) {
      block.rows.forEach((row) => {
        row.cells[row.cells.length - 1].pendingDelete = true;
      });
      this.deletingBlock = block;
      this.loginGuard.confirmationReqActivate({ caption: "You are about to delete a column. Are you sure?", confirm: () => {

          this.deletingBlock.rows.forEach((row) => {
            row.cells[row.cells.length - 1].pendingDelete = false;
          });
          let deletedCells = [];
          block.rows.forEach((row) => {
    
            deletedCells.push(row.cells[row.cells.length - 1]);
            row.cells.splice(row.cells.length - 1, 1);
          });

          // Set rows to empty if all columns are deleted
          if (block.rows[0].cells.length === 0) {
            block.rows = [];
          }

          this.pushCommand({ type: "delete_col", action: { block },  undo: { block, deletedCells }});
        }
      });
    } else {

      let deletedCells = [];
      block.rows.forEach((row) => {

        deletedCells.push(row.cells[row.cells.length - 1]);
        row.cells.splice(row.cells.length - 1, 1);
      });

      // Set rows to empty if all columns are deleted
      if (block.rows[0].cells.length === 0) {
        block.rows = [];
      }

      if(!isUndoRedo) {
        this.pushCommand({ type: "delete_col", action: { block },  undo: { block, deletedCells }});
      }
    }
    this.authToolTableUpdated.emit();
  }

  removeRow = (block, isUndoRedo?) => {
    if (block.rows.length - 1 === -1) {
      return;
    }
    
    // Checking if the row has text already
    const cellsWithText = block.rows[block.rows.length - 1].cells.filter((cell) => {
      return cell.text !== undefined && cell.text.length !== 0;
    });

    // Show modal if the row happens to have text
    if (cellsWithText.length !== 0 && !isUndoRedo) {
      block.rows[block.rows.length - 1].cells.forEach((cell) => { cell.pendingDelete = true; })
      this.deletingBlock = block;
      this.loginGuard.confirmationReqActivate({ caption: "You are about to delete a row. Are you sure?", confirm: () => {
        
        this.deletingBlock.rows[this.deletingBlock.rows.length - 1].cells.forEach((cell) => { cell.pendingDelete = false; });
        const deletedCells = block.rows[block.rows.length - 1].cells;
        block.rows.splice(block.rows.length - 1, 1);

        this.pushCommand({ type: "delete_row", action: { block },  undo: { block, deletedCells }});
      }})
    } else {
      const deletedCells = block.rows[block.rows.length - 1].cells;
      block.rows.splice(block.rows.length - 1, 1);

      if(!isUndoRedo) {
        this.pushCommand({ type: "delete_row", action: { block },  undo: { block, deletedCells }});
      }
    }
    this.authToolTableUpdated.emit();
  }

  insertColumn(block, isUndoRedo?){

    if (!isUndoRedo) {
      this.pushCommand({ type: "add_col", action: { block },  undo: { block }});
    }

    if (block.rows.length === 0) {
      block.rows.push({cells: []});
    }
    block.rows.forEach(row => {
      row.cells.push({})
    });
    this.authToolTableUpdated.emit();
  }

  insertRow(block, isUndoRedo?){

    if (!isUndoRedo) {
      this.pushCommand({  type: "add_row", action: { block },  undo: { block }});
    }
    const cells = [];
    if (block.rows.length === 0) {
      block.rows.push({cells: [{}]});
      return;
    }
    block.rows[0].cells.forEach(() => {
      cells.push({});
    })
    // console.log('insert row', cells)
    block.rows.push({cells})
    this.authToolTableUpdated.emit();
  }

  boldCell(block) {
    if (block.rows[this.tableRow].cells[this.tableCol].bold) {
      block.rows[this.tableRow].cells[this.tableCol].bold = false
    } else {
      block.rows[this.tableRow].cells[this.tableCol].bold = true
    }
    this.pushCommand({ type: "bold", action: { block, row: this.tableRow, col: this.tableCol },  undo: { block, row: this.tableRow, col: this.tableCol }});
    this.authToolTableUpdated.emit();
  }

  underlineCell(block) {
    if (block.rows[this.tableRow].cells[this.tableCol].underline) {
      block.rows[this.tableRow].cells[this.tableCol].underline = false
    } else {
      block.rows[this.tableRow].cells[this.tableCol].underline = true
    }
    this.pushCommand({ type: "underline", action: { block, row: this.tableRow, col: this.tableCol },  undo: { block, row: this.tableRow, col: this.tableCol }});
    this.authToolTableUpdated.emit();
  }

  italicCell(block) {
    if (block.rows[this.tableRow].cells[this.tableCol].italic) {
      block.rows[this.tableRow].cells[this.tableCol].italic = false
    } else {
      block.rows[this.tableRow].cells[this.tableCol].italic = true
    }
    this.pushCommand({ type: "italic", action: { block, row: this.tableRow, col: this.tableCol },  undo: { block, row: this.tableRow, col: this.tableCol }});
    this.authToolTableUpdated.emit();
  }

  increaseCellFontSize(block) {
    block.rows[this.tableRow].cells[this.tableCol].fontSize = block.rows[this.tableRow].cells[this.tableCol].fontSize + 0.1;
    this.pushCommand({ type: "increase_font_size", action: { block, row: this.tableRow, col: this.tableCol, increasedBy: 0.1 },  undo: { block, row: this.tableRow, col: this.tableCol, decreasedBy: 0.1 }});
    this.authToolTableUpdated.emit();
  }

  decreaseCellFontSize(block) {
    block.rows[this.tableRow].cells[this.tableCol].fontSize = block.rows[this.tableRow].cells[this.tableCol].fontSize - 0.1;
    this.pushCommand({ type: "decrease_font_size", action: { block, row: this.tableRow, col: this.tableCol, decreasedBy: 0.1 },  undo: { block, row: this.tableRow, col: this.tableCol, increasedBy: 0.1 }});
    this.authToolTableUpdated.emit();
  }

  isCellFontSizeMax(block) {
    if (block.rows[this.tableRow] && block.rows[this.tableRow].cells[this.tableCol]) {
      const roundedFontSize = (Math.round(block.rows[this.tableRow].cells[this.tableCol].fontSize * 10) / 10); 
      return (roundedFontSize === this.fontSizeMax);
    }
    return false;
  }

  isCellFontSizeMin(block) {
    if (block.rows[this.tableRow] && block.rows[this.tableRow].cells[this.tableCol]) {
      const roundedFontSize = (Math.round(block.rows[this.tableRow].cells[this.tableCol].fontSize * 10) / 10); 
      return (roundedFontSize === this.fontSizeMin);
    }
    return false;
  }

  setRowAndCol = (row, col, block,) => {
    this.tableRow = row;
    this.tableCol = col
    this.activeTableBlock = block;
  }

  setText = (block, row, col, event) => {
    // console.log('setting text... block: ', block, ' row: ', row, ' col: ', col, ' event: ', event)
    const val = event.target.value;
    const undoVal = block.rows[row].cells[col].text
    block.rows[row].cells[col].text = val;

    this.pushCommand({ type: "set_text", action: { block, row: this.tableRow, col: this.tableCol, text: val },  undo: { block, row: this.tableRow, col: this.tableCol, text: undoVal }});
    this.authToolTableUpdated.emit();
  }

  isMinZoom = (zoom) => zoom <= 1;
  isMaxZoom = (zoom) => zoom >= 2;
  
  drop(arr: any, event: CdkDragDrop<string[]>) {
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
  }

  toggleShowTableBorderOptions() {
    this.showBorderOptions = !this.showBorderOptions;
  }

  setTableBorder_All(block, isFromUndoRedo?: boolean, row?:number, col?:number){
    let rowIndex = this.tableRow;
    let colIndex = this.tableCol;
    if(isFromUndoRedo){
      rowIndex = row;
      colIndex = col;
    } else {
      this.pushCommand({  type: "border_all", action: { block, row: this.tableRow, col: this.tableCol },  undo: { block, row: this.tableRow, col: this.tableCol }});
    }
    if (block.rows[rowIndex].cells[colIndex].allBorder) {
      block.rows[rowIndex].cells[colIndex].allBorder = false
      block.rows[rowIndex].cells[colIndex].leftBorder = false
      block.rows[rowIndex].cells[colIndex].rightBorder = false
      block.rows[rowIndex].cells[colIndex].topBorder = false
      block.rows[rowIndex].cells[colIndex].bottomBorder = false
    } else {
      block.rows[rowIndex].cells[colIndex].allBorder = true
      block.rows[rowIndex].cells[colIndex].leftBorder = true
      block.rows[rowIndex].cells[colIndex].rightBorder = true
      block.rows[rowIndex].cells[colIndex].topBorder = true
      block.rows[rowIndex].cells[colIndex].bottomBorder = true
    }
    this.showBorderOptions = false
    this.authToolTableUpdated.emit();
  }

  setTableBorder_None(block, isFromUndoRedo?: boolean, row?:number, col?:number){
    let rowIndex = this.tableRow;
    let colIndex = this.tableCol;
    if(isFromUndoRedo){
      rowIndex = row;
      colIndex = col;
    } else {
      this.pushCommand({  type: "border_none", action: { block, row: this.tableRow, col: this.tableCol },  undo: { block, row: this.tableRow, col: this.tableCol }});
    }
    block.rows[rowIndex].cells[colIndex].allBorder = false
    block.rows[rowIndex].cells[colIndex].leftBorder = false
    block.rows[rowIndex].cells[colIndex].rightBorder = false
    block.rows[rowIndex].cells[colIndex].topBorder = false
    block.rows[rowIndex].cells[colIndex].bottomBorder = false
    this.showBorderOptions = false
    this.authToolTableUpdated.emit();
  }


  setTableBorder_Left(block, isFromUndoRedo?: boolean, row?:number, col?:number){
    let rowIndex = this.tableRow;
    let colIndex = this.tableCol;
    if(isFromUndoRedo){
      rowIndex = row;
      colIndex = col;
    } else {
      this.pushCommand({  type: "border_left", action: { block, row: this.tableRow, col: this.tableCol },  undo: { block, row: this.tableRow, col: this.tableCol }});
    }
    if (block.rows[rowIndex].cells[colIndex].leftBorder) {
      block.rows[rowIndex].cells[colIndex].leftBorder = false
      block.rows[rowIndex].cells[colIndex].allBorder = false
    } else {
      block.rows[rowIndex].cells[colIndex].leftBorder = true
    }
    this.showBorderOptions = false
    this.authToolTableUpdated.emit();
  }

  setTableBorder_Right(block, isFromUndoRedo?: boolean, row?:number, col?:number){
    let rowIndex = this.tableRow;
    let colIndex = this.tableCol;
    if(isFromUndoRedo){
      rowIndex = row;
      colIndex = col;
    } else {
      this.pushCommand({  type: "border_right", action: { block, row: this.tableRow, col: this.tableCol },  undo: { block, row: this.tableRow, col: this.tableCol }});
    }
    if (block.rows[rowIndex].cells[colIndex].rightBorder) {
      block.rows[rowIndex].cells[colIndex].rightBorder = false
      block.rows[rowIndex].cells[colIndex].allBorder = false
    } else {
      block.rows[rowIndex].cells[colIndex].rightBorder = true
    }
    this.showBorderOptions = false
    this.authToolTableUpdated.emit();
  }

  setTableBorder_Top(block, isFromUndoRedo?: boolean, row?:number, col?:number){
    let rowIndex = this.tableRow;
    let colIndex = this.tableCol;
    if(isFromUndoRedo){
      rowIndex = row;
      colIndex = col;
    } else {
      this.pushCommand({  type: "border_top", action: { block, row: this.tableRow, col: this.tableCol },  undo: { block, row: this.tableRow, col: this.tableCol }});
    }
    if (block.rows[rowIndex].cells[colIndex].topBorder) {
      block.rows[rowIndex].cells[colIndex].topBorder = false
      block.rows[rowIndex].cells[colIndex].allBorder = false
    } else {
      block.rows[rowIndex].cells[colIndex].topBorder = true
    }
    this.showBorderOptions = false
    this.authToolTableUpdated.emit();
  }

  setTableBorder_Bottom(block, isFromUndoRedo?: boolean, row?:number, col?:number){
    let rowIndex = this.tableRow;
    let colIndex = this.tableCol;
    if(isFromUndoRedo){
      rowIndex = row;
      colIndex = col;
    } else {
      this.pushCommand({  type: "border_bottom", action: { block, row: this.tableRow, col: this.tableCol },  undo: { block, row: this.tableRow, col: this.tableCol }});
    }
    if (block.rows[rowIndex].cells[colIndex].bottomBorder) {
      block.rows[rowIndex].cells[colIndex].bottomBorder = false
      block.rows[rowIndex].cells[colIndex].allBorder = false
    } else {
      block.rows[rowIndex].cells[colIndex].bottomBorder = true
    }
    this.showBorderOptions = false
    this.authToolTableUpdated.emit();
  }


  getBorderColor(block, row_i, col_i){
    if(block.rows[row_i].cells[col_i].pendingDelete){
      return '#0099e2'
    }
    let left = '#dbdbdb';
    let right = '#dbdbdb';
    let top = '#dbdbdb';
    let bottom = '#dbdbdb';
    if(block.rows[row_i].cells[col_i].leftBorder){block.rows[row_i].cells[col_i].cellBorderColour? left = block.rows[row_i].cells[col_i].cellBorderColour : left = '#000000'}
    if(block.rows[row_i].cells[col_i].rightBorder){block.rows[row_i].cells[col_i].cellBorderColour? right = block.rows[row_i].cells[col_i].cellBorderColour : right = '#000000'}
    if(block.rows[row_i].cells[col_i].topBorder){block.rows[row_i].cells[col_i].cellBorderColour? top = block.rows[row_i].cells[col_i].cellBorderColour : top = '#000000'}
    if(block.rows[row_i].cells[col_i].bottomBorder){block.rows[row_i].cells[col_i].cellBorderColour? bottom = block.rows[row_i].cells[col_i].cellBorderColour : bottom = '#000000'}

    return top + ' ' + right + ' ' + bottom + ' ' + left;
  }

  getBorderWidth(block, row_i, col_i){
    if(block.rows[row_i].cells[col_i].pendingDelete){
      return '2px'
    }

    let left = '1px';
    let right = '1px';
    let top = '1px';
    let bottom = '1px';
    
    if(block.rows[row_i].cells[col_i].leftBorder){left = '2px'}
    if(block.rows[row_i].cells[col_i].rightBorder){right = '2px'}
    if(block.rows[row_i].cells[col_i].topBorder){top = '2px'}
    if(block.rows[row_i].cells[col_i].bottomBorder){bottom = '2px'}

    return top + ' ' + right + ' ' + bottom + ' ' + left;
  }

  getBorderStyle(block, row_i, col_i){
    let left = 'solid';
    let right = 'solid';
    let top = 'solid';
    let bottom = 'solid';
    
    return top + ' ' + right + ' ' + bottom + ' ' + left;
  }

  isBorderOptionActive(block, border: string){
    if(block != this.activeTableBlock || !block.rows[this.tableRow] || !block.rows[this.tableRow].cells[this.tableCol]){
      return false;
    }
    switch(border){
      case 'all':
        return block.rows[this.tableRow].cells[this.tableCol].allBorder
      case 'left':
        return block.rows[this.tableRow].cells[this.tableCol].leftBorder
      case 'right':
        return block.rows[this.tableRow].cells[this.tableCol].rightBorder
      case 'top':
        return block.rows[this.tableRow].cells[this.tableCol].topBorder
      case 'bottom':
        return block.rows[this.tableRow].cells[this.tableCol].bottomBorder
    }
  }


  toggleShowColorOptions(){
    this.showColorOptions = !this.showColorOptions
  }

  setCellColor(block, colour){
    if(block.rows[this.tableRow] && block.rows[this.tableRow].cells[this.tableCol]){
      let oldColor = block.rows[this.tableRow].cells[this.tableCol].colour;
      block.rows[this.tableRow].cells[this.tableCol].colour = colour;
      this.showColorOptions = false;
      this.pushCommand({  type: "set_cell_color", action: { block, row: this.tableRow, col: this.tableCol, color:  colour},  undo: { block, row: this.tableRow, col: this.tableCol, color:  oldColor }});
    } else {
      alert('Please select a table cell first.')
    }
    this.authToolTableUpdated.emit();
  }

  getCellColour(block, row_i, col_i){
    if(block.rows[row_i].cells[col_i].colour){
      return block.rows[row_i].cells[col_i].colour
    }
    return 'transparent'
  }

  isColorActive(block, colour){
    if(block == this.activeTableBlock || block.rows[this.tableRow] && block.rows[this.tableRow].cells[this.tableCol].colour){
      return colour == block.rows[this.tableRow].cells[this.tableCol].colour;
    }
    return false;
  }

  areBorderAndColorOptionDisabled(block){
    if(!this.activeTableBlock || this.activeTableBlock != block){
      return true;
    }
    else if ((!this.tableRow && this.tableRow != 0) || (!this.tableCol && this.tableCol != 0)){
      return true;
    }
    else {
      return false;
    }
  }

  toggleShowBorderColorOptions() {
    this.showBorderColorOptions = !this.showBorderColorOptions;
  }

  setCellBorderColor(block, colour: string) {
    if(block.rows[this.tableRow] && block.rows[this.tableRow].cells[this.tableCol]){
      let oldColor = block.rows[this.tableRow].cells[this.tableCol].cellBorderColour;
      block.rows[this.tableRow].cells[this.tableCol].cellBorderColour = colour;
      this.showBorderColorOptions = false;
      this.pushCommand({  type: "set_cell_border_color", action: { block, row: this.tableRow, col: this.tableCol, borderColor:  colour},  undo: { block, row: this.tableRow, col: this.tableCol, borderColor:  oldColor }});
    }
    this.authToolTableUpdated.emit();
  }
  
  getActiveBorderColor(block){
    if(block.rows[this.tableRow] && block.rows[this.tableRow].cells[this.tableCol]){
      return block.rows[this.tableRow].cells[this.tableCol].cellBorderColour;
    }
    return '#FFFFFF'
  }
}
