import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/api/auth.service';
import { BcAccountsService, DistrictDetail, FilterCondition, PaginatedRows, Pagination, SchoolLevelDataReportProficiencyLevel } from 'src/app/bc-accounts/bc-accounts.service';
import { AssessmentType, BcAssessmentsService, StudentIrtRow, StudentIrtRow2, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { IndividualRawScoresRow } from 'src/app/bc-reports/types';
import { LangService } from 'src/app/core/lang.service';
import { SingleModeData } from '../student-individual-report/student-individual-report.component';

import { formatDate } from '@angular/common';
import { ScoreType } from '../individual-student-reports/individual-student-reports.component';


@Component({
  selector: 'student-individual-report-bulk',
  templateUrl: './student-individual-report-bulk.component.html',
  styleUrls: ['./student-individual-report-bulk.component.scss']
})
export class StudentIndividualReportBulkComponent implements OnInit, OnChanges {

  @Input() testWindow: TestWindow;
  @Input() districtDetail: DistrictDetail;
  @Input() isIrtReady: boolean;
  @Input() school: number;

  singleModeDataRows: SingleModeData[] = [];

  reportYear: string | number;

  isLoading: boolean = false;
  languageRefresh: boolean = false;
  currentlang: string = "en"; // default language on bulk reports is always English
  bulkReportInterval;
  selectedScoreType: ScoreType;
  ScoreType = ScoreType;


  constructor(
    private bcAccounts: BcAccountsService,
    private bcAssessments: BcAssessmentsService,
    private auth: AuthService,
    private lang: LangService
  ) { 
  }

  ngOnInit(): void {
    this.isIrtReady = false; // deprecated variable
    this.initSelectedScoreType();
    this.reportYear = this.getReportYear();
    // console.log(this.selectedScoreType);
    this.bulkReportInterval = setInterval(() => {
      this.checkBulkReportStatus();
    }, 5000)
  }

  ngOnDestroy(): void {
    clearInterval(this.bulkReportInterval);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.testWindow || changes.districtDetail || changes.isIrtReady) 
    {
      this.reportYear = this.getReportYear();
      if (this.selectedScoreType == null)
      {
        this.initSelectedScoreType();
      }

      console.log(this.selectedScoreType);
      this.loadReports();
    }
  }

  loadTotal;
  loadAmt = 0;

  getReportYear() {
    let year = '';
    for(let char of this.testWindow.title.en) {
      if(!isNaN(+char)) year += char;
      else if(year != '') return year;
    }

    return year;
  }

  async loadReports() {
    this.isLoading = true;
    this.isIrtReady = false; // deprecated variable

    if (!this.testWindow || !this.districtDetail || ![true, false].includes(this.isIrtReady)) {
      return;
    }

    let schGroupIds;
    if (this.school) {
      schGroupIds = [this.school];
    } else {
      const schools = await this.bcAccounts.findSchools(this.districtDetail.groupId, AssessmentType.FSA);
      schGroupIds = schools.map(s => s.groupId).filter(schGroupId => schGroupId > 0)
    }

    this.loadTotal = 0;
    this.loadAmt = 0;

    const isScaled = this.selectedScoreType === ScoreType.SCALED_SCORE;
    console.log(this.selectedScoreType);

    const singleModeDataRows: SingleModeData[] = [];
    for (const schoolGroupId of schGroupIds) {
      const school = await this.bcAccounts.getSchoolDetail(schoolGroupId);
      const reports = await this.getAllIndividualStudentReports(schoolGroupId, isScaled);
      // console.log(reports);
      // console.log(schoolGroupId, isScaled);

      this.loadTotal += reports.data.length;
      reports.data.forEach(report => 
      {
        singleModeDataRows.push(this.createSingleModeDataV2({
          ...report,
          schoolName: school.name
        }));
        
        this.loadAmt += 1;
      });
    }

    // this.loadTotal = schGroupIds.length;
    // let promises = schGroupIds.map(async (schGroupId) => {
    //   let pens = await this.findPens(schGroupId);
    //   this.loadTotal += pens.length;

    //   // needs change here
    //   let promises = pens.map(async ({ pen, lang, grade }) => {
    //     let singleModeData = await this.createSingleModeData(pen, grade, lang, schGroupId);
    //     this.loadAmt += 1;
    //     return singleModeData;
    //   });


    //   this.loadAmt += 1;
    //   return Promise.all(promises);
    // });
    // let rows = <any>await Promise.all(promises);

    // let singleModeDataRows: SingleModeData[] = [];
    // for (let row of rows) {
    //   for (let student of row) {
    //     student.studentSchoolLang = this.currentlang.toUpperCase();
    //     student.litLevel = await this.translateLevel(this.getProficiencyLevel('literacy', student.literacyScore, student.singleModeLitAssessmentCode, student.singleModeNumAssessmentCode, this.isIrtReady));
    //     student.numLevel = await this.translateLevel(this.getProficiencyLevel('numeracy', student.numeracyScore, student.singleModeLitAssessmentCode, student.singleModeNumAssessmentCode, this.isIrtReady));
    //   }

    //   singleModeDataRows.push(row);
    // }
    this.singleModeDataRows = singleModeDataRows;
    this.applyLanguageToggleOverride();
    this.isLoading = false;
  }

  private applyLanguageToggleOverride()
  {
    const lang = this.currentlang == "en" ? "en" : "fr";
    this.handleLanguageToggle(lang);
  }

  private async findPens(school_group_id: number): Promise<{ pen: string, lang: string, grade: number, }[]> {
    this.isIrtReady = false; // deprecated variable

    if (this.isIrtReady) {
      return await this.bcAssessments.getStudentIrtScore__penOnly(
        this.bcAccounts.getInitialPagination(),
        AssessmentType.FSA,
        this.testWindow,
        this.districtDetail.groupId,
        school_group_id,
      );
    } else {
      let rows = <any> await this.bcAssessments.getStudentPreIrtScore__new( 
        {
          ...this.bcAccounts.getInitialPagination(),
          skip: 0,
          size: -1,
        },
        AssessmentType.FSA,
        this.testWindow,
        false,
        this.districtDetail.groupId,
        school_group_id,
      );

      return rows.data.map(r => ({
        pen: r.pen,
        grade: +r.grade,
        lang: r.lang,
      }));
    }
  }

  private async getAllIndividualStudentReports(schoolGroupId: number, isScaled: boolean) {
    const mockPagination = {
      ...this.bcAccounts.getInitialPagination(),
      skip: 0,
      size: -1,
    }

    const reports = await this.bcAssessments.getIndividualStudentReports({
      pagination: mockPagination,
      testWindow: this.testWindow,
      assessmentType: AssessmentType.FSA,
      schoolGroupId,
      isScaled,
      withScores: true,
    })

    return reports;
  }

  isValidRecentReport()
  {
    // for better debugging of missing export button problem
    // console.log(this.recentReports[0]);
    return this.recentReports[0].is_waiting == 0 && this.recentReports[0].is_succeed == 1;
  }

  isDownloadingAll = false;

  printReport(){
    
  }
  // printReport() {
  //   this.isDownloadingAll = true;
  //   this.auth.apiCreate('public/bcg-scan/individual-student-results-report', {pages:this.singleModeDataRows}).then(response => {
  //     const byteArray = new Uint8Array(atob(response).split('').map(char => char.charCodeAt(0)));
  //     return new Blob([byteArray], {type: 'application/pdf'});
  //   })
  //   .then(blob => {
  //     saveAs(blob, 'Individual Student Results Report.pdf');
  //     this.isDownloadingAll = false;
  //   });
  //   //window.print();
  // }

  //TO DO: 
  // EMAIL CHECKBOX
  // MODAL IF NEW PDF IS READY IN THE LAST 5 MINS

  emailMe;

  async changeEmailPreference() {
    const mostRecentReport = this.recentReports[0];
    if (!mostRecentReport.end) {
      mostRecentReport.email_on_completion = (await this.auth.apiPatch('public/bcg-scan/individual-student-results-report', mostRecentReport.id, {
        email_on_completion: this.emailMe
      })).email_on_completion;
    }
  }

  async generatePDF() {
    this.emailMe = false;
    this.recentReports.unshift(await this.auth.apiCreate('public/bcg-scan/individual-student-results-report', {
      pages: this.singleModeDataRows,
      school: this.school,
      district: this.districtDetail.groupId,
      reportYear: this.reportYear
    }));
  }

  async downloadPDF(path) {
    // var xhr = new XMLHttpRequest(),
    //   a = document.createElement('a'), file;

    //   xhr.open('GET', path, true);
    //   xhr.responseType = 'blob';
    //   xhr.onload = function () {
    //       file = new Blob([xhr.response], { type : 'application/octet-stream' });
    //       a.href = window.URL.createObjectURL(file);
    //       a.download = 'someName.pdf';  // Set to whatever file name you want
    //       // Now just click the link you created
    //       // Note that you may have to append the a element to the body somewhere
    //       // for this to work in Firefox
    //       a.click();
    //   };
    //   xhr.send();

    // let downloadLink = document.createElement('a');
    let href = await this.auth.apiGet('public/bcg-scan/individual-student-results-report', path);

    var xhr = new XMLHttpRequest(),
      a = document.createElement('a'), file;

      xhr.open('GET', href, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
          file = new Blob([xhr.response], { type : 'application/octet-stream' });
          a.href = window.URL.createObjectURL(file);
          a.download = 'Individual Student Results Reports.pdf';  // Set to whatever file name you want
          // Now just click the link you created
          // Note that you may have to append the a element to the body somewhere
          // for this to work in Firefox
          a.click();
      };
      xhr.send();
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
  }

  async handleLanguageToggle(lang: string) {
    this.languageRefresh = true;
    this.lang.setCurrentLanguage(lang);
    this.currentlang = lang;
    for (let row of this.singleModeDataRows) {
      row.studentSchoolLang = this.currentlang.toUpperCase();
      console.log(row.studentSchoolLang);

      if (!row.isScaled) {
        row['litLevel'] = await this.translateLevel(this.getProficiencyLevel('literacy', row.literacyScore, row.singleModeLitAssessmentCode, row.singleModeNumAssessmentCode, this.isIrtReady));
        row['numLevel'] = await this.translateLevel(this.getProficiencyLevel('numeracy', row.numeracyScore, row.singleModeLitAssessmentCode, row.singleModeNumAssessmentCode, this.isIrtReady));
      }
    }
    setTimeout(() => { this.languageRefresh = false }, 1000);
  }

  private async createSingleModeData(pen: string, grade: number, lang: string, school_group_id: number): Promise<SingleModeData> {
    const schoolDetail = await this.bcAccounts.getSchoolDetail(school_group_id);

    let singleModeData: SingleModeData = {
      literacyScore: null,
      numeracyScore: null,
      singlePagination: null,
      singleModePens: null,
      singleModeName: null,
      singleModeLitAssessmentCode: null,
      singleModeNumAssessmentCode: null,
      pen: pen,
      grade: grade,
      studentSchool: null,
      studentDistrict: null,
      studentSchoolLang: lang,
      isScaled: false,
    };

    const isScaled = this.selectedScoreType === ScoreType.SCALED_SCORE;
    let language = lang == 'EN' ? 'E' : 'F';

    this.isIrtReady = false; // deprecated variable

    if (this.isIrtReady) {
      const getScore = (
        pagination: Pagination,
        byAssessment: boolean = false,
      ) => {
        return this.bcAssessments.getStudentIrtScore__new(
          pagination,
          AssessmentType.FSA,
          this.testWindow,
          false,
          this.districtDetail.groupId,
          school_group_id,
        );
      }

      let namePagination = this.bcAccounts.getInitialPagination();
      namePagination.filters = [{
        field: 'pen',
        condition: FilterCondition.MATCH,
        value: pen,
      }];
      let p1 = getScore(namePagination, false).then(({ data: studentData, count: nameCount }) => {
        if (nameCount > 0) {
          singleModeData.singleModeName =
          {
            first_name: studentData[0].first_name,
            last_name: studentData[0].last_name,
          };

          singleModeData.studentSchool = studentData[0].school_name || schoolDetail.name;
          singleModeData.studentDistrict = studentData[0].district_code || this.districtDetail.foreignId;
          singleModeData.studentSchoolLang = studentData[0].lang || schoolDetail.lang;
        }

        else {
          singleModeData.singleModeName =
          {
            first_name: '',
            last_name: '',
          };

          singleModeData.studentSchool = '';
          singleModeData.studentSchoolLang = '';
        }
      });

      singleModeData.singleModeLitAssessmentCode = `LT${language}${grade}`;
      singleModeData.grade = grade;
      singleModeData.pen = pen;
      let literacyPagination = this.bcAccounts.getInitialPagination();
      literacyPagination.filters = [{
        field: 'assessment_code',
        condition: FilterCondition.MATCH,
        value: singleModeData.singleModeLitAssessmentCode,
      }, {
        field: 'pen',
        condition: FilterCondition.MATCH,
        value: pen,
      }];
      let p2 = getScore(literacyPagination, true).then(({ data: litData, count: litCount }) => {
        if (litCount > 0) {
          singleModeData.literacyScore = litData[0].score;
        } else {
          singleModeData.literacyScore = null;
        }
      });

      singleModeData.singleModeNumAssessmentCode = `NM${language}${grade}`;
      let numeracyPagination = this.bcAccounts.getInitialPagination();
      numeracyPagination.filters = [{
        field: 'assessment_code',
        condition: FilterCondition.MATCH,
        value: singleModeData.singleModeNumAssessmentCode,
      }, {
        field: 'pen',
        condition: FilterCondition.MATCH,
        value: pen,
      }];
      let p3 = getScore(numeracyPagination, true).then(({ data: numData, count: numCount }) => {
        if (numCount > 0) {
          singleModeData.numeracyScore = numData[0].score;
        } else {
          singleModeData.numeracyScore = null;
        }
      });

      await Promise.all([p1, p2, p3]);
    }
    else {
      await (this.bcAssessments.getStudentPreIrtScore__new(
        undefined,
        AssessmentType.FSA,
        this.testWindow,
        true,
        this.districtDetail.groupId,
        school_group_id,
        pen,
        isScaled
      ) as Promise<IndividualRawScoresRow[]>).then(rows => {
        if (rows.length > 0) {
          let row = rows[0];

          singleModeData.singleModeName =
          {
            first_name: row.first_name,
            last_name: row.last_name,
          };

          singleModeData.studentSchool = schoolDetail.name;
          singleModeData.studentDistrict = this.districtDetail.foreignId;
          singleModeData.studentSchoolLang = schoolDetail.lang;

          singleModeData.singleModeLitAssessmentCode = `LT${language}${grade}`;
          singleModeData.singleModeNumAssessmentCode = `NM${language}${grade}`;
          singleModeData.grade = grade;
          singleModeData.pen = pen;

          singleModeData.literacyScore = row.literacyScore;
          singleModeData.numeracyScore = row.numeracyScore;

          singleModeData.isScaled = isScaled;
        }
      });
    }

    return singleModeData;
  }

  private createSingleModeDataV2(payload) {
    const { 
      first_name,
      last_name,
      pen,
      grade,
      lang,
      schoolName,
      literacyScore,
      numeracyScore,
      li_proficiency_level,
      nu_proficiency_level
    } = payload;

    const language = lang.toUpperCase() == 'EN' ? 'E' : 'F';
    const singleModeLitAssessmentCode = `LT${language}${grade}`;
    const singleModeNumAssessmentCode = `NM${language}${grade}`;

    const isScaled = this.selectedScoreType === ScoreType.SCALED_SCORE;
    console.log(isScaled);

    let singleModeData: SingleModeData = {
      literacyScore,
      numeracyScore,
      singlePagination: null,
      singleModePens: null,
      singleModeName: {
        first_name: first_name,
        last_name: last_name,
      },
      singleModeLitAssessmentCode,
      singleModeNumAssessmentCode,
      pen: pen,
      grade: grade,
      studentSchool: schoolName,
      studentDistrict: this.districtDetail.foreignId,
      studentSchoolLang: lang,
      isScaled,
      litLevel: isScaled ? li_proficiency_level : this.getProficiencyLevel('literacy', literacyScore, singleModeLitAssessmentCode, singleModeNumAssessmentCode, false),
      numLevel: isScaled ? nu_proficiency_level : this.getProficiencyLevel('numeracy', numeracyScore, singleModeLitAssessmentCode, singleModeNumAssessmentCode, false),
    };

    return singleModeData;
  }

  getLoadPercent() {
    if (!this.loadTotal) return 0;

    return this.loadAmt / this.loadTotal * 100;
  }



  translateLevel(level: any) {
    if (level == 'EM') {
      //return await translation.getOneBySlug('sa_sr_emerging', langCode.toLowerCase())
      return this.lang.tra('sa_sr_emerging');
    } else if (level == 'OT') {
      //return await translation.getOneBySlug('sa_sr_ontrack', langCode.toLowerCase())
      return this.lang.tra('sa_sr_ontrack');
    } else if (level == 'EX') {
      //return await translation.getOneBySlug('sa_sr_extend', langCode.toLowerCase())
      return this.lang.tra('sa_sr_extend');
    } else {
      //return await translation.getOneBySlug('sa_sr_plu', langCode.toLowerCase())
      return this.lang.tra('sa_sr_plu');
    }
  }

  getProficiencyLevel(type: 'literacy' | 'numeracy', score: number, singleModeLitAssessmentCode: any, singleModeNumAssessmentCode: any, isIrtReady: boolean): string {
    if (score === undefined || score === null) {
      return SchoolLevelDataReportProficiencyLevel.PLU;
    }

    let assessmentCode = type == 'literacy' ? singleModeLitAssessmentCode : singleModeNumAssessmentCode;
    let level = this.calculateLevelFromIrtScore(assessmentCode, score, isIrtReady);
    return level;
  }



  calculateLevelFromIrtScore(componentCode: string, score: number, isScaled: boolean) {
    return this.bcAccounts.calculateLevelFromIrtScore(componentCode, score, isScaled);
  }

  recentReports = [];

  async checkBulkReportStatus() {
    if (this.districtDetail && this.school) {
      this.recentReports = await this.auth.apiFind('public/bcg-scan/individual-student-results-report', {
        query: {
          district: this.districtDetail.groupId,
          school: this.school
        }
      });
    }
  }

  recentIncompleteReport() {
    if (!this.recentReports || this.recentReports.length == 0) {
      return null;
    }
    for (let report of this.recentReports) {
      if (!report.end) {
        return report;
      }
    }

    return null;
  }

  recentCompleteReport() {
    if (!this.recentReports || this.recentReports.length == 0) {
      return null;
    }
    for (let report of this.recentReports) {
      if (report.end) {
        return report;
      }
    }

    return null;

  }

  formatDate(date) {
    return formatDate(date, 'mediumDate', 'en-US');
  }


  timeSince(date) {
    date = +(new Date(date));
    let now = +new Date();

    var seconds = Math.floor((now - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " year(s)";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " month(s)";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " day(s)";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hour(s)";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minute(s)";
    }
    return Math.floor(seconds) + " seconds";
  }

  hasRecentReports() {
    return this.recentReports.length > 0;
  }

  getRecentSucceedReport() {
    return this.recentReports.find(rr => rr.is_succeed == 1);
  }

  async selectScoreType(type: ScoreType) {
    this.selectedScoreType = type;
    await this.loadReports();
  }

  private isFsaMinistryAdmin() {
    return this.auth.isFsaMinistryAdmin();
  }

  private isFsaSchoolAdmin(): boolean {
    return this.auth.isFsaSchoolAdmin();
  }

  private isFsaDistrictAdmin(): boolean { 
    return this.auth.isFsaDistrictAdmin();
  }

  public isFsaAdmin() {
    return this.isFsaMinistryAdmin() || this.isFsaSchoolAdmin() || this.isFsaDistrictAdmin();
  }

  private isIrtReadyOnCurrTestWindow(): boolean {
    return this.testWindow?.isIrtReady || false;
  }

  public isRawScoreAvailable(): boolean 
  {
    if (this.isFsaMinistryAdmin()) 
    {
      return true;
    }

    return false;
  }

  public isScaledScoreAvailable(): boolean 
  {
    if (this.isFsaMinistryAdmin()) 
    {
      return true;
    }

    return false;
  }


  private initSelectedScoreType(): void {
    if (this.isScaledScoreAvailable()) {
      this.selectedScoreType = ScoreType.SCALED_SCORE;
    } else {
      this.selectedScoreType = ScoreType.RAW_SCORE;
    }
  }
}

