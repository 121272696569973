import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { BreadcrumbsService } from "../../core/breadcrumbs.service";
import { LangService } from 'src/app/core/lang.service';
import { BcAccountsService, DistrictDetail, Filter, FilterCondition, Pagination, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { BcAssessmentsService, IScoreProfile, ScoreEntryStudentRowV2, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';

import { AuthService } from 'src/app/api/auth.service';
import { DataGuardService } from 'src/app/core/data-guard.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { LoginGuardService } from "../../api/login-guard.service";
import { cloneDeep } from 'lodash';
import { BcScoreEntryService } from 'src/app/bc-score-entry/bc-score-entry.service';
import { IScoreEntryCell, IScoreEntryData, ScoreEntryStatus } from 'src/app/bc-score-entry/types';

// interface IScoringPanelQuestion {
//   title: string,
//   scoreEntryField: string,
//   profile: IScoreProfile,
//   onChange: (field: string, score?: string) => void,
//   getDisplay?: (slug: string) => string;
//   disableOption?: (slug: string) => boolean;
// }
// interface IScoringPanel {
//   component: string,
//   questions: IScoringPanelQuestion[],
// }

// interface IScoreEntryPenOnlyRow {
//   uid: number,
//   pen: string,
// }

// interface IPageChangeRequest {
//   previousPagination: Pagination,
// }

@Component({
  selector: 'score-entry-focus-table',
  templateUrl: './score-entry-focus-table.component.html',
  styleUrls: ['./score-entry-focus-table.component.scss']
})
export class ScoreEntryFocusTableComponent implements OnInit, OnChanges {

  // @Input() testWindow: TestWindow;
  // @Input() school: SchoolDetail;
  // @Input() district: DistrictDetail;
  // @Input() themeProfile: IScoreProfile;
  // @Input() scoreProfile: IScoreProfile;
  // @Input() uid: number;
  // @Input() breadcrumbProxy: any[];
  @Input() selectedScoreEntryRowV2: ScoreEntryStudentRowV2;
  isLoading: boolean;
  scoreOptions: string[] = ['4', '3', '2', '1', '0', 'NR'];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private lang: LangService,
    private whitelabel: WhitelabelService,
    private loginGuard: LoginGuardService,
    private bcAccounts: BcAccountsService,
    private bcAssessments: BcAssessmentsService,
    private bcScoreEntry: BcScoreEntryService,
    private breadcrumbsService: BreadcrumbsService,
    private auth: AuthService,
    private dataGuard: DataGuardService,
  ) {
    this.isLoading = false;
  }

  ngOnInit(): void {
    console.log(this.selectedScoreEntryRowV2);
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }

  

}
