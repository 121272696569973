import { Component, OnInit, Input } from '@angular/core';
import { IContentElementText, TextParagraphStyle, QuestionState, TextParagraph, ElementType } from '../models';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { TextToSpeechService } from '../text-to-speech.service';
@Component({
  selector: 'element-render-text',
  templateUrl: './element-render-text.component.html',
  styleUrls: ['./element-render-text.component.scss']
})
export class ElementRenderTextComponent implements OnInit {

  @Input() element:IContentElementText;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() questionPubSub?: QuestionPubSub;

  constructor(
    private whitelabel: WhitelabelService,
    private text2Speech: TextToSpeechService
  ) { }

  thisText = '';

  ngOnInit() {
    this.isInitDynamicText()
    this.thisText = this.getElementCaption()
  }
  
  ngOnChange(){
    this.isInitDynamicText()
  }

  initElementSub(){
    this.questionPubSub.allSub().subscribe(payload => {
      if (payload.entryId !== this.element.fromEntryId || !this.element.isShowDynamicText) return;
      const state = this.questionState[payload.entryId]
      switch (state.type) {
        case ElementType.MCQ:
          if (!state.selections.length) return ;
          const selection = state.selections[0]
          let selectionStr = selection.content
          if (selection.paragraphStyle == TextParagraphStyle.ADVANCED_INLINE && selection.advancedList) {
            selectionStr = ''
            selection.advancedList.forEach((el)=>{
              if (el.elementType==ElementType.TEXT) {
                selectionStr += el.caption
              }
            })
          }
          //this.element.caption = selection.toString();
          this.thisText = selectionStr
          return;
        default:
          return;
      }
    });
  }

  ngDoCheck() {
    if(!this.element.isShowDynamicText) {
      this.thisText = this.getElementCaption()
    }
  }

  isInitDynamicText(){
    if(this.element.isShowDynamicText){
      this.element.caption = ''
      this.initElementSub()
    }
  }

  isParagraphs(){
    return this.element.paragraphStyle === TextParagraphStyle.PARAGRAPHS;
  }
  isHeading(){
    return this.element.paragraphStyle === TextParagraphStyle.HEADLINE;
  }
  isHeadingSmall(){
    return this.element.paragraphStyle === TextParagraphStyle.HEADLINE_SMALL;
  }
  isBody(){
    return this.element.paragraphStyle === TextParagraphStyle.REGULAR;
  }
  isSmall(){
    return this.element.paragraphStyle === TextParagraphStyle.SMALL;
  }
  isBulletList(){
    return this.element.paragraphStyle === TextParagraphStyle.BULLET;
  }
  isNumberedList(){
    return this.element.paragraphStyle === TextParagraphStyle.NUMBERED;
  }

  /* The white-space does not work with the 'text-content: justify' css prop
     in FireFox, so replace the newline character with the line break tag and 
     remove the white-space setting for FireFox
  */
  isFireFox(){
    let userAgent = navigator.userAgent;
    if(userAgent.match(/firefox|fxios/i))
      return true;
    return false;
  }
  getElementCaption(){
    if(this.isFireFox()){
      return this.element.caption.replace(/\n/g, '<br>')
    } else {
      return this.element.caption
    }
  }

  isSimpleText(){
    if (this.element.paragraphStyle === TextParagraphStyle.HEADLINE){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.HEADLINE_SMALL){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.REGULAR){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.SMALL){ return true }
    return false;
  }

  isLink(){
    return this.element.paragraphStyle === TextParagraphStyle.LINK;
  }

  isListText(){
    if (this.element.paragraphStyle === TextParagraphStyle.BULLET){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.NUMBERED){ return true }
    return false;
  }

  isAdvancedInlineText(){
    if (this.element.paragraphStyle === TextParagraphStyle.ADVANCED_INLINE){ return true }
    return false;
  }

  isAnnotation() {
    return this.element.paragraphStyle === TextParagraphStyle.ANNOTATION;
  }

  getFontFamily() {
    if (!this.whitelabel.getSiteFlag('IS_EQAO')){
      return this.element.font;
    }
    return 'inherit';
  }

  

  getRotation() {
    if (this.element.rotation) return "rotate("+this.element.rotation+"deg)";
    return '';
  }

  getFontSize(){
    if (this.element.fontSize){
      return `${this.element.fontSize}em`;
    }
    return 'inherit';
  }

  getLineHeight() {
    if (this.element.lineHeight){
      return `${this.element.lineHeight}em`;
    }
    return '1.3em';
  }

  renderParaNum(index:number, paragraph:TextParagraph){
    if (paragraph.numberingOverride){
      return paragraph.numberingOverride;
    }
    let start = this.element.paragraphNumberingStart || 1;
    return (+start) + index;
  }

  renderParaNumPadding(){
    return (1-(this.element.paragraphNumberingScale || 1)) / 4;
  }

  getTextStyle() {
    const style:any = {}
    style["font-family"]=this.getFontFamily();
    style["text-align"]=this.element.alignment;
    style["font-size"]=this.getFontSize();
    style["transform"]=this.getRotation();
    style["color"]= this.element.colour;
    //if (!this.isAdvancedInlineText()){
    style["line-height"]= this.getLineHeight()
    if (this.element.isNoInvertOnHiContrast && this.text2Speech.isHiContrast) {
      style["filter"]="invert(1)";
    }
    //}
    // style["user-select"]='none';
    return style;
  }

  getChildStyle() {
    const style: any = {}
    if (this.getTextStyle()["line-height"]) {
      style['line-height']='inherit'
    }
    return style;
  }

  minWidth = 10;
  getWidth() {
    if(this.isParagraphs()){
      return this.element.paragraphWidth
    }
    if (this.element.advancedInlineWidth && this.element.advancedInlineWidth >= this.minWidth) {
      return this.element.advancedInlineWidth;
    }
    return 'initial';
  }

  parseTextFormat(text:string) {
    if (!text) {
      text = ''
    }
    let regEx = /{{([0-9]+)\.(.+)}}/
    let match = text.match(regEx);
    if (match) {
      const id = match[1];
      const field = match[2];
      if (id && this.questionState[id] && this.questionState[id][field]!=undefined) {
        const replaceWith = this.questionState[id][field]
        const newText = text.replace(regEx, replaceWith)
        return newText;
      }
    }
    if(this.isParagraphs()){
      text = text.replace(/\n/g, '<br>')
    }
    return text;
  }
}