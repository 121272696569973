<div class="fsa-final-results-table">
    <div class="sa-final-results-landing" *ngIf="!selectedView">
        <div class="sa-final-results-intro">
            <tra-md [slug]="isEAO() ? 'sa_eao_fr_intro' : 'sa_fr_intro'"></tra-md>
            <!-- <tra slug="sa_fr_intro_p1"></tra>
            <b>
                <tra slug="sa_export"></tra>
            </b>
            <tra slug="sa_fr_intro_p2"></tra>
            <b>
                <tra slug="sa_assessment_session"></tra>
            </b>
            <tra slug="sa_fr_intro_p3"></tra> -->
        </div>

        <table class="table is-auto-width">
            <tr class="header-row">
                <td>
                    <tra slug="sa_sr_report_name"></tra>
                </td>
                <td>
                    <tra slug="sa_sr_desc"></tra>
                </td>
                <td>
                    <tra slug="sa_export"></tra>
                </td>
            </tr>
            <tr class="report-row">
                <td>
                    <a (click)="selectSchoolLevelDataReport()">
                        <tra slug="sc_sldr_title"></tra>
                    </a>
                </td>
                <td>
                    <span *ngIf="isCurrentUserFsaMinistryAdmin()">
                        <tra slug="sc_sldr_desc"></tra>
                    </span>

                    <span *ngIf="!isCurrentUserFsaMinistryAdmin()">
                        <tra slug="sc_sldr_desc_no_scaled"></tra>
                    </span>
                </td>
                <td>
                    <ng-container>
                        <button class="export-button" (click)="exportSchoolLevelDataReport()" [disabled]="isLoading">
                            <span class="icon"></span>
                            <span class="title">
                                <tra slug="sa_export"></tra>
                            </span>
                        </button>
                    </ng-container>
                </td>
            </tr>
            <tr class="report-row" *ngIf="!isScoreEntryAdmin() && !isFsaEaoMinistry">
                <td>
                    <a (click)="selectIndividualStudentResultsReport()">
                        <tra slug="sa_fr_individual_srr_title"></tra>
                    </a>
                </td>
                <td>
                    <tra slug="sa_fr_individual_srr_desc"></tra>
                </td>
                <td>
                    <ng-container *ngIf="isDownloadAllAvailable()">
                        <button 
                            class="export-button" 
                            (click)="exportIndividualStudentResultsReports()"
                            [disabled]="isDownloadAllDisabled()"
                            [class.is-disabled]="isDownloadAllDisabled()"
                        >
                            <span class="icon"></span>
                            <span class="title">
                                <tra slug="sa_download_all"></tra>
                            </span>
                        </button>
                    </ng-container>
                    <!-- <ng-container *ngIf="isDownloadingAll">
                        <div style="position:relative;">
                            <div class="ball-pulse">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>

                        </div>

                    </ng-container> -->
                </td>
            </tr>
            <tr *ngIf="auth.isMinistryAdmin()" class="report-row">                
                <td>
                    <a (click)="selectSelfReflectionReport()">
                        Self Reflection
                    </a>
                </td>
                <td>
                    Use this page to review how students responded to the self reflection.
                </td>
                <td>
                   
                </td>
            </tr>

            <ng-container *ngIf="isFsaEaoMinistry && odiFiles.length > 0">
                <tr *ngFor="let odiFile of odiFiles" class="report-row">                
                    <td>
                        {{ odiFile.caption }}
                    </td>
                    <td>
                        {{ odiFile.description }}
                    </td>
                    <td>
                        <a (click)="downloadFile(odiFile.testWindowFileId)" >Download</a>
                    </td>
                </tr>
            </ng-container>

            <tr *ngIf="auth.isMinistryAdmin() && !isEAO()" class="report-row">                
                <td>
                    <a class="disabled" (click)="selectStudentAnalysisReport()">
                        Student Analysis
                    </a>
                </td>
                <td>
                    Use this page to view student analysis codes.
                </td>
                <td>
                   
                </td>
            </tr>
        </table>
    </div>

    <ng-container *ngIf="selectedView">
        <div class="no-district" *ngIf="!districtDetail && [SaFinalResultsViews.SCHOOL_LEVEL_DATA_REPORT,SaFinalResultsViews.INDIVIDUAL_STUDENT_RESULTS_REPORT].includes(selectedView)">
            <tra slug="sa_fr_issr_selectDistrict"></tra>
        </div>

        <ng-container *ngIf="selectedView && testWindow && (districtDetail || selectedView == SaFinalResultsViews.SELF_REFLECTION_REPORT)" [ngSwitch]="selectedView">
            <individual-student-reports *ngSwitchCase="SaFinalResultsViews.INDIVIDUAL_STUDENT_RESULTS_REPORT"
                [districtDetail]="districtDetail" [schoolDetail]="schoolDetail" [testWindow]="testWindow"
                [accountType]="accountType">
            </individual-student-reports>
            <school-level-data-report *ngSwitchCase="SaFinalResultsViews.SCHOOL_LEVEL_DATA_REPORT"
                [testWindow]="testWindow" [districtDetail]="districtDetail" [schoolDetail]="schoolDetail"
                [includeScaled]="sldIncludeScaled" [accountType]="accountType">
            </school-level-data-report>
            <fsa-self-reflection *ngSwitchCase="SaFinalResultsViews.SELF_REFLECTION_REPORT" 
                [testWindow]="testWindow">
            </fsa-self-reflection>
            
        </ng-container>

        <ng-container *ngIf="selectedView && selectedView == SaFinalResultsViews.STUDENT_RESULTS">
            <fsa-student-result 
                [testWindow]="testWindow"
                [districtDetail]="districtDetail" 
                [schoolDetail]="schoolDetail"
            >
            </fsa-student-result>
        </ng-container>

    </ng-container>

</div>