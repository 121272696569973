<div zwibbler #zwibbler z-controller="MyController" id="{{ 'zwib' + id }}" class="full flex-col draw-frame" showToolbar="false" z-no-auto-init pageView="false" pagePlacement="centre" defaultZoom="page" defaultPaperSize="none" defaultBrushWidth="4">
    <div z-canvas class="stretch"></div>
    <div toolbar class="dialog zwibbler-passthrough flex-row wrap is-main">
        <button z-If="ctx['mode'] !== 'review'" z-click="ctx.usePickTool(ctx.focus())" z-selected="ctx.getCurrentTool()==='pick'" title="Select" id="pick">
            <i class="fas fa-mouse-pointer"></i>
            <p class="toolbar-label">Select</p>
        </button>
        <button z-click="ctx.usePanTool(ctx.focus())" z-selected="ctx.getCurrentTool()==='pan'" title="Pan" id="pan">
            <i class="far fa-hand-paper"></i>
            <p class="toolbar-label">Pan</p>
        </button>
        <button z-If="ctx['mode'] !== 'review'" z-show-popup="palette" title="Palette">
            <i class="fas fa-palette" [ngStyle]="{'color': currentColour}" ></i>
            <p class="toolbar-label">Palette</p>
        </button>
        <button z-If="ctx['mode'] !== 'review'" z-click="ctx.useTextTool(ctx.renderTextConfig({}), ctx.focus())" title="Text" z-selected="ctx.getCurrentTool()==='text'" id="text">
            <i class="fas fa-font"></i>
            <p class="toolbar-label">Text</p>
        </button>
        <button z-If="ctx['mode'] !== 'review'" z-click="ctx.useFreehandTool()" z-selected="ctx.getCurrentTool()==='freehand'" title="Pencil" id="freehand">
            <i class="fas fa-pencil-alt"></i>
            <p class="toolbar-label">Pencil</p>
        </button>
        <button z-If="ctx['mode'] !== 'review'" z-show-popup="stampPalette" title="Stamp" id="imagestamp">
            <i z-class="{
                'fas': ctx.getCurrentTool() !== 'imagestamp',
                'fa-stamp': ctx.getCurrentTool() !== 'imagestamp',
                'stampButton': ctx.getCurrentTool() === 'imagestamp',
                'button': ctx.getCurrentTool() === 'imagestamp',
                'currStamp': ctx.getCurrentTool() === 'imagestamp',
                'blackTriangleButton': ctx.isCurrStamp('triangle', '#000'),
                'redTriangleButton':   ctx.isCurrStamp('triangle', '#f7412d'),
                'greenTriangleButton': ctx.isCurrStamp('triangle', '#47b04b'),
                'blueTriangleButton':  ctx.isCurrStamp('triangle', '#1194f6'),
                'goldTriangleButton':  ctx.isCurrStamp('triangle', '#ffc200'),
                'purpleTriangleButton':ctx.isCurrStamp('triangle', '#9d1bb2'),
                'pinkTriangleButton':  ctx.isCurrStamp('triangle', '#ec1561'),
                'brownTriangleButton': ctx.isCurrStamp('triangle', '#7a5547'),
                'blackSquareButton': ctx.isCurrStamp('square', '#000'),
                'redSquareButton':   ctx.isCurrStamp('square', '#f7412d'),
                'greenSquareButton': ctx.isCurrStamp('square', '#47b04b'),
                'blueSquareButton':  ctx.isCurrStamp('square', '#1194f6'),
                'goldSquareButton':  ctx.isCurrStamp('square', '#ffc200'),
                'purpleSquareButton':ctx.isCurrStamp('square', '#9d1bb2'),
                'pinkSquareButton':  ctx.isCurrStamp('square', '#ec1561'),
                'brownSquareButton': ctx.isCurrStamp('square', '#7a5547'),
                'blackStarButton': ctx.isCurrStamp('star', '#000'),
                'redStarButton':   ctx.isCurrStamp('star', '#f7412d'),
                'greenStarButton': ctx.isCurrStamp('star', '#47b04b'),
                'blueStarButton':  ctx.isCurrStamp('star', '#1194f6'),
                'goldStarButton':  ctx.isCurrStamp('star', '#ffc200'),
                'purpleStarButton':ctx.isCurrStamp('star', '#9d1bb2'),
                'pinkStarButton':  ctx.isCurrStamp('star', '#ec1561'),
                'brownStarButton': ctx.isCurrStamp('star', '#7a5547'),
                'blackCircleButton': ctx.isCurrStamp('circle', '#000'),
                'redCircleButton':   ctx.isCurrStamp('circle', '#f7412d'),
                'greenCircleButton': ctx.isCurrStamp('circle', '#47b04b'),
                'blueCircleButton':  ctx.isCurrStamp('circle', '#1194f6'),
                'goldCircleButton':  ctx.isCurrStamp('circle', '#ffc200'),
                'purpleCircleButton':ctx.isCurrStamp('circle', '#9d1bb2'),
                'pinkCircleButton':  ctx.isCurrStamp('circle', '#ec1561'),
                'brownCircleButton': ctx.isCurrStamp('circle', '#7a5547'),
            }"></i>
            <p class="toolbar-label">Stamp</p>
        </button>
        <button z-If="ctx['mode'] !== 'review'" z-show-popup="dashes" title="Line Style">
            <i class="fas"
                z-class="{
                    'fa-grip-lines': ctx.getCurrLineStyle() === undefined,
                    'rep-line': ctx.getCurrLineStyle() !== undefined,
                    'dashed': ctx.getCurrLineStyle() === '6,3',
                }"></i>
                <p class="toolbar-label" [style.lineHeight.em]="0.75">Line<br><br/>Style</p>
        </button>
        <button z-If="ctx['mode'] !== 'review'" z-click="ctx.useLineTool(ctx.renderDashesConfig({}),{singleLine: true}, ctx.focus())" title="Line" z-selected="ctx.getCurrentTool()==='line'" id="line">
            <i class="fas fa-pencil-ruler"></i>
            <p class="toolbar-label">Line</p>
        </button>
        <button z-If="ctx['mode'] !== 'review'" z-click="ctx.useArrowTool(ctx.renderDashesConfig({}), ctx.focus())" title="Arrow" z-selected="ctx.getCurrentTool()==='arrow'" id="arrow">
            <i class="fas fa-long-arrow-alt-up"></i>
            <p class="toolbar-label">Arrow</p>
        </button>
        <!-- <button z-click="ctx.useBrushTool({
            lineWidth: 10,
            strokeStyle: 'erase',
            layer: 'my_eraser_layer'})" z-selected="ctx.getCurrentTool()==='brush'" title="Eraser">
            <i class="fas fa-eraser"></i>
        </button> -->

        <button z-If="ctx['mode'] !== 'review'" z-show-popup="shapes" z-selected="ctx.getCurrentTool()==='rectangle' || ctx.getCurrentTool()==='circle'" title="Shape" id="shape">
            <i z-class="{
            'fas':  ctx.getCurrFillStyle() === 'filled' || (ctx.getCurrentTool() !=='rectangle' && ctx.getCurrentTool() !=='circle'),
            'far': ctx.getCurrFillStyle() === 'transparent',
            'fa-shapes': ctx.getCurrentTool() !=='rectangle' && ctx.getCurrentTool() !=='circle', 
            'fa-square': ctx.getCurrentTool() ==='rectangle',
            'fa-circle': ctx.getCurrentTool() ==='circle' }"></i>
            <p class="toolbar-label">Shapes</p>
        </button>
    <!-- <div style="margin-top:0.02em; border-top:1px solid #fff; height:50px; position:relative" class="btn-line">
        <button (click)="setDashes(false)" style="width:42px; height:15px;" [class.is-selected]="!isDashesEnabled"> <div class="rep-line"></div> </button>
        <button (click)="setDashes(true)" style="width:42px; height:15px;" [class.is-selected]="isDashesEnabled"> <div class="rep-line dashed"></div> </button>
    </div> -->
    </div>
    <div toolbar class="dialog zwibbler-passthrough flex-row is-doc">
        <button z-click="ctx.zoomIn()" title="Zoom in" z-disabled="ctx.isMaxZoom()">
            <i class="fas fa-search-plus"></i>
        </button>
        <div class="zoom-text">
            <p>{{ currZoom }}%</p>
        </div>
        <button z-click="ctx.zoomOut()" title="Zoom out" z-disabled="ctx.isMinZoom()">
            <i class="fas fa-search-minus"></i>
        </button>
        <div z-If="ctx['mode'] !== 'review'">
            <button z-click="ctx.copy()" title="Copy">
                <i class="fas fa-copy"></i>
            </button>
            <button z-click="ctx.paste()" title="Paste">
                <i class="fas fa-paste"></i>
            </button>
            <button z-click="ctx.deleteSelection()" title="Delete Selection">
                <i class="fas fa-trash-alt"></i>
            </button>
            <button z-click="ctx.undo()" title="Undo" z-disabled="!ctx.canUndo()">
                <i class="fas fa-undo"></i>
            </button>
            <button z-click="ctx.redo()" title="Redo" z-disabled="!ctx.canRedo()">
                <i class="fas fa-redo"></i>
            </button>
        </div>
    </div>

    <div z-popup="palette" class="dialog" z-click-dismiss z-show-position="tr tl">
        <div class="swatch" z-for="colour in colours" z-click="ctx.setColor(colour, true)" z-on:contextmenu="ctx.setColor(colour, false)" z-style="{backgroundColor:colour}">
        </div>
    </div>

    <div z-popup="stampPalette" class="dialog" z-click-dismiss z-show-position="tr tl">
        <!-- <div class="swatch" z-for="index in 8" z-click="ctx.useStampTool({url: images[index], width: 50}, true)" z-style="{backgroundImage: `url(${images[index]})`}">
        </div> -->
        <!-- <button z-style="{backgroundImage: 'url(\'assets/RedTriangle.png\')'}" z-click="ctx.useStampTool({url: 'assets/RedTriangle.png', width: 50}, true)"></button> -->
        <button z-click="ctx.setCurrStamp({url: 'Triangle', width:15}, true, 'triangle')" class="stampButton" [class.redTriangleButton]="currentColour == '#f7412d'" [class.blackTriangleButton]="currentColour == '#000' || currentColour == '#000000'" [class.greenTriangleButton]="currentColour == '#47b04b'" [class.blueTriangleButton]="currentColour == '#1194f6'" [class.goldTriangleButton]="currentColour == '#ffc200'" [class.purpleTriangleButton]="currentColour == '#9d1bb2'" [class.pinkTriangleButton]="currentColour == '#ec1561'" [class.brownTriangleButton]="currentColour == '#7a5547'"></button>
        <button z-click="ctx.setCurrStamp({url: 'Square', width:15}, true, 'square')" class="stampButton" [class.redSquareButton]="currentColour == '#f7412d'" [class.blackSquareButton]="currentColour == '#000' || currentColour == '#000000'" [class.greenSquareButton]="currentColour == '#47b04b'" [class.blueSquareButton]="currentColour == '#1194f6'" [class.goldSquareButton]="currentColour == '#ffc200'" [class.purpleSquareButton]="currentColour == '#9d1bb2'" [class.pinkSquareButton]="currentColour == '#ec1561'" [class.brownSquareButton]="currentColour == '#7a5547'"></button>
        <button z-click="ctx.setCurrStamp({url: 'Star', width:15}, true, 'star')" class="stampButton" [class.redStarButton]="currentColour == '#f7412d'" [class.blackStarButton]="currentColour == '#000' || currentColour == '#000000'" [class.greenStarButton]="currentColour == '#47b04b'" [class.blueStarButton]="currentColour == '#1194f6'" [class.goldStarButton]="currentColour == '#ffc200'" [class.purpleStarButton]="currentColour == '#9d1bb2'" [class.pinkStarButton]="currentColour == '#ec1561'" [class.brownStarButton]="currentColour == '#7a5547'"></button>
        <button z-click="ctx.setCurrStamp({url: 'Circle', width:15}, true, 'circle')" class="stampButton" [class.redCircleButton]="currentColour == '#f7412d'" [class.blackCircleButton]="currentColour == '#000' || currentColour == '#000000'" [class.greenCircleButton]="currentColour == '#47b04b'" [class.blueCircleButton]="currentColour == '#1194f6'" [class.goldCircleButton]="currentColour == '#ffc200'" [class.purpleCircleButton]="currentColour == '#9d1bb2'" [class.pinkCircleButton]="currentColour == '#ec1561'" [class.brownCircleButton]="currentColour == '#7a5547'"></button>
    </div>

    <div z-popup="shapes" class="dialog" z-click-dismiss>
        <button z-click="ctx.useRectangleTool(ctx.renderShapeOutlineConfig({}))"><i class="far fa-square"></i></button>
        <button z-click="ctx.useRectangleTool(ctx.renderFilledShapeConfig({strokeStyle:'transparent'}))"><i class="fas fa-square"></i></button>
        <button z-click="ctx.useCircleTool(ctx.renderShapeOutlineConfig({}))"><i class="far fa-circle"></i></button>
        <button z-click="ctx.useCircleTool(ctx.renderFilledShapeConfig({strokeStyle:'transparent'}))"><i class="fas fa-circle"></i></button>
    </div>
    <div z-popup="dashes" class="dialog" z-click-dismiss>
        <button z-click="ctx.setCurrLineStyle(null)" style="width:42px; height:15px;"> <div class="rep-line"></div> </button>
        <button z-click="ctx.setCurrLineStyle('6,3')" style="width:42px; height:15px;"> <div class="rep-line dashed"></div> </button>
    </div>
</div>

<div id="preview">
    <img [src]="pngFile">
</div>