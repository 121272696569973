// app services
import { AssetLibraryService, IAssetLibraryConfig} from '../../services/asset-library.service';
import { AssignedUsersService } from '../../assigned-users.service';
import { AuthScopeSettingsService, AuthScopeSetting } from "../../auth-scope-settings.service";
import { AuthService } from '../../../api/auth.service';
import { DataGuardService } from '../../../core/data-guard.service';
import { EditingDisabledService } from '../../editing-disabled.service';
import { ItemComponentEditService } from '../../item-component-edit.service';
import { ItemMakerService } from '../../item-maker.service';
import { LangService } from '../../../core/lang.service';
import { LoginGuardService } from '../../../api/login-guard.service';
import { PrintAltTextService } from '../../print-alt-text.service';
import { PrintModeService } from '../../print-mode.service';
import { RoutesService } from '../../../api/routes.service';
import { ScriptGenService } from '../../script-gen.service';
import { StyleprofileService } from '../../../core/styleprofile.service';
import { WhitelabelService } from '../../../domain/whitelabel.service';

import { IContentElement, IQuestionConfig } from "../../../ui-testrunner/models";
import { FormControl } from '@angular/forms';
import { elementIconById, createDefaultElement, frameElement} from '../models';
import { ItemBankCtrl } from './item-bank';
import { ItemBankSaveLoadCtrl } from './save-load';
import { Subscription } from 'rxjs';
import { FrameworkQuadrantCtrl } from './quadrants';
import { ItemSetFrameworkCtrl } from './framework';
import { TestletCtrl } from './testlets';
import { ItemFilterCtrl } from './item-filter';

export class ItemEditCtrl {

  isElementRestoreOpen: boolean;
  isElementImportExportOpen: boolean;
  isElementImportExportSkipId: boolean;
  currentElement: IQuestionConfig;
  currentElementImportExport = new FormControl('');
  isRightPanelExpanded = false;
  isProtected = new FormControl(false);
  isLocked: boolean = false;
  isVoiceUpload: boolean = false;
  
  public itemFilterCtrl:ItemFilterCtrl;
  constructor(
    public lang: LangService,
    public scriptGen: ScriptGenService,
    public frameworkCtrl: ItemSetFrameworkCtrl,
    public itemBankCtrl: ItemBankCtrl,
    public saveLoadCtrl: ItemBankSaveLoadCtrl,
    public editingDisabled: EditingDisabledService,
    public quadrantCtrl: FrameworkQuadrantCtrl,
    public testletCtrl: TestletCtrl,
  ){

  }

  toggleQuestionLock(){
    this.isLocked = !this.isLocked;
    if (this.itemBankCtrl.activeQuestionState){
      this.itemBankCtrl.rawQuestionState = JSON.stringify(this.itemBankCtrl.activeQuestionState)
    }
  }

  // registerFormControlsForDisable() {
  //   this.editingDisabled.registerFormControlsArr([
  //     this.itemBankCtrl.currentQuestionNotes,
  //     this.frameworkCtrl.testFormConstructionMethod,
  //     this.frameworkCtrl.questionWordingFc,
  //     this.quadrantCtrl.showQuadrantConfigDetail,
  //     this.frameworkCtrl.selectedNewItemBank,
  //     this.testletCtrl.isTestletFiltersActive,
  //     this.currentElementImportExport,
  //     this.itemFilterCtrl.isQuestionFiltersActive,
  //     // this.currentQuestionLabel
  //   ]);
  // }


  getQuestionVoiceScript(question){
    let questionRef = question;
    if (this.lang.c() === 'fr'){
      questionRef = question.langLink;
    }
    return questionRef.voiceover.script
  }

  autoGenQuestionScript(question:IQuestionConfig){
    this.scriptGen.autoGenQuestionVoiceover(question, this.lang.c());
  }

  isRefreshing:boolean;
  loadQuestionState(){
    this.isRefreshing = true;
    this.itemBankCtrl.activeQuestionState = JSON.parse(prompt('Copy and paste response_raw JSON here'))
    setTimeout(()=>{
      this.isRefreshing = false;
    }, 1000)
    console.log('activeQuestionState', this.itemBankCtrl.activeQuestionState)
  }

  
  scrollToQuestionLeftPanel() {
    try {
      const el: any = document.querySelector('.question-block.is-active');
      const options = {block: 'start'};
      try {
        el.scrollIntoView(options);
      } catch (e) {
        el.scrollIntoViewIfNeeded(options);
      }
    } catch (e) {

    }
  }

  isReadOnly = () => this.editingDisabled.isReadOnly();

  createNewStackElement(elementType: string, isDisabled: boolean = false) {
    if (isDisabled) { return; }
    let newElement = createDefaultElement(elementType);
    const currQuestionContent = this.itemBankCtrl.getCurrentQuestionContent()
    currQuestionContent.content.push({
      ...newElement,
      entryId: currQuestionContent.content.length + 1
    });
    console.log('getCurrentQuestionContent', currQuestionContent.content)
  }


  transferFromLang() {
    if (confirm('Are you sure you want to transfer the content from the other language? This will overwrite anything you have currently visible in the question now.')) {
      let source, target;
      if (this.lang.c() === 'fr') {
        source = this.itemBankCtrl.currentQuestion;
        target = this.itemBankCtrl.currentQuestion.langLink;
      } else {
        source = this.itemBankCtrl.currentQuestion.langLink;
        target = this.itemBankCtrl.currentQuestion;
      }
      target.content = JSON.parse(JSON.stringify(source.content));
    }
  }

  openElementRestore() {
    this.saveLoadCtrl.restoreENFc.setValue(this.lang.getCurrentLanguage() === 'en');
    this.saveLoadCtrl.restoreFRFc.setValue(this.lang.getCurrentLanguage() === 'fr');
    this.saveLoadCtrl.restoreParamsFc.setValue(true);
    this.isElementRestoreOpen = true;
  }

  frameElement = (content:any[], element:IContentElement) => frameElement(content, element);

  closeElementRestore() {
    this.isElementRestoreOpen = false;
  }

  openElementImportExport(contentElement: IQuestionConfig) {
    this.isElementImportExportOpen = true;
    this.isElementImportExportSkipId = false;
    this.currentElement = contentElement;
    this.currentElementImportExport.setValue(JSON.stringify(contentElement));
  }
  startImportExportQuestion() {
    this.isElementImportExportOpen = true;
    this.isElementImportExportSkipId = true;
    this.currentElement = this.itemBankCtrl.currentQuestion;

    if(this.lang.getCurrentLanguage() === 'en') {
      this.saveLoadCtrl.importEN.setValue(true);
      this.saveLoadCtrl.importFR.setValue(false);
    } 
    else if(this.lang.getCurrentLanguage() === 'fr') {
      this.saveLoadCtrl.importEN.setValue(false);
      this.saveLoadCtrl.importFR.setValue(true);
    }

    this.currentElementImportExport.setValue(JSON.stringify(this.currentElement));
  }

  importFormIntoElement() {
    const newEl = JSON.parse(this.currentElementImportExport.value);
    //console.log(newEl)
    Object.keys(newEl).forEach(key => {
      if (!this.saveLoadCtrl.importParams.value && key === 'meta') { return; }
      //Should still import the meta even if not importing english
      if(!this.saveLoadCtrl.importEN.value && key !== 'langLink' && key !== 'meta') { return; }
      if(!this.saveLoadCtrl.importFR.value && key === 'langLink' ) { return; }
      if (this.isElementImportExportSkipId && key === 'id') { return; }
      //console.log(key)
      this.currentElement[key] = newEl[key];
    });
    this.closeElementImportExport();
  }
  closeElementImportExport() {
    this.isElementImportExportOpen = false;
    this.currentElement = null;
  }

  isCurrentEnabledQuestion(question) {
    if (question === this.itemBankCtrl.currentQuestion) {
      return true;
    }
    return false;
  }


  changeQuestionCaption(question) {
    let str = prompt('New question name', question.caption);
    if (str) {
      question.caption = str;
    }
  }
  
  toggleIsVoiceUpload() {
    if (this.isVoiceUpload) {
      this.isVoiceUpload = false;
    }
    else {
      this.isVoiceUpload = true;
    }
  }

  deleteReadSelection = (index: number) => {
    const currentQ = this.itemBankCtrl.getCurrentQuestionContent();
    const i = this.itemBankCtrl.getReadSelIndex(currentQ, index);
    const readSel = currentQ.readSelections[i];
    currentQ.readSelections.splice(i, 1);

    if(currentQ.readSelProps) {
      currentQ.readSelProps[readSel] = undefined;
    }
  }

  addSelection() {
    const currQuestion = this.itemBankCtrl.getCurrentQuestionContent();
    const newReadSel = this.itemBankCtrl.currentQuestionReadSel.value;
    if (!currQuestion.readSelections){
      currQuestion.readSelections = [];
    }
    if (!currQuestion.readSelections.includes(this.itemBankCtrl.currentQuestionReadSel.value)){
      currQuestion.readSelections.push(this.itemBankCtrl.currentQuestionReadSel.value);
    }
    if (!currQuestion.readSelProps) {
      currQuestion.readSelProps = {};
    }
    currQuestion.readSelProps[newReadSel] = this.itemBankCtrl.generateDefaultReadSelProps();
  }
  insertUpload(question) {
    if (!question.uploads) {
      question.uploads = [];
    }
    question.uploads.push({});
  }

  toggleReadSelVisible(readSel: string) {
    const q = this.itemBankCtrl.getCurrentQuestionContent();
    if(!q.readSelProps) {
      q.readSelProps = {};
    }
    if(!q.readSelProps[readSel]) {
      q.readSelProps[readSel] = this.itemBankCtrl.generateDefaultReadSelProps();
    }
    const prevVisible = this.itemBankCtrl.isReadSelVisible(q, readSel);

    q.readSelProps[readSel].isVisible = !prevVisible;
  }
}