import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyBlockArrSelectComponent } from './body-block-arr-select/body-block-arr-select.component';
import { UiChartsModule } from '../ui-charts/ui-charts.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { ListTravArrowsComponent } from './list-trav-arrows/list-trav-arrows.component';
import { CheckToggleComponent } from './check-toggle/check-toggle.component';
import { TraComponent } from './tra/tra.component';
import { TraMdComponent } from './tra-md/tra-md.component';
import { MarkdownModule } from 'ngx-markdown';
import { InfoCenterDetailsComponent } from './info-center-details/info-center-details.component';
import { ModalComponent } from './modal/modal.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { TableRowSelectorComponent } from './table-row-selector/table-row-selector.component';
import { EditSaveComponent } from './edit-save/edit-save.component';
import { InputTimeComponent } from './input-time/input-time.component';
import { InputDateComponent } from './input-date/input-date.component';
import { TableHeaderComponent } from './table-header/table-header.component';
import { CaptureFilterRangeComponent } from './capture-filter-range/capture-filter-range.component';
import { LineChartModule, PieChartModule, BarChartModule } from '@swimlane/ngx-charts';
import { ExpectationTableComponent } from './expectation-table/expectation-table.component';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ChatEmojiPickerComponent } from './chat-emoji-picker/chat-emoji-picker.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import { CustomColumnFilterComponent } from './custom-column-filter/custom-column-filter.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { UiSidebarComponent } from '../ui-sidebar/ui-sidebar.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToggleComponent } from './toggle/toggle.component';
import { AnnotationToolsComponent } from './annotation-tools/annotation-tools.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { DefaultBrowserBehavior } from 'amazon-chime-sdk-js';
import { FilterTogglesComponent } from './filter-toggles/filter-toggles.component';
import { TwiddleComponent } from './twiddle/twiddle.component';
import { NumScrubComponent } from './num-scrub/num-scrub.component';
import { BcExportButtonComponent } from './bc-export-button/bc-export-button.component';
import { BcPaginatedTableComponent } from './bc-paginated-table/bc-paginated-table.component';
import { BcPaginatorComponent } from './bc-paginator/bc-paginator.component';
import { BcHeaderLayoutComponent } from './bc-header-layout/bc-header-layout.component';
import { BcToggleComponent } from './bc-toggle/bc-toggle.component';
import { ProgressReportComponent } from './progress-report/progress-report.component';
import { SchoolLevelDataReportComponent } from './school-level-data-report/school-level-data-report.component';
import { SchoolCompletionReportComponent } from './school-completion-report/school-completion-report.component';
import { BcSearchDropdownComponent } from './bc-search-dropdown/bc-search-dropdown.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import { ConfirmationCodeReportComponent } from './confirmation-code-report/confirmation-code-report.component';
import { BcTabsComponent } from './bc-tabs/bc-tabs.component';
import { FsaSchoolsNotStartedReportComponent } from './fsa-schools-not-started-report/fsa-schools-not-started-report.component';
import { BcAccountsAdminsSchoolComponent } from './bc-accounts-admins-school/bc-accounts-admins-school.component';
import { BcAccountsAdminsDistrictComponent } from './bc-accounts-admins-district/bc-accounts-admins-district.component';
import { BcAccountsAdminsComponent } from './bc-accounts-admins/bc-accounts-admins.component';
import { BcUploadWidgetComponent } from './bc-upload-widget/bc-upload-widget.component';
import { RollUpReportComponent } from './roll-up-report/roll-up-report.component';
import { StudentLoginReportComponent } from '././student-login-report/student-login-report.component';
import { ScoreEntryDocumentsComponent } from './score-entry-documents/score-entry-documents.component';
import { ScoreEntryStudentsComponent } from './score-entry-students/score-entry-students.component';
import { ScoreEntryAggregationTableComponent } from './score-entry-aggregation-table/score-entry-aggregation-table.component';
import { ScoreEntryStudentsTableComponent } from './score-entry-students-table/score-entry-students-table.component';
import { StudentIndividualReportComponent } from './student-individual-report/student-individual-report.component';
import { StudentIndividualReportBulkComponent } from './student-individual-report-bulk/student-individual-report-bulk.component';
import { InputListSelectComponent } from './input-list-select/input-list-select.component';
import { BcAssessmentMaterialsComponent } from './bc-assessment-materials/bc-assessment-materials.component';
import { AdminSessionComponent } from './admin-session/admin-session.component';
import { BcAccountsStudentsEnrollComponent } from './bc-accounts-students-enroll/bc-accounts-students-enroll.component';
import { MatRadioModule } from '@angular/material/radio';
import { IndividualStudentReportsComponent } from './individual-student-reports/individual-student-reports.component';
import { FsaFinalResultsTableComponent } from './fsa-final-results-table/fsa-final-results-table.component';
import { FsaSampleAssessmentLoginReportComponent } from './fsa-sample-assessment-login-report/fsa-sample-assessment-login-report.component';
import { StudentAssessmentStatusComponent } from '../components/student-assessment-status-card/student-assessment-status-card.component';
import { FsaSelfReflectionComponent } from './fsa-self-reflection/fsa-self-reflection.component';
import { FsaStudentResultComponent } from './fsa-student-result/fsa-student-result.component';
import { AgGridModule } from 'ag-grid-angular';
import { ScoreEntryFocusTableComponent } from './score-entry-focus-table/score-entry-focus-table.component';

import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { MatExpansionModule } from '@angular/material/expansion';
@NgModule({
  providers: [
    DefaultBrowserBehavior
  ],
  imports: [
    AgGridModule,
    CommonModule,
    RouterModule,
    MarkdownModule,
    ReactiveFormsModule,
    LineChartModule,
    PieChartModule,
    BarChartModule,
    FormsModule,
    UiChartsModule,
    PickerModule,
    EmojiModule,
    TextFieldModule,
    DragDropModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    AutoSizeInputModule,
    MatRadioModule,
    MatExpansionModule
  ],
  declarations: [
    BcAccountsStudentsEnrollComponent,
    BodyBlockArrSelectComponent,
    FooterComponent,
    HeaderComponent,
    ListTravArrowsComponent,
    CheckToggleComponent,
    TraComponent,
    TraMdComponent,
    InfoCenterDetailsComponent,
    ModalComponent,
    PaginatorComponent,
    MenuBarComponent,
    TableRowSelectorComponent,
    EditSaveComponent,
    InputTimeComponent,
    InputDateComponent,
    TableHeaderComponent,
    CaptureFilterRangeComponent,
    ExpectationTableComponent,
    ChatBoxComponent,
    UiSidebarComponent,
    ChatEmojiPickerComponent,
    ChatMessageComponent,
    ModalFooterComponent,
    CustomColumnFilterComponent,
    LoadingSpinnerComponent,
    ToggleComponent,
    AnnotationToolsComponent,
    LoadingIndicatorComponent,
    FilterTogglesComponent,
    TwiddleComponent,
    NumScrubComponent,
    BcExportButtonComponent,
    BcPaginatedTableComponent,
    BcPaginatorComponent,
    BcHeaderLayoutComponent,
    BcToggleComponent,
    ProgressReportComponent,
    SchoolLevelDataReportComponent,
    SchoolCompletionReportComponent,
    BcSearchDropdownComponent,
    FsaSchoolsNotStartedReportComponent,
    ConfirmationCodeReportComponent,
    BcTabsComponent,
    BcAccountsAdminsComponent,
    BcAccountsAdminsSchoolComponent,
    BcAccountsAdminsDistrictComponent,
    BcUploadWidgetComponent,
    RollUpReportComponent,
    StudentLoginReportComponent,
    ScoreEntryDocumentsComponent,
    ScoreEntryStudentsComponent,
    ScoreEntryAggregationTableComponent,
    ScoreEntryStudentsTableComponent,
    StudentIndividualReportComponent,
    StudentIndividualReportBulkComponent,
    InputListSelectComponent,
    BcAssessmentMaterialsComponent,
    AdminSessionComponent,
    IndividualStudentReportsComponent,
    FsaFinalResultsTableComponent,
    FsaSampleAssessmentLoginReportComponent,
    StudentAssessmentStatusComponent,
    FsaSelfReflectionComponent,
    FsaStudentResultComponent,
    ExpansionPanelComponent,
    ScoreEntryFocusTableComponent
  ],
  exports: [
    BcAccountsStudentsEnrollComponent,
    UiSidebarComponent,
    BodyBlockArrSelectComponent,
    FooterComponent,
    HeaderComponent,
    ListTravArrowsComponent,
    CheckToggleComponent,
    TraComponent,
    TraMdComponent,
    InfoCenterDetailsComponent,
    PaginatorComponent,
    MenuBarComponent,
    EditSaveComponent,
    TableRowSelectorComponent,
    TableHeaderComponent,
    InputTimeComponent,
    InputDateComponent,
    CaptureFilterRangeComponent,
    ExpectationTableComponent,
    ChatBoxComponent,
    ToggleComponent,
    AnnotationToolsComponent,
    LoadingIndicatorComponent,
    ModalFooterComponent,
    FilterTogglesComponent,
    TwiddleComponent,
    NumScrubComponent,
    BcExportButtonComponent,
    BcHeaderLayoutComponent,
    BcToggleComponent,
    BcPaginatedTableComponent,
    MatSlideToggleModule,
    ProgressReportComponent,
    SchoolLevelDataReportComponent,
    FsaSchoolsNotStartedReportComponent,
    ConfirmationCodeReportComponent,
    BcTabsComponent,
    BcUploadWidgetComponent,
    ScoreEntryDocumentsComponent,
    ScoreEntryStudentsComponent,
    StudentIndividualReportComponent,
    StudentIndividualReportBulkComponent,
    InputListSelectComponent,
    BcAssessmentMaterialsComponent,
    SchoolCompletionReportComponent,
    AdminSessionComponent,
    BcSearchDropdownComponent,
    StudentLoginReportComponent,
    IndividualStudentReportsComponent,
    FsaFinalResultsTableComponent,
    FsaSampleAssessmentLoginReportComponent,
    StudentAssessmentStatusComponent,
    ExpansionPanelComponent
  ]
})
export class UiPartialModule { }
