import { Component, OnInit, Input, ElementRef, ViewChild,  Renderer2 } from '@angular/core';
import { IContentElementCdkeditor, QuestionState } from '../models';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { DomSanitizer } from '@angular/platform-browser';
import { BreadcrumbsService } from '../../core/breadcrumbs.service';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
// import * as CkEditor from 'ckeditor5-build-classic-balloon/ckeditor';
import * as Rx from 'rxjs';
import { Title } from '@angular/platform-browser';

export enum BlockTypes {
    TEXT = 'TEXT',
    MATH = 'MATH',
    TABLE = 'TABLE',
    DIAGRAM = 'DIAGRAM',
  }

@Component({
    selector: 'element-render-cdkeditor',
    templateUrl: './element-render-cdkeditor.component.html',
    styleUrls: ['./element-render-cdkeditor.component.scss']
})

export class ElementRenderCdkeditorComponent implements OnInit {

    @Input() element:IContentElementCdkeditor;
    @Input() isLocked:boolean;
    @Input() questionState:QuestionState;

    @ViewChild('constrRespInput') constrRespInputEl:ElementRef;
    @ViewChild("popup") popup: ElementRef;
    @ViewChild("page") page: ElementRef;
    @ViewChild("text") textBlock: ElementRef;
    @ViewChild("table") tableBlock: ElementRef;
    @ViewChild("diagram") diagramBlock: ElementRef;
    @ViewChild("screen") screen: ElementRef;
    @ViewChild("canvas") canvas: ElementRef;

    protected paramsSubscription: Rx.Subscription;
    public Editor;
    public breadcrumb = [];
    variant:string;
    review:boolean = false;
    undoContent:any;
    blocks = [];
    fauxBlocks = [];
    reviewBlocks = [];
    BlockTypes = BlockTypes;
    mode='';
    availableBlocks = []
    isToggled = false;
    restrictMove: boolean = false
    ckEditorConfig;
    readingSelectionWidth:number;
    removeStack:any[] = [];
    imgCanvas

    constructor(
        private renderer2: Renderer2, 
        protected route: ActivatedRoute,
        private titleService: Title,
        public sanitizer: DomSanitizer,
    ) {}

    ngOnInit() {
        // console.log('availableBlocks', this.availableBlocks)
        this.readingSelectionWidth = Math.min(window.innerWidth/2, 600);
        this.titleService.setTitle( 'Numeracy Constructed Response Tool (preview)' );
        this.getParamsSub()
        this.setAvailableBlocks()
        console.log(window.innerWidth)
        this.setWidth();
    }

    getParamsSub(){
        if (this.paramsSubscription){
          this.paramsSubscription.unsubscribe()
        }
        this.paramsSubscription = this.route.paramMap.subscribe(async paramMap => {
          this.variant = paramMap.get('variant');
        });
      }
     
      setWidth(){
       if (window.innerWidth <= 770){
         this.readingSelectionWidth = 400;
       }
      } 
    
      setAvailableBlocks(){
        if(this.variant === 's1b'){
          // this.Editor = CkEditor.BalloonEditor;
        }
        else{
          // this.Editor = CkEditor.ClassicEditor
        }
    
        let toolbar = [ 'SpecialCharacters','|' ,'Highlight','|' ,'Bold', 'Italic', 'Underline', 'Subscript', 'Superscript', '|'  ,'FontSize', '|' ,'Undo' ,'Redo' ] ;
        this.ckEditorConfig = {
          toolbar: toolbar,
          placeholder: 'Use the textbox to type out your response.',
        };
        
        this.availableBlocks = [];
        this.availableBlocks.push({id: BlockTypes.TEXT, caption:'Add Text', icon:'fa fa-font'});
        if(this.variant !== 's1c'){
          this.availableBlocks.push ({id: BlockTypes.TABLE, caption:'Add a Table', icon:'fa fa-table'});
        }
        this.availableBlocks.push({id: BlockTypes.DIAGRAM, caption:'Add a Diagram/Graph', icon:'fas fa-pencil-alt'});
      }
      showPreview(){
        alert('This function is not available in this tool sample')
      }
      showNext(){
        alert('This function is not available in this tool sample')
      }
      toggleReview(){
        if(this.review){
          this.review = false;
          this.mode = '';
          $(".ck-editor__top").css( "display", "block" );
          $(".ckdiv").css( "pointer-events", "all" );
        }
        else{
          this.review = true;
          this.mode = 'review'
          document.getElementById("split-area").scrollTop = 0;
          $(".ck-editor__top").css( "display", "none" );
          $(".ckdiv").css( "pointer-events", "none" );
         // pointer-events:none;
        }
      }
    
    
      removeElement(arr, i){
       
        if (confirm('Are you sure you want to remove this element?')){
          // arr.splice(i, 1)
          document.getElementById("block"+i).style.display = "none";
          this.removeStack.push(this.mapBlockElement(arr,i))
        } 
       
    
      }
      undo(){
        let block = this.removeStack.pop()
        document.getElementById("block"+block.index).style.display = "flex"
      }
      mapBlockElement(arr,i){
        let blockElement = {}
        switch(arr[i].blockType) {
          case 'TEXT':
            blockElement = {
              index:i,
            }
            break;
          case 'TABLE':
            blockElement = {
              index:i
            }
            break;
          case 'DIAGRAM':
            blockElement = {
              index:i,
            }
            break;  
          default:
             null;
          }
      return blockElement;
      }
    
      public onReady( editor ) {
        editor.editing.view.focus();
        editor.plugins.get( 'SpecialCharacters' )._characters.clear()
        editor.plugins.get( 'SpecialCharacters' )._groups.clear()
        // console.log(editor.plugins.get( 'SpecialCharacters' ))
        editor.plugins.get( 'SpecialCharacters' ).addItems( 'Common', [
          { title: 'pi', character: 'π' },
          { title: 'division', character: '÷'},
          { title: 'degrees', character: '°' },
          { title: 'square', character: '²' },
          { title: 'cube', character: '³' },
          { title: 'equivalent', character: '≈' },
          { title: 'unequal', character: '≠' },
          { title: 'greaterOrEqual', character: '≥' },
          { title: 'lessOrEqual', character: '≤' }
        ] );
        //editor.plugins.get( 'SpecialCharacters' ).addItems('Mathematics');
        // get focus
      }
      
      bufferedBlock:BlockTypes
      insertBlock(blockType:BlockTypes){
       
        const block = {
          blockType, 
          isInFocus: true,
          rows:[
            {cells:[{},{},{},{},{}]},
            {cells:[{},{},{},{},{}]},
            {cells:[{},{},{},{},{}]},
            {cells:[{},{},{},{},{}]},
            {cells:[{},{},{},{},{}]},
          ]
        };
          this.blocks.push(block);
        this.bufferedBlock = blockType;
        setTimeout(()=>{
          if (this.bufferedBlock === blockType){
            this.bufferedBlock = null;
          }
        }, 1000)
        this.scrollToBottom();
      }
    
      isBlockInsertBuffered(blockType:BlockTypes){
        return (this.bufferedBlock === blockType);
      }
      
      removeColumn(block, col_i){
        block.rows.forEach(row => {
          row.cells.splice(col_i, 1)
        })
      }
      removeRow(block, row_i){
        block.rows.splice(row_i, 1)
      }
      insertColumn(block){
        block.rows.forEach(row => {
          row.cells.push({})
        })
      }
      insertRow(block){
        const cells = [];
        block.rows[0].cells.forEach(cell=> {
          cells.push({});
        })
        // console.log('insert row', cells)
        block.rows.push({cells})
      }
      
      drop(arr: any, event: CdkDragDrop<string[]>) {
        moveItemInArray(arr, event.previousIndex, event.currentIndex);
      }
      
      scrollToBottom(){
        setTimeout(()=> {
          const el = this.constrRespInputEl.nativeElement;
          el.scrollIntoView({behavior:'smooth'});
          // el.scrollTop = el.scrollHeight;  
          // console.log('scroll', el)
        }, 200)
      }
      
      log(x) {
        console.log('dragEnd ', x.sizes, ' total > ', x.sizes.reduce((t, s) => t+s, 0))
      }
     
      setModalFocus(){
        const  focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.querySelector('#modal1'); // select the modal by it's id
    
    let firstFocusableElement:any = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    let focusableContent:any = modal.querySelectorAll(focusableElements);
    let lastFocusableElement:any = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal
    
    
    document.addEventListener('keydown', function(e) {
      let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
    
      if (!isTabPressed) {
        return;
      }
    
      if (e.shiftKey) { // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus(); // add focus for the last focusable element
          e.preventDefault();
        }
      } else { // if tab key is pressed
        if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
          firstFocusableElement.focus(); // add focus for the first focusable element
          e.preventDefault();
        }
      }
    });
    
    firstFocusableElement.focus();
    }
    

}