<ng-container class="score-entry-individual">
    <div class="scoring-panel" >
        <div class="panel-title">Literacy</div>

        <div class="buttons has-addons">
            <span class="question-title">Theme Selection</span>

            <div class="field has-addons" *ngFor="let themeOption of selectedScoreEntryRowV2.literacy.themeOptions">
                <button 
                    *ngIf="selectedScoreEntryRowV2.literacy.selectedTheme == themeOption"
                    class="button is-select is-info"
                    [disabled]="isLoading"
                >
                    <ng-container >Theme {{themeOption}}</ng-container>
                </button>

                <button 
                    *ngIf="selectedScoreEntryRowV2.literacy.selectedTheme != themeOption"
                    class="button"
                    [disabled]="isLoading"
                >
                    <ng-container >Theme {{themeOption}}</ng-container>
                </button>
            </div>

            <button 
                class="button has-addons clear-section" 
                [disabled]="isLoading"
            >
                <tra slug="sa_se_clear_selection"></tra>
            </button>
        </div>

        <!-- <div class="buttons has-addons" *ngFor="let questionOption of selectedScoreEntryRowV2.literacy.questionOptions">
            <span class="question-title">Question {{ questionOption }}</span>

            <div class="field has-addons" *ngFor="let scoreOption of scoreOptions">
                <button 
                    *ngIf="question.response == scoreOption"
                    class="button is-select is-info"
                    [disabled]="isLoading"
                >
                    {{ scoreOption }}
                </button>

                <button 
                    *ngIf="question.response != scoreOption"
                    class="button"
                    [disabled]="isLoading"
                >
                    {{ scoreOption }}
                </button>
            </div>

            <button 
                class="button has-addons clear-section" 
                [disabled]="isLoading"
            >
                <tra slug="sa_se_clear_selection"></tra>
            </button>
        </div> -->
    </div>

    <div class="scoring-panel" >
        <div class="panel-title">Numeracy</div>
    </div>
    <!-- <score-entry-documents *ngIf="currentRow" [grade]="currentRow.grade" [large]="documentsLargeText">
    </score-entry-documents>

    

    <ng-container *ngIf="currentRow">

        <div class="student-info">
            <b>{{currentRow.last_name}}, {{currentRow.first_name}} ({{currentRow.pen}}) - <tra slug="sa_grade"></tra>:
                {{currentRow.grade}}</b>
            <span class="score-entry-status tag" [style.background-color]="getColorForStatus(currentRow)">
                {{getStatus(currentRow)}}
            </span>
        </div>
    </ng-container>

    <div class="action-bar" *ngIf="allPens && pagination">
        <bc-paginator [paginator]="pagination" [showCount]="paginatorShowCount" [getCountDisplay]="getCountDisplay"
            [disabled]="isLoading" (refresh)="onPaginationChange()" (willRefresh)="onPaginationWillChange($event)">
        </bc-paginator>

        <div style="flex-grow: 1;"></div>

        <button class="button not-scored-button" (click)="markAsNotYetScoredClicked()"
            [disabled]="shouldDisableMarkAsNotYetScoredButton()">
            <tra *ngIf="currentRow && !currentRow.is_marked_not_yet_scored" slug="sa_se_notYetScored"></tra>
            <tra *ngIf="currentRow && currentRow.is_marked_not_yet_scored" slug="sa_se_mark_submitted"></tra>
        </button>

        <button class="button save-button" (click)="save()" [disabled]="isLoading">
            <tra slug="sa_aa_save"></tra>
        </button>
    </div>

    <ng-container *ngIf="themeProfile && scoreProfile && currentRow">

        <div class="scoring-panel" *ngFor="let panel of scoringPanels">
            <div class="panel-title">{{panel.component}}</div>

            <div class="buttons has-addons" *ngFor="let question of panel.questions">
                <span class="question-title">{{question.title}}</span>

                <div class="field has-addons" *ngFor="let option of question.profile.options">
                    <button *ngIf="currentRow[question.scoreEntryField] == option.slug" class="button is-select is-info"
                        [disabled]="isLoading">
                        <ng-container *ngIf="question.getDisplay">{{question.getDisplay(option.slug)}}</ng-container>
                        <ng-container *ngIf="!question.getDisplay">{{option.slug}}</ng-container>
                    </button>

                    <button *ngIf="currentRow[question.scoreEntryField] != option.slug" class="button"
                        [disabled]="isLoading || (question.disableOption && question.disableOption(option.slug))"
                        (click)="question.onChange(question.scoreEntryField, option.slug)">
                        <ng-container *ngIf="question.getDisplay">{{question.getDisplay(option.slug)}}</ng-container>
                        <ng-container *ngIf="!question.getDisplay">{{option.slug}}</ng-container>
                    </button>
                </div>

                <button class="button has-addons clear-section" (click)="question.onChange(question.scoreEntryField)"
                    [disabled]="isLoading">
                    <tra slug="sa_se_clear_selection"></tra>
                </button>
            </div>
        </div>

    </ng-container> -->


</ng-container>