import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IQuestionConfig, elementTypes, QuestionState, ElementType, IContentElement, IContentElementInput, IContentElementCanvas, IContentElementFrame } from '../models/index';
import { FormControl } from '@angular/forms';
import { elementIconById } from '../../ui-item-maker/item-set-editor/models';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UrlLoaderService } from '../url-loader.service';
import { TextToSpeechService } from '../text-to-speech.service';
import { AuthScopeSetting, AuthScopeSettingsService } from '../../ui-item-maker/auth-scope-settings.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { Subscription, Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { DrawingLogService } from '../drawing-log.service';
import { DisplayMode } from '../models';
import { RenderModeService } from '../render-mode.service';
import { ActivatedRoute } from '@angular/router';
import { QuestionRunnerLayoutService } from '../question-runner-layout.service';
import { ignoreElements } from 'rxjs/operators';

@Component({
  selector: 'question-runner',
  templateUrl: './question-runner.component.html',
  styleUrls: ['./question-runner.component.scss']
})
export class QuestionRunnerComponent implements OnInit, OnChanges {

  @Input() currentQuestion:IQuestionConfig;
  @Input() currentQuestionIndex:number = 0;
  @Input() totalQuestions:number = 4;
  @Input() questionState:any;
  @Input() isSubmitted:boolean;
  @Input() containingWidth:number = 40;
  @Input() containingWidthSpill:number;
  @Input() allowSubtitles: boolean = false;
  @Input() allowTranscripts: boolean = false;
  @Input() allowAudioPlaybackSpeed: boolean = false;
  @Input() allowVideoPlaybackSpeed: boolean = false;
  @Input() isPrintMode: boolean = false;
  @Input() isAuthoringMode: boolean = false;
  @Input() isEntranceAnimDisabled: boolean = false;
  @Input() customAudioState: boolean;
  @Input() isFSA?: boolean;
  @Output() manualSave = new EventEmitter();

  questionTextSize = new FormControl(1);
  // isAudioPlayerShowing:boolean;
  questionAudioUrl:SafeResourceUrl;
  questionPubSub:QuestionPubSub;
  DisplayMode = DisplayMode; 

  constructor(
    private urlLoader: UrlLoaderService,
    private textToSpeech:TextToSpeechService,
    private authScope: AuthScopeSettingsService,
    private whitelabel: WhitelabelService,
    private bufferedLog: DrawingLogService,
    private renderMode: RenderModeService,
    private route: ActivatedRoute,
    private questionRunnerLayout: QuestionRunnerLayoutService
  ) { 
  }

  
  ngOnInit() {
    if (this.isAuthoringMode) {
      this.renderMode.turnAuthoringOn()
    } else {
      this.renderMode.turnAuthoringOff()
    }
    if (this.currentQuestion){
      if (!this.currentQuestion.enableManualSaveButton){
        this.currentQuestion.enableManualSaveButton = false;
      }
      if (!this.currentQuestion.manualSaveButtonTopMargin){
        this.currentQuestion.manualSaveButtonTopMargin = 0;
      }
    }
    this.reset();
    this.questionRunnerLayout.reset()
  }
  
  public reset(){
    this.questionPubSub = new QuestionPubSub(this.isPrintMode, this.customAudioState);
  }

  ngOnChanges(changes:SimpleChanges){
    if (changes.currentQuestion){
      this.bufferedLog.bufferedLog('QUESTION_RESET_PRE', {id: (this.currentQuestion || {}).id, questionState: this.questionState });
      this.reset();
      this.bufferedLog.bufferedLog('QUESTION_RESET_POST', {id:(this.currentQuestion || {}).id, questionState: this.questionState });
    }
    if(changes.isPrintMode) {
      this.questionPubSub.isPrintMode = this.isPrintMode;
    }
    if(changes.customAudioState) {
      this.reset();
    }
  }

  getZIndex(contentElement:IContentElement) {
    return this.questionRunnerLayout.id2ZIndex.get(contentElement.entryId)
  }

  isFadeInEntrance(){
    return (!this.isEntranceAnimDisabled && this.authScope.getSetting(AuthScopeSetting.Q_FADE_IN_ENTRANCE));
  }

  isAudioPlayerShowing(){
    if(this.customAudioState !== undefined) {
      return this.customAudioState
    }
    return this.textToSpeech.isActive;
  }

  getQuestionAudioUrl(){
    return this.currentQuestion?.voiceover?.url;
  }

  isInputLocked(){
    return this.isSubmitted;
  }

  ghostAdded = false;
  getPixelsPerEM() {
    const el = document.getElementById("ghost");
    if (!el) return 0;
    if (!this.ghostAdded) {
      document.body.appendChild(el);
      this.ghostAdded = true;
    }
    const width = el.offsetWidth;
    const len = width/10;
    //document.body.removeChild(el);
    return len;
  }

  showElement(contentElement:IContentElement) {
    if (!this.isPrintMode || contentElement.elementType == ElementType.RESULTS_PRINT) return true;
  }

  getScaleToFitStyle(element) {
    return{};
    /*const widthConstrainedElement = this.checkForElementWidthConstraint(element);
    if (!widthConstrainedElement && window.innerWidth<1000) {
      return true;
    }*/
    let style:any = {};
    const scalingBuffer = 0.95;
    if (element.elementType == ElementType.CANVAS) {
      const pixelsPerEm = this.getPixelsPerEM();
      if (pixelsPerEm==0) return style;
      let width = ((window.innerWidth-180)/pixelsPerEm)
      const box = document.getElementById("readingPassageSplit")
      const questionRunnerInReadingPassage = document.getElementById("questionRunnerInReadingPassage")
      if (questionRunnerInReadingPassage) {
        width = box.offsetWidth/pixelsPerEm;
      }
      if (element.width>Math.floor(width)) {
        style["font-size"] = Math.floor(width)/element.width*scalingBuffer+"em";
      }
      //console.log(style);
      //console.log(window.innerWidth)
    }
    style["line-height"]="1em"
    return style;
  }

  getIconByElementTypeId(elementTypeId:string){
    return elementIconById.get(elementTypeId);
  }

  checkForElementWidthConstraint(elementTypeId:ElementType){
    // deprecated
    if (!this.whitelabel.getSiteFlag('TEST_RUNNER_WIDTH_CONSTRAINT')) return false;

    switch (elementTypeId){ 
      case ElementType.SELECT_TABLE:
      case ElementType.ORDER:
      case ElementType.CANVAS:
      case ElementType.TABLE:
        return false;
      default: 
        return true;
    }
  }

  isHighContrastException(element:IContentElement) {
    if (element.elementType==ElementType.CANVAS) {
      return true;
    }
    return false;
  }


  computeAnimDelayAndDuration(index:number){
    let animDelayIncr = 250;
    let animDuration = 600;
    let animDelay = 0;
    for (let i=1; i<index; i++){
      animDelay += animDelayIncr;
      animDelayIncr *= 0.8;
      animDuration *= 0.8;
    }
    return {
      animDelay,
      animDuration,
    }
  }

  private computeFontScaleByBaseWidth(baseWidth:number){
    let fontScale = 1
    if (baseWidth && this.containingWidth < baseWidth){
      fontScale = this.containingWidth / baseWidth;
    }
    return fontScale;
  }

  getFontScale(element:IContentElement){
    let fontScale = 1;
    if (this.containingWidth){
      if (element.elementType === ElementType.CANVAS){
        const elementCanvas = <IContentElementCanvas> element;
        fontScale = this.computeFontScaleByBaseWidth(elementCanvas.width);
      }
      if (element.elementType === ElementType.FRAME){
        const elementFrame = <IContentElementFrame> element;
        fontScale = this.computeFontScaleByBaseWidth(elementFrame.width);
      }
    }
    return fontScale;
  }

  getElementStyle(element:IContentElement, index:number){
    // could be optimized
    const fontScale = this.getFontScale(element);
    const {animDelay, animDuration,} = this.computeAnimDelayAndDuration(index);
  
    const style = {
      'font-size': fontScale+'em',
      'animation-delay': animDelay+'ms',
      'animation-duration': animDuration+'ms',
      // 'z-index': (this.currentQuestion.content.length-index)*10,
      // 'position': 'relative'
    }
    if (this.getZIndex(element)!=undefined) {
      style['position']='relative'
      style['z-index']=this.getZIndex(element)
    }
    return style
  }

  isElementVisible(contentElement) {
    return true;
    //return contentElement.elementType === ElementType.RESULTS_PRINT || !this.isPrintMode;
  }

}
