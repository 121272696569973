<div class="runner-container" >
  <div *ngIf="getQuestionAudioUrl() && isAudioPlayerShowing()" class="dont-print is-not-high-contrast-exception">
    <render-audio
      [url]="getQuestionAudioUrl()"
      [mode]="DisplayMode.NORMAL"
      [hasControls]="true"
    ></render-audio>
  </div>
  <div id="ghost" style="visibility: hidden; width: 10em; height:0em"></div>
  <div class="question-container" [class.is-print-mode]="isPrintMode">
    <div class="question-panels" [class.is-print-mode]="isPrintMode">
      <div 
        class="question-panel" 
        [class.is-print-mode]="isPrintMode"
        [ngStyle]="{'font-size.em':questionTextSize.value}"  
        *ngIf="currentQuestion && currentQuestion.content"
      >
        <div 
          *ngFor="let contentElement of currentQuestion.content; let elementIndex = index;" 
          class="content-element"
          [class.is-not-high-contrast-exception]="!isHighContrastException(contentElement)"
          [class.fade-in]="isFadeInEntrance()"
          [ngStyle]="getElementStyle(contentElement, elementIndex)"
          [class.element-width-constrainer]="checkForElementWidthConstraint(contentElement.elementType)"
          [class.is-element-hidden]="!isElementVisible(contentElement)"
        >
          <!-- <div [ngStyle]="getScaleToFitStyle(contentElement)"> -->
          <element-render 
            *ngIf="showElement(contentElement)"
            [contentElement]="contentElement"
            [isLocked]="isInputLocked()"  
            [questionState]="questionState"
            [questionPubSub]="questionPubSub"
            [allowSubtitles]="allowSubtitles"
            [allowTranscripts]="allowTranscripts"
            [allowAudioPlaybackSpeed]="allowAudioPlaybackSpeed"
            [allowVideoPlaybackSpeed]="allowVideoPlaybackSpeed"
            [isFSA]="isFSA"
          ></element-render>
          <!-- </div> -->
        </div>
      </div>
   </div>
  </div>
  <!-- <button (click)="prevQuestion.emit(questionState)" [disabled]="isFirstQuestion" class="button">Back</button> -->
  <!-- <button (click)="nextQuestion.emit(questionState)" class="button is-success">Continue</button> -->
  <button *ngIf="currentQuestion && currentQuestion.enableManualSaveButton" (click)="manualSave.emit()" class="button is-small is-info has-icon is-outlined maunal-save-button" [style.margin-top.em]="currentQuestion.manualSaveButtonTopMargin">
    <tra slug="question_runner_manual_save"></tra>
  </button>
</div>
