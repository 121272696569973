import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IContentElementSelectionTable, IEntryStateSelectionTable, QuestionState, getElementWeight, ScoringTypes, ElementType } from '../models';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { TextToSpeechService } from '../../ui-testrunner/text-to-speech.service';

// export interface 


@Component({
  selector: 'element-render-selection-table',
  templateUrl: './element-render-selection-table.component.html',
  styleUrls: ['./element-render-selection-table.component.scss']
})
export class ElementRenderSelectionTableComponent implements OnInit {
  
  @Input() element:IContentElementSelectionTable;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter: number;
  @Input() questionPubSub?: QuestionPubSub;

  isStarted:boolean;
  testTakerAnswers:{value: boolean}[][] = [];
  lastQuestionState:IEntryStateSelectionTable;

  constructor(private textToSpeech:TextToSpeechService) { }
  hoverTriggers = new Map()

  ngOnInit(): void {
    this.resetChecks();
    if (this.questionState && this.questionState[this.element.entryId]) {
      this.handleNewState();
    } else {
      this.ensureState();
    }
    if (!this.element.overflow){
      this.element.overflow = 'auto';
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes.changeCounter) {
      this.resetChecks();
    }
    if (this.lastQuestionState !== <any> this.questionState) {
      this.lastQuestionState = <any> this.questionState;
      if (this.questionState[this.element.entryId]) {
        this.handleNewState();
      } 
      else {
        this.ensureState();
      }
    }
    if (this.isLocked){
      this.lockAnswer();
    }
  }
  getToprowValue(){
    if(!this.element.topRow || this.element.topRow.length === 0) {
      return false; 
    }
    
    if(this.element.topRow[0].content.elementType !== "text"){
      return true
    } else return ( this.element.topRow[0].content.elementType == "text" && (this.element.topRow[0].content.caption !== '') ) || ( this.element.topLeftText.caption !== '' );
  }

  lockAnswer() {
    if (this.questionState) {
      const quest = this.questionState[this.element.entryId];
      this.testTakerAnswers == quest.checkMarks;
    }
  }

  isAudio(cell) {
    return cell.content.elementType == ElementType.AUDIO;
  }

  resetChecks() {
    
    this.testTakerAnswers = [];

    if (this.element.leftCol && this.element.topRow) {
      for (let i = 0;i<this.element.leftCol.length;i++) {
        if (this.testTakerAnswers.length<this.element.leftCol.length) this.testTakerAnswers.push([]);
        for (let j = 0;j<this.element.topRow.length;j++) {
          if (this.testTakerAnswers[i].length<this.element.topRow.length) this.testTakerAnswers[i].push({value: false});
        }
      }
    }
    
    if (this.testTakerAnswers.length==0) {
      this.testTakerAnswers.push([{value: false}]);
    }
  }

  handleNewState() {
    if (this.questionState) {
      //console.log("handle new state");
      //console.log(this.questionState);
      const entryState:IEntryStateSelectionTable = this.questionState[this.element.entryId];
      if (entryState) {
        this.isStarted = entryState.isStarted;
        this.testTakerAnswers = [];
        entryState.checkMarks.forEach((row, r) => {
          this.testTakerAnswers.push([]);
          row.forEach((col, c) => {
            if (col["value"]) this.testTakerAnswers[r].push({value: true});
            else this.testTakerAnswers[r].push({value: false});
          })
        })
      }
      //console.log(this.testTakerAnswers);
    }
  }

  ensureState() {
    if (this.questionState){
      const entryId = this.element.entryId;
      const weight = getElementWeight(this.element);
      let score = 0;
      if (!this.questionState[entryId]){
        const newCheck = [];
        this.testTakerAnswers = []
        if (this.element.leftCol) {
          this.element.leftCol.forEach((row, r)=>{
            newCheck.push([]);
            this.testTakerAnswers.push([]);
            this.element.topRow.forEach((col, c)=>{
              newCheck[r].push({value:false});
              this.testTakerAnswers[r].push({value: false});
            })
          })
        }
        // if (this.testTakerAnswers.length==0) {
        //   this.testTakerAnswers.push([])
        // }
        // const isCorrect = (+score === +weight);
        // if (!isCorrect && !this.element.enableProportionalScoring){
        //   score = 0;
        // }
        let entryState:IEntryStateSelectionTable = {
          type: 'select_table',
          isStarted: false,
          isFilled: false,
          isResponded: false,
          isCustomGrading: false,
          isCorrect: false,
          checkMarks: newCheck, 
          score,
          weight,
          scoring_type: ScoringTypes.AUTO,
        }
        this.questionState[entryId] = entryState;
      }
    }
  }

  updateState() {
    //console.log(this.questionState);
    if (this.questionState) {
      const entryId = this.element.entryId;
      const checkMarks = this.captureCheckState();
      const result = this.getNewChecks();
      // const isResponded = this.questionState[entryId].isResponded || result.isFilled;
      let entryState:IEntryStateSelectionTable = {
        type: ElementType.SELECT_TABLE,
        isStarted: result.isStarted,
        isCorrect: result.isCorrect,
        isFilled: result.isFilled,
        isResponded: result.isResponded,
        score: result.score,
        isCustomGrading: false,
        checkMarks,
        weight: getElementWeight(this.element),
        scoring_type: ScoringTypes.AUTO,
      }
      this.questionState[entryId] = entryState;
    }
  }

  captureCheckState(){
    const capturedGridState:{value: boolean}[][] = [];
    const colIndex = new Map();
    this.testTakerAnswers.forEach((row, rowIndex)=>{
      const capturedRowState = [];
      capturedGridState.push(capturedRowState);
      row.forEach((cell, colIndex)=>{
        capturedRowState.push({
          value: !! cell.value
        })
      })
    })
    return capturedGridState
  }

  isRowSelectable(cell) {
    if (!this.element.isClickabilityConditional || !this.isAudio(cell)) return true;
    const state = this.questionState[cell.audio.entryId]
    return state.isFilled;
  }

  getNewChecks() {
    const isPropScore = this.element.enableProportionalScoring;
    const weight = getElementWeight(this.element);
    
    const isOneSelPerRow = (+this.element.maxCheckedPerRow === 1);
    const isOneSelPerCol = (+this.element.maxCheckedPerCol === 1);
    const isRowsAsEntries = isOneSelPerRow && !isOneSelPerCol;
    const isColsAsEntries = !isOneSelPerRow && isOneSelPerCol;
    const checkBoxGridDef = this.element.checkBoxRows;
    const firstRowDef = checkBoxGridDef[0];
    const numRows = checkBoxGridDef.length;
    const numCols = firstRowDef.length;

    let onePerRow = true;
    let onePerCol = true;
    let isStarted = false;
    let isFilled = false;
    let isCorrect = false;
    
    let score = 0;
    const numChecks:number = numRows * numCols;

    // console.log('isRowsAsEntries', isRowsAsEntries, isColsAsEntries)
    const gridSelections = this.captureCheckState();
    let totalRowsChecked = 0;
    if (isRowsAsEntries){
      const gridAnswerKey = this.element.checkBoxRows;
      let isAnyRowFilled = false;
      let isAllRowFilled = true;
      let numCorrect = 0;
      // const numRows = gridAnswerKey.length;
      if (numRows > 0){
        for (let iRow=0; iRow<numRows; iRow++){
          let isRowFilled = false;
          let isRowCorrect = true;
          gridAnswerKey[iRow].forEach((cellKey, iCol) => {
            const cellSelection = gridSelections[iRow][iCol];
            if (cellSelection.value){
              isRowFilled = true;
              totalRowsChecked++;
            }
            if (!!cellKey.value === !cellSelection.value){
              isRowCorrect = false;
            }
          });
          if (isRowCorrect){
            numCorrect ++;
          }
          if (isRowFilled){
            isAnyRowFilled = true;
          }
          else{
            isAllRowFilled = false;
          }
        }
        isStarted = isAnyRowFilled;
        isFilled = isAllRowFilled;
        isCorrect = (numCorrect === numRows);
        // if(!isResponded) isResponded =  isAnyRowFilled || isAllRowFilled;
        score = weight * numCorrect/numRows;
        if (!isCorrect && !this.element.enableProportionalScoring){
          score = 0;
        }
      }
    }
    else {
      ///////////// NOT VALIDATED, NOT SAFE TO USE
      score=weight;
      console.warn('Scoring for this configuration of the block has not yet been validated')
      const newChecks:{value: boolean}[][] = [];
      const colIndex = new Map();
      let isAllColFilled:boolean = true;
      this.testTakerAnswers.forEach((row, r)=>{
        newChecks.push([]);
        let rowChecked = false;
        row.forEach((col, c)=>{
          if (this.testTakerAnswers[r][c].value) {
            newChecks[r].push({value: true});
            rowChecked = true;
            isStarted = true;
            colIndex.set(c, true);
            if (!this.element.checkBoxRows[r][c].value) {
              if (isPropScore) score -= weight/numChecks;
              else score = 0;
            }
          } else {
            newChecks[r].push({value: false});
            if (this.element.checkBoxRows[r][c].value) {
              if (isPropScore) score -= weight/numChecks;
              else score = 0;
            }
          }
        })
        if (rowChecked==false) onePerRow = false;
      })
      const colLen = this.testTakerAnswers[0].length;
      for (let i = 0;i<colLen;i++) {
        if (!colIndex.has(i)) onePerCol = false;
      }
      let isColReqFulfilled = false;
      let isRowReqFulfilled = false;
      if (!this.element.isMustHaveOnePerCol || onePerCol) isColReqFulfilled = true;
      if (!this.element.isMustHaveOnePerRow || onePerRow) isRowReqFulfilled = true;
      if (isStarted && isColReqFulfilled && isRowReqFulfilled) isFilled = true;
      isCorrect = (+score === +weight);
      // if (!isCorrect && !this.element.enableProportionalScoring){
      //   score = 0;
      // }
      /////////
      
    }

    let numCellsFilled = 0;
    if(this.element.maxCheckedPerCol && this.element.maxCheckedPerCol > 1){
      let isAllColsFilled = false;
      for (let iCol=0; iCol<numCols; iCol++){        
        for (let iRow=0; iRow<numRows; iRow++){
          const cellSelection = gridSelections[iRow][iCol];
          if (cellSelection.value){
            numCellsFilled ++;
          }
        }
        if (numCellsFilled == this.element.maxCheckedPerCol){
          isAllColsFilled = true;
        }
        // console.log('isAllColsFilled', this.element.maxCheckedPerCol, numCellsFilled, isAllColsFilled)
      }
      console.log(numCellsFilled)
      isFilled = !!isAllColsFilled || !!numCellsFilled;
    }
    let isResponded = this.questionState[this.element.entryId].isResponded || !!numCellsFilled || !!totalRowsChecked;
    return { score, isStarted, isFilled, isCorrect, isResponded};
  }

  checkOrUnCheckAnswer(row:number, col:number) {
    if (this.isLocked){
      return;
    }
    if (!this.isRowSelectable(this.element.leftCol[row])) {
      return;
    }
    if (this.element.checkBoxRows[row][col].isSelectionDisabled) {
      return;
    }
    const prop = this.testTakerAnswers[row][col];
    if (prop.value){
      prop.value = false;
    }
    else {
      let update = true;
      if (this.element.maxCheckedPerCol && this.element.maxCheckedPerCol>0) {
        let count = 0;
        for (let i = 0;i<this.element.leftCol.length;i++) {
          if (this.testTakerAnswers[i][col].value) {
            count++;
          }
          if (count >= this.element.maxCheckedPerCol) {
            update = false;
            break;
          }
        }
      }

      if (update && +this.element.maxCheckedPerRow === 1){
        for (let i = 0;i<this.element.topRow.length;i++) {
          this.testTakerAnswers[row][i].value = false;
        }
      }
      else if (this.element.maxCheckedPerRow && this.element.maxCheckedPerRow>0 && update) {
        let count = 0;
        for (let i = 0;i<this.element.topRow.length;i++) {
          if (this.testTakerAnswers[row][i].value){
            count++;
          }
          if (count >= this.element.maxCheckedPerRow) {
            update = false;
            break;
          }
        }
      }

      if (update == true){
        prop.value = true;
      }
    }
    this.updateState();
  }

  getTableMaxWidth() {
    if (!this.element.isAnswerWidthSet || !this.element.isQuestionWidthSet) return "none";
    if (!this.element.answerColumnWidth || !this.element.questionColumnWidth) return "none";

    let width = this.element.questionColumnWidth+this.element.topRow.length*this.element.answerColumnWidth;
    return width+'em';
  }

  getTableStyle() {
    let style = {}
    var isEdge = /Edg\/(\d+)/.test(navigator.userAgent);
    if(isEdge) {
      // Issue with rendering the border-collapse property for EDGE
      style['border-collapse'] ='separate'
    }
    return style
  }

  getCellStyle(row:number, col:number) {
    const style = {}
    // if (this.element.isElementInSelectableCell) {
      if (!this.testTakerAnswers[row][col].value) {
        if (!this.element.checkBoxRows[row][col].defaultColor) {
          style["background-color"] = "#ffffff"
        } else {
          style["background-color"] = this.element.checkBoxRows[row][col].defaultColor;
        }
      } else {
        if (!this.element.checkBoxRows[row][col].selectedColor) {
          style["background-color"] = "#ffffff"
        } else {
          style["background-color"] = this.element.checkBoxRows[row][col].selectedColor;
        }
      }

      if (this.element.isCellPaddingSet) {
        if (this.element.topCellPadding != undefined) {
          style["padding-top"]=this.element.topCellPadding+"em"
        }
        if (this.element.rightCellPadding != undefined) {
          style["padding-right"]=this.element.rightCellPadding+"em"
        }
        if (this.element.bottomCellPadding != undefined) {
          style["padding-bottom"]=this.element.bottomCellPadding+"em"
        }
        if (this.element.leftCellPadding != undefined) {
          style["padding-left"]=this.element.leftCellPadding+"em"
        }
      }
    // }

    /*if (this.element.isAnswerHeightSet && this.element.answerColumnHeight) {
      style["max-height"]=this.element.answerColumnHeight+"em"
      style["max-height"]=this.element.answerColumnHeight+"em"
    } else if (this.element.isAnswerWidthSet && this.element.answerColumnWidth) {
      style["max-width"]=this.element.answerColumnWidth+"em"
    }*/
    // if (this.textToSpeech.isHiContrast) {
    //   style["filter"]="invert(1)"
    // }
    return style
  }

  isHiContrast() {
    return this.textToSpeech.isHiContrast
  }

  getHoverTrigger(obj) {
    let trigger = this.hoverTriggers.get(obj)
    if (!trigger) {
      trigger = new Subject();
      this.hoverTriggers.set(obj, trigger)
    }
    return trigger
  }

  hoverOver(obj) {
    const trigger = this.hoverTriggers.get(obj);
    if (trigger) {
      trigger.next(true);
    }
  }

  isVoiceOverEnabled(){
    return this.textToSpeech.isActive;
  }

}
