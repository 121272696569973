<div #table id="{{ 'tableBlock' + index }}">
    <input
    type="text"
    placeholder="Add a Title" 
    class="table-title"
    [disabled]="review ? true : null"
    [style.font-size.em]="1"/> 
    <div class="table-toolbar">
        <div class="table-tool-section">
            <button (click)="insertRow(block)" class='table-tool' title="Add Row" [disabled]="review ? true : null">
                <img class="table-row-icons" src="../../../assets/icons/add_row.svg"/>
            </button>
            <button (click)="removeRow(block)" class='table-tool' title="Delete Row" [disabled]="block.rows.length === 0 || review ? true : null">
                <img class="table-row-icons" src="../../../assets/icons/delete_row.svg"/>
            </button>
            <button (click)="insertColumn(block)" class='table-tool' title="Add Column" [disabled]="review ? true : null">
                <img class="table-col-icons" src="../../../assets/icons/add_col.svg"/>
            </button>
            <button (click)="removeColumn(block)" class='table-tool' title="Delete Column" [disabled]="block.rows.length === 0 || review ? true : null">
                <img class="table-col-icons" src="../../../assets/icons/delete_col.svg"/>
            </button>
        </div>
        <div class="table-tool-section">
            <button (click)="boldCell(block)" class='table-tool' title="Bold" [disabled]="block.rows.length === 0 || review ? true : null">
                <i class="fas fa-bold"></i>
            </button>
            <button (click)="italicCell(block)" class='table-tool' title="Italics" [disabled]="block.rows.length === 0 || review ? true : null">
                <i class="fas fa-italic"></i>
            </button>
            <button (click)="underlineCell(block)" class='table-tool' title="Underline" [disabled]="block.rows.length === 0 || review ? true : null">
                <i class="fas fa-underline"></i>
            </button>
        </div>
        <div class="table-tool-section">
            <button (click)="increaseCellFontSize(block)" class='table-tool' title="Increase Font Size" [disabled]="isCellFontSizeMax(block) || block.rows.length === 0 || review ? true: null">
                <i class="fas fa-font"></i>
            </button>
            <button (click)="decreaseCellFontSize(block)" class='table-tool' title="Decrease Font Size" [disabled]="isCellFontSizeMin(block) || block.rows.length === 0 || review ? true: null">
                <i class="fas fa-font fa-xs"></i>
            </button>
        </div>
        <div class="table-tool-section">
            <button class="table-tool" title="Undo" (click)="undoTable()" [disabled]="this.tableCommands.length === 0 || this.commandPointer === -1 ? true: null">
                <i class="fas fa-undo-alt"></i>
            </button>
            <button class="table-tool" title="Redo" (click)="redoTable()" [disabled]="this.tableCommands.length === 0 || this.commandPointer === this.tableCommands.length - 1 ? true: null">
                <i class="fas fa-redo-alt"></i>
            </button>
        </div>
        <div class="table-tool-section">
            <div class="dropdown border-dropdown-option">
                <button class='table-tool border-dropdown-option' (click)="toggleShowTableBorderOptions()" [disabled]="areBorderAndColorOptionDisabled(block)" [class.dropdown-active]="this.showBorderOptions" title="Border">
                    <img *ngIf="!showBorderOptions" class="table-row-icons border-dropdown-option" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/border-all-v2/1656101528715/border-all-v2.svg"/>
                    <img *ngIf="showBorderOptions" class="table-row-icons border-dropdown-option" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/border-all-select/1656101552571/border-all-select.svg"/>
                </button>
                <div id="tableBorderOptionDropdown" class="dropdown-content" [class.show]="showBorderOptions && this.activeTableBlock == block">
                    <button class='table-tool table-border-btn' [class.is-selected]="isBorderOptionActive(block, 'all')" (click)="setTableBorder_All(block)" title="All">
                        <img *ngIf="!isBorderOptionActive(block, 'all')" class="table-row-icons border-dropdown-option" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/border-all-v2/1656101528715/border-all-v2.svg"/>
                        <img *ngIf="isBorderOptionActive(block, 'all')" class="table-row-icons border-dropdown-option" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/border-all-select/1656101552571/border-all-select.svg"/>
                    </button>
                    <button class='table-tool table-border-btn' (click)="setTableBorder_None(block)" title="Clear">
                        <img class="table-row-icons border-dropdown-option" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/border-none-v2/1656101950783/border-none-v2.svg"/>
                    </button>
                    <button class='table-tool table-border-btn' [class.is-selected]="isBorderOptionActive(block, 'left')" (click)="setTableBorder_Left(block)" title="Left">
                        <img *ngIf="!isBorderOptionActive(block, 'left')" class="table-row-icons border-dropdown-option" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/border-left-v2/1656101977550/border-left-v2.svg"/>
                        <img *ngIf="isBorderOptionActive(block, 'left')" class="table-row-icons border-dropdown-option" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/border-left-select/1656101994737/border-left-select.svg"/>
                    </button>

                    <div class="dropdown" 
                        [ngStyle]="{
                            'border-left': 'solid', 
                            'border-color': 'darkgrey'
                        }">
                        <button class='table-tool border-dropdown-option border-color-dropdown-option' (click)="toggleShowBorderColorOptions()" [disabled]="areBorderAndColorOptionDisabled(block)" title="Border color"
                                [style.padding-top]="'2.5px'"
                                [style.padding-bottom]="'2.5px'">
                            <img class="table-row-icons border-dropdown-option" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/pencil-solid/1656102229942/pencil-solid.svg" [style.color]="'#ffffff'"/>
                            <div *ngIf="getActiveBorderColor(block)" class="border-color-strip" [style.background-color]="getActiveBorderColor(block)"></div>
                        </button>
                        <div id="tableBorderOptionDropdown" class="dropdown-content" [class.show-color-optitons]="showBorderColorOptions && activeTableBlock == block">
                            <div class="swatch border-color-dropdown-option" [class.is-active-color]="isColorActive(block, colour)" *ngFor="let colour of borderColours" [style.background-color] = "colour" (click)="setCellBorderColor(block, colour)">
                                <i *ngIf="isColorActive(block, colour)" class="fa fa-check" aria-hidden="true" [style.color]="'#4a4a4a'"></i>
                            </div>
                        </div>
                    </div>

                    <button class='table-tool table-border-btn' [class.is-selected]="isBorderOptionActive(block, 'right')" (click)="setTableBorder_Right(block)" title="Right">
                        <img *ngIf="!isBorderOptionActive(block, 'right')" class="table-row-icons border-dropdown-option" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/border-right-v2/1656102033940/border-right-v2.svg"/>
                        <img *ngIf="isBorderOptionActive(block, 'right')" class="table-row-icons border-dropdown-option" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/border-right-select/1656102051899/border-right-select.svg"/>
                    </button>
                    <button class='table-tool table-border-btn' [class.is-selected]="isBorderOptionActive(block, 'top')" (click)="setTableBorder_Top(block)" title="Top">
                        <img *ngIf="!isBorderOptionActive(block, 'top')" class="table-row-icons border-dropdown-option" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/border-top-v2/1656102072439/border-top-v2.svg"/>
                        <img *ngIf="isBorderOptionActive(block, 'top')" class="table-row-icons border-dropdown-option" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/border-top-select/1656102091037/border-top-select.svg"/>
                    </button>
                    <button class='table-tool table-border-btn' [class.is-selected]="isBorderOptionActive(block, 'bottom')" (click)="setTableBorder_Bottom(block)" title="Bottom">
                        <img *ngIf="!isBorderOptionActive(block, 'bottom')" class="table-row-icons border-dropdown-option" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/border-bottom-v2/1656102114740/border-bottom-v2.svg"/>
                        <img *ngIf="isBorderOptionActive(block, 'bottom')" class="table-row-icons border-dropdown-option" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/border-bottom-select/1656102131864/border-bottom-select.svg"/>
                    </button>
                </div>
            </div>
            <div class="dropdown">
                <button class='table-tool color-dropdown-option' (click)="toggleShowColorOptions()" [disabled]="areBorderAndColorOptionDisabled(block)" [class.dropdown-active]="this.showColorOptions">
                    <img *ngIf="!showColorOptions" class="table-row-icons color-dropdown-option" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/paintbucket/1656102174097/paintbucket.svg"/>
                    <img *ngIf="showColorOptions" class="table-row-icons color-dropdown-option" src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/paintbucket-select/1656102188729/paintbucket-select.svg"/>
                </button>
                <div id="tableBorderOptionDropdown" class="dropdown-content" [class.show-color-optitons]="showColorOptions && activeTableBlock == block">
                    <div class="swatch color-dropdown-option" [class.is-active-color]="isColorActive(block, colour)" *ngFor="let colour of colours" [style.background-color] = "colour" (click)="setCellColor(block, colour)">
                        <i *ngIf="isColorActive(block, colour)" class="fa fa-check" aria-hidden="true" [style.color]="'#4a4a4a'"></i>
                    </div>
                </div>
            </div>     
        </div>
    </div>
    <table class="table-input" id="table">
        <!-- <tr *ngIf="block.isEditingTable">
            <td></td>
            <td *ngFor="let cell of block.rows[0].cells; let col_i = index;" class="edit-cell">
                <button *ngIf="!review" class="button  is-small " (click)="removeColumn(block, col_i)">
                    <i class="fa fa-trash" aria-hidden="true" aria-label="DeleteBlock"></i>
                </button>
            </td>
        </tr> -->
        <tr 
        *ngFor="let row of block.rows; let row_i = index;">
            <!-- <td *ngIf="block.isEditingTable" class="edit-cell">
                <button *ngIf="!review" class="button is-small " (click)="removeRow(block, row_i)">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
            </td> -->
            <td *ngFor="let cell of row.cells; let col_i = index" 
            class="table-cell"
            [style.border-style]="getBorderStyle(block, row_i, col_i)"
            [style.border-color]="getBorderColor(block, row_i, col_i)"
            [style.border-width]="getBorderWidth(block, row_i, col_i)"
            [style.background-color]="getCellColour(block, row_i, col_i)"
            [class.is-selected]="">
                <textarea 
                class="table-cell-textarea"
                [ngStyle]="{
                    'font-weight': block.rows[row_i].cells[col_i].bold ? 'bold' : 'normal', 
                    'text-decoration': block.rows[row_i].cells[col_i].underline ? 'underline' : 'none',
                    'font-style': block.rows[row_i].cells[col_i].italic ? 'italic' : 'normal'
                }" 
                [style.font-size.em]="block.rows[row_i].cells[col_i].fontSize"
                [style.background-color]="'transparent'"
                (click)="setRowAndCol(row_i, col_i, block)"
                (input)="setText(block, row_i, col_i, $event)"
                [ngModel]="block.rows[row_i].cells[col_i].text ? block.rows[row_i].cells[col_i].text : ''" 
                cdkTextareaAutosize 
                [cdkTextareaAutosize]="true" 
                [readonly]="review"></textarea>
            </td>
        </tr>
        <tr>
    </table>
</div>