import { Component, OnInit, ElementRef ,Input, ChangeDetectorRef, Output, EventEmitter} from '@angular/core';
import { ZwibblerContext, ZwibblerClass } from "./zwibbler"
import { DrawDisplayMode } from './constants';
import { DrawingLogService } from '../drawing-log.service';
import { AuthService } from '../../api/auth.service';
import { AccountType } from '../../constants/account-types';
import { SectionDrawingCtx } from '../sample-questions/data/sections';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import * as $ from 'jquery';
import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { config } from 'rxjs';
import { BlockDrawingCtx } from 'src/app/ui-testtaker/view-tt-test-runner/view-tt-test-runner.component';
declare let Zwibbler: ZwibblerClass;

@Component({
  selector: 'element-render-drawing',
  templateUrl: './element-render-drawing.component.html',
  styleUrls: ['./element-render-drawing.component.scss']
})
export class ElementRenderDrawingComponent implements OnInit {

  constructor(private myElement: ElementRef,
    private changeRef: ChangeDetectorRef,
    private drawLog: DrawingLogService,
    private auth: AuthService) {

  }


  @Input() mode='';
  @Input() id;
  @Input() currId;
  @Input() parent;
  @Input() zoomLevel;
  @Input() isZoomIn;
  @Input() isZoomOut;
  @Input() activatedState:boolean = true;
  @Input() useEraser:boolean = false;
  @Input() useHighlighter:boolean = false;
  @Input() useLine:boolean = false;
  @Input() isFrameEnabled:boolean = true;
  @Input() isToolbarEnabled:boolean = false;
  @Input() drawingCtx: any;
  @Input() pageIndexTracker:Map<any,any> = new Map();
  @Input() section?:number;
  @Input() clearAllDrawings?: boolean;
  @Input() blockID?: number;
  @Output() resetClearAll? = new EventEmitter<boolean>();
  @Output() savedCtx = new EventEmitter<SectionDrawingCtx>();
  @Output() authToolDiagramUpdated = new EventEmitter<BlockDrawingCtx>();
  
  preview = false;
  minZoom = 0.5;
  maxZoom = 2;
  currZoom = 100;
  isInFocus = false;
  updateInit = false;
  config
  pngFile
  lineClick = true;
  highlightClick = false;
  pageIndexes:number[] = [];
  DrawDisplayMode = DrawDisplayMode;
  currentColour
  currFillStyle
  currLineStyle
  currStamp

  triangleStampPNGs = new Map([
    ['#000000', 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/BlackTriangle/1655225072554/BlackTriangle.png'],
    ['#000', 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/BlackTriangle/1655225072554/BlackTriangle.png'],
    ['#f7412d', 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/OrangeTriangle/1655225094345/OrangeTriangle.png'],
    ['#47b04b','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/GreenTriangle/1655225115449/GreenTriangle.png'],
    ['#1194f6','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/BlueTriangle/1655225127283/BlueTriangle.png'],
    ['#ffc200','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/GoldTriangle/1655225145853/GoldTriangle.png'],
    ['#9d1bb2','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/PurpleTriangle/1655225157603/PurpleTriangle.png'],
    ['#ec1561','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/PinkTriangle/1655225171026/PinkTriangle.png'],
    ['#7a5547','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/BrownTriangle/1655225183928/BrownTriangle.png'],
  ]);

  squareStampPNGs = new Map([
    ['#000000', 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/BlackSquare/1655224736401/BlackSquare.png'],
    ['#000', 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/BlackSquare/1655224736401/BlackSquare.png'],
    ['#f7412d', 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/OrangeSquare/1655224748374/OrangeSquare.png'],
    ['#47b04b','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/GreenSquare/1655224761626/GreenSquare.png'],
    ['#1194f6','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/BlueSquare/1655224775918/BlueSquare.png'],
    ['#ffc200','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/GoldSquare/1655224795676/GoldSquare.png'],
    ['#9d1bb2','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/PurpleSquare/1655224808572/PurpleSquare.png'],
    ['#ec1561','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/PinkSquare/1655224946765/PinkSquare.png'],
    ['#7a5547','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/BrownSquare/1655224827769/BrownSquare.png'],
  ]);

  starStampPNGs = new Map([
    ['#000000', 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/BlackStar/1655223946695/BlackStar.png'],
    ['#000', 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/BlackStar/1655223946695/BlackStar.png'],
    ['#f7412d', 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/OrangeStar/1655223965591/OrangeStar.png'],
    ['#47b04b','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/GreenStar/1655223983286/GreenStar.png'],
    ['#1194f6','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/BlueStar/1655223999561/BlueStar.png'],
    ['#ffc200','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/GoldStar/1655224011414/GoldStar.png'],
    ['#9d1bb2','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/PurpleStar/1655224022826/PurpleStar.png'],
    ['#ec1561','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/PinkStar/1655224039391/PinkStar.png'],
    ['#7a5547','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/BrownStar/1655224051722/BrownStar.png'],
  ]);

  circleStampPNGs = new Map([
    ['#000000', 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/BlackCircle/1655221539514/BlackCircle.png'],
    ['#000', 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/BlackCircle/1655221539514/BlackCircle.png'],
    ['#f7412d', 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/OrangeCircle/1655221645737/OrangeCircle.png'],
    ['#47b04b','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/GreenCircle/1655221624996/GreenCircle.png'],
    ['#1194f6','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/BlueCircle/1655221575853/BlueCircle.png'],
    ['#ffc200','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6526/authoring/GoldCircle/1606854572821/GoldCircle.png'],
    ['#9d1bb2','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/PurpleCircle/1655221674526/PurpleCircle.png'],
    ['#ec1561','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/PinkCircle/1655221661276/PinkCircle.png'],
    ['#7a5547','https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/1203032/authoring/BrownCircle/1655221598722/BrownCircle.png'],
  ]);

  private ctx: ZwibblerContext; // Zwibbler context
  ngOnDestroy() {
    if (this.ctx) {
      // console.log("DESTROY ZWIBBLER");
      const strSave = this.ctx.save();
      console.log("save before merge", JSON.parse(strSave.substr(strSave.indexOf('['))))
      if (this.savedCtx) {
        this.savedCtx.emit({ctx: strSave, section: this.section});
        this.authToolDiagramUpdated.emit({blockID: this.blockID, ctx: strSave});
      }
      this.ctx.destroy();
      this.ctx = null;
    }
  }

  ngOnInit() {
  }

  setPageToQuestion(){
    const id = this.currId;
    if(this.ctx){
      console.log("question id", id)
      if (!this.pageIndexTracker.has(id)){
        
        this.pageIndexTracker.set(id, this.pageIndexes.length);
        this.pageIndexes.push(id);
      }
      const pageIndex = this.pageIndexTracker.get(id);
      const initialPageCount = this.ctx.getPageCount();
      for(let i=initialPageCount; i<(pageIndex + 1); i++){
        this.ctx.addPage();
      }
      this.ctx.setCurrentPage(pageIndex);
       console.log('setCurrentPage', pageIndex)
    }
  }

  resetPageIndexes() {
    Array.from(this.pageIndexTracker.keys()).forEach(element => {
      this.pageIndexes.push(element)
    });
  }

  ngOnChanges() {
    this.changeRef.detectChanges();
    if(this.ctx){
      this.ctx.resize();
    }
    if(this.parent === DrawDisplayMode.TEST_RUNNER){
      console.log("on changes")
      this.resetPageIndexes();
      this.setPageToQuestion();
      if(this.ctx){
        if(this.useEraser){
          this.ctx.useBrushTool({
            lineWidth: 35,
            strokeStyle: 'erase',
            layer: 'my_eraser_layer'})
        }

        if(this.useHighlighter){
          this.ctx.useLineTool({
            lineWidth: 24, strokeStyle:'#FFFF00',opacity:'0.3',lockAspectRatio:false},{singleLine: true})
        }
        if(this.useLine){
          this.ctx.useLineTool({
            lineWidth: 3, strokeStyle:'#3298DC', lockAspectRatio:false},{singleLine: true})
        }
        if(this.clearAllDrawings){
          const nodes = this.ctx.getAllNodes()
          this.ctx.deleteNode(nodes)
          this.resetClearAll.emit(false)
        }
      }
    }
    else{
      if(this.mode === 'review'){
        this.ctx.usePanTool()
        this.ctx['mode'] = this.mode;
        this.preview = true;
        this.updateInit = true;
  
      }
      else{
        if (this.updateInit) {
          this.ctx['mode'] = this.mode;
          this.ctx.usePanTool();
        }
  
      }
      if (this.zoomLevel && this.ctx) {
        console.log("zoom ", this.zoomLevel)
        this.ctx.setZoom(this.zoomLevel)
      }
    }
    this.authToolDiagramUpdated.emit();
  }

  ngAfterViewInit() {
    Zwibbler = window['Zwibbler'];
    let zwibblerDiv = this.myElement.nativeElement.querySelector("[zwibbler]")!;
    // console.log("zwibblerdiv is ", zwibblerDiv);
    
    Zwibbler.controller("MyController", function (ctx) {
      //Colours available in the colour palette
      ctx.colours = [
          "#000",
          "#f7412d",
          "#47b04b",
          "#1194f6",
          "#ffc200",
          "#9d1bb2",
          "#ec1561",
          "#7a5547",
      ];
      ctx['isInFocus'] = false;
      ctx['mode'] = this.mode;

      //Start with black, default brush size of 4
      ctx.ctx.useFreehandTool(ctx.colours[0], 4);
    });

    if (('jQuery' in window) && !window['jQuery']) {
      delete window['jQuery'];
    }

    const ret = Zwibbler.attach(zwibblerDiv, {});
    if (this.drawingCtx) {
      // console.log("Loading", this.drawingCtx)
      ret.ctx.load(this.drawingCtx)
    }
    const ctx = ret.ctx;
    if(this.parent === DrawDisplayMode.TEST_RUNNER){
      ctx.setConfig("background", "clear");
      ctx.setConfig("showHints", false);
      ctx.setConfig("setFocus", false);
      ctx.setConfig("scrollbars",false);
      //ctx.focus(true);
      if(this.useHighlighter){
        ctx.useLineTool({
          lineWidth: 24, strokeStyle:'#FFFF00',opacity:'0.3',lockAspectRatio:false},{singleLine: true})
      }
      if(this.useLine){
        ctx.useLineTool({
          lineWidth: 3, strokeStyle:'#3298DC', lockAspectRatio:false},{singleLine: true})
      }
    }
    else{
      ctx.setConfig("setFocus", true);
      ctx.setConfig("background", "grid");
      ctx.setConfig("showHints", true);
      //ctx.focus(true);
    }
    ctx.setConfig("pageBorderColour","rgba(0,0,0,0.0)")
    ctx.setConfig("autoPickTool", false);


    ctx.addKeyboardShortcut("Ctrl+M", () => {
      if (ctx['isInFocus']) {
        ctx.focus(false);
        
        let id = ctx.getCurrentTool();
        if (id === "rectangle" || id === "circle") {
          id = "shape";
        }
        document.getElementById(id).focus();
        ctx.focus();
      } else {
        ctx.focus(true);
      }
      ctx['isInFocus'] = !ctx['isInFocus'];
    });

    ctx.on("tool-changed", function() {
      if (ctx['isInFocus']) {
        ctx.focus(true);
      } else {
        ctx.focus(false);
      }
    });

    ctx.setConfig("setFocus", true);
    ctx.setConfig("background", "grid");
    ctx.setConfig("showColourPanel", false);
    ctx.setConfig("showPropertyPanel", false); // good for troubleshooting the available properties
    ctx.setConfig("showPageSelectorControls",true);
    ctx.setConfig("autoPickToolText", false);
    ctx.setConfig("allowTextInShape", false); // BUG: this does not seem to be working
    ctx.setConfig("allowResize", true); // this should only be applied to stamp and text maybe?
    ctx.setConfig("multilineText", true); 
    ctx.setConfig("defaultFontSize", 18); // this should only be applied to stamp and text maybe?
    ctx.setConfig("outsidePageColour", '#ffffff');
    ctx.setConfig("pageShadow", false);
    ctx.setConfig("pageView", false)
    // ctx.setPaperSize(width, height);
  
    const isUserStudent = this.auth.user() && this.auth.user().value ? this.auth.user().value.accountType === AccountType.STUDENT : false;
    //For logging drawing data
    const user = this.auth.user().getValue();
    const isStudent = ( user && user.accountType === AccountType.STUDENT)
    const isInTestRunner = (this.parent === DrawDisplayMode.TEST_RUNNER)
    if(isInTestRunner && isStudent){
      ctx.on("nodes-added", (nodes) => {
        for(let node of nodes) {
          this.drawLog.logPath(ctx, node, this.useLine, this.useHighlighter);
        }
      });
    }
  
    ctx.on("hint", function(text) {
      console.log("hint event is called")
      ctx.addToLanguage("en:click-to-place-first-point-of-line:Click to place first point of line")
      ctx.addToLanguage("en:click-to-place-another-point-or-double-click-to-end-the-line:Click once to finish drawing, or double-click to place a point and continue drawing.")
    });

    ctx.setConfig("minimumZoom", this.minZoom);
    ctx.setConfig("maximumZoom", this.maxZoom);
    ctx.setConfig("allowZoom", false)
    // ctx.setPaperSize(width, height);



    ctx.on("nodes-added", function(nodes) {
      // console.log("Added %s nodes", nodes.length);
      // lockSize
    });
    ctx.setConfig("showHints", true);
    ctx.on("hint", function(text) {
      console.log("hint event is called")
      ctx.addToLanguage("en:click-to-place-first-point-of-line:Click to place first point of line")
      ctx.addToLanguage("en:click-to-place-another-point-or-double-click-to-end-the-line:Click once to place a point and continue drawing. Double-click to finish drawing.")
    });
    ctx.on("edit-text-shown", () => {
      ctx.setProperties({"fillStyle": this.currentColour});
    })

    ctx.on("draw", () => {
      console.log('ctx blurred')
      const strSave = this.ctx.save();
      console.log("save before merge", JSON.parse(strSave.substr(strSave.indexOf('['))))
      if (this.savedCtx) {
        this.authToolDiagramUpdated.emit({blockID: this.blockID, ctx: strSave});
      }
    })

    ctx['setColor'] = (colour:any) => {
      ctx.setColour(colour, false);
      ctx.setColour(colour, true);
      this.currentColour = colour
      this.updateSelectedStampColour();
    }

    ctx['renderShapeFillConfig'] = (config:any) => {
      const colour = ctx.getFillColour();
      // config.fillStyle = 'transparent';
      return ctx['renderDashesConfig'](config);
    }


    ctx['zoomIn'] = () => {
      this.zoomIn();
    }

    ctx['zoomOut'] = () => {
      this.zoomOut();
    } 

    ctx['getMode'] = () => {
      return this.mode;
    }

    // ctx['renderShapeFillConfig'] = (config:any) => {
    //   const colour = ctx.getFillColour();
    //   // config.fillStyle = 'transparent';
    //   return ctx['renderDashesConfig'](config);
    // }
  
    ctx['renderShapeOutlineConfig'] = (config:any) =>{
      const colour = ctx.getFillColour();
      config.strokeStyle = colour;
      config.fillStyle = 'transparent';
      this.currFillStyle = 'transparent';
      
      return ctx['renderDashesConfig'](config);
    }

    ctx['renderFilledShapeConfig'] = (config) => {
      this.currFillStyle = 'filled';
      return config
    }

    ctx['setCurrLineStyle'] = (lineStyle: any) => {
      this.isDashesEnabled = lineStyle !== null;
      this.currLineStyle = lineStyle;
      ctx.setToolProperty('dashes', lineStyle);
    }

    ctx['getCurrFillStyle'] = () => {
      return this.currFillStyle;
    }

    ctx['setCurrStamp'] = (url: any, isMultiples: boolean, stamp: string) => {
      this.currStamp = stamp;
      let stampUrl = url
      switch(stampUrl.url){
        case 'Triangle':
          stampUrl.url = this.getTriangleStampUrl();
          break;
        case 'Square':
          stampUrl.url = this.getSquareStampUrl();
          break;
        case 'Star':
          stampUrl.url = this.getStarStampUrl();
          break;
        case 'Circle':
          stampUrl.url = this.getCircleStampUrl();
          break;
      }
      ctx.useStampTool(stampUrl, isMultiples);
    }

    ctx['getCurrStamp'] = () => {
      return this.currStamp;
    }

    ctx['isCurrStamp'] = (stamp: string, colour: string) => {
      return stamp === this.currStamp && (colour === this.currentColour || (colour == '#000' && this.currentColour == '#000000'));
    }

    ctx['getCurrLineStyle'] = () => {
      return this.currLineStyle;
    }

    ctx['renderTextConfig'] = (config:any) => {
      const colour = ctx.getFillColour();
      config.fillStyle = colour;

      return ctx['renderDashesConfig'](config);
    }
  
    ctx['renderDashesConfig'] = (config:any) =>{
      if (this.isDashesEnabled){
        config.dashes = '6,3'
      }
      else{
        config.dashes = null;
      }
      return config;
    }

    ctx['setColor']('#000000');  
    
    this.ctx = ret.ctx
    if (this.zoomLevel) this.ctx.setZoom(this.zoomLevel);
    this.ctx.resize();
    if(this.parent === DrawDisplayMode.TEST_RUNNER){
      console.log("Test after view init")
      this.setPageToQuestion();
    }
    ctx['isMaxZoom'] = () => {
      const roundedScale = Math.round(this.ctx.getCanvasScale() * 10) / 10;

      return roundedScale === this.maxZoom;
    }

    ctx['isMinZoom'] = () => {
      const roundedScale = Math.round(this.ctx.getCanvasScale() * 10) / 10;

      return roundedScale === this.minZoom;
    }
    ctx['setColor']('#000000');

    console.log("ret is ", ret);
    this.ctx = ret.ctx;
    this.ctx.resize();
     
    
  }

  isDashesEnabled:boolean;
  // setDashes(isDashesEnabled:boolean){
  //   this.isDashesEnabled = isDashesEnabled;
  //   const config = this.ctx['renderDashesConfig']({});
  //   this.ctx.setToolProperty('dashes', config.dashes);
  // }

  completed(){
    this.pngFile = this.ctx.save("png"); 
  }

  getCanvasStyle() {
    let style:any = {}

    if(this.parent === DrawDisplayMode.TEST_RUNNER) {
      style.outline = "none";
    }

    return style;
  }

  zoomIn() {

    if (this.currZoom === this.maxZoom * 100) {
      return;
    }

    var viewRect = this.ctx.getViewRectangle();

    this.currZoom += 25;
    this.ctx.setZoom(this.currZoom/100, viewRect.x + viewRect.width/2, viewRect.y + viewRect.height/2);
  }

  zoomOut() {

    if (this.currZoom === this.minZoom * 100) {
      return;
    }
    var viewRect = this.ctx.getViewRectangle();

    this.currZoom -= 25;
    this.ctx.setZoom(this.currZoom/100, viewRect.x + viewRect.width/2, viewRect.y + viewRect.height/2);

  }

  getCircleStampUrl(){
    return this.circleStampPNGs.get(this.currentColour);
  }

  getStarStampUrl(){
    return this.starStampPNGs.get(this.currentColour);
  }

  getSquareStampUrl(){
    return this.squareStampPNGs.get(this.currentColour);
  }

  getTriangleStampUrl(){
    return this.triangleStampPNGs.get(this.currentColour);
  }

  updateSelectedStampColour(){
    switch(this.currStamp){
      case 'triangle':
        this.ctx.useStampTool({url: this.getTriangleStampUrl(), width:15}, true);
        break;
      case 'square':
        this.ctx.useStampTool({url: this.getSquareStampUrl(), width:15}, true);
        break;
      case 'star':
        this.ctx.useStampTool({url: this.getStarStampUrl(), width:15}, true);
        break;
      case 'circle':
        this.ctx.useStampTool({url: this.getCircleStampUrl(), width:15}, true);
        break;
    }
  }
}
