import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';
import { BcAssessmentsService, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { DistrictDetail, SchoolDetail } from "../../bc-accounts/bc-accounts.service";
import { GridOptions, GridReadyEvent } from 'ag-grid-community';

@Component({
  selector: 'fsa-student-result',
  templateUrl: './fsa-student-result.component.html',
  styleUrls: ['./fsa-student-result.component.scss']
})
export class FsaStudentResultComponent implements OnInit {

  @Input() testWindow: TestWindow;
  @Input() districtDetail: DistrictDetail;
  @Input() schoolDetail: SchoolDetail;

  loading: boolean;
  gridOptions: GridOptions;
  isGridLoaded: boolean;

  constructor(
    private auth: AuthService,
    private routes: RoutesService,
  ) { 
    this.loading = false;
    this.isGridLoaded = false;

    this.initGridOptions();
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes.schoolDetail || changes.testWindow || changes.districtDetail) {
      await this.getData()
    }
  }

  ngOnInit(): void {
    console.log(this.testWindow);
    console.log(this.districtDetail);
    console.log(this.schoolDetail);
    
    // this.init();
  }

  initGridOptions(): void {
    console.log('init grid options');
    this.gridOptions = {
      rowData: [],
      columnDefs: [
        { field: 'pen', headerName: "PEN", width: 120, },
        { field: 'firstName', headerName: "First Name", width: 120, },
        { field: 'lastName', headerName: "Last Name", width: 120, },
        { field: 'grade', headerName: "Grade", width: 120, },
        { field: 'isEnrolled', headerName: "Enrolled", width: 120, },
        { field: 'isExempted', headerName: "Exempted", width: 120, },
        { field: 'isStarted', headerName: "Is Login", width: 120, },
        { field: 'isSubmitted', headerName: "Is Submitted", width: 120, },
        { field: 'analysisCode', headerName: "Analysis Code", width: 120, },
      ],
      defaultColDef: {
        suppressMenu: true,
        floatingFilter: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      enableCellTextSelection: true,
      animateRows: true,
      pagination: true,
      paginationPageSize: 10,
      onGridReady: (event: GridReadyEvent) => {
        this.isGridLoaded = true;
      },
    };
  }

  async init() {
    await this.getData();
  }

  async getData(): Promise<void> {
    console.log('get data');
    console.log(this.isGridLoaded);
    if (!this.isGridLoaded) return;

    this.loading = true;
    let response = await this.auth.apiGet(this.routes.MINISTRY_ADMIN_FSA_STUDENT_RESULT, this.testWindow.id, {
      query: {
        districtGroupId: this.districtDetail?.groupId,
        schoolGroupId: this.schoolDetail?.groupId
      }
    })

    response = response.map(res => ({
      ...res,
      isEnrolled: this.parseBoolToText(res.isEnrolled),
      isExempted: this.parseBoolToText(res.isExempted),
      isStarted: this.parseBoolToText(res.isStarted),
      isSubmitted: this.parseBoolToText(res.isSubmitted),
    }))

    this.gridOptions.api.setRowData(response);
    console.log(response);
    this.loading = false;
  }
  
  // public getSelfReflections() {
  //   return this.data.filter(d => d.slug === 'self-reflection' && d.meta);
  // }

  private parseBoolToText(val: boolean) {
    return val ? 'Yes' : 'No';
  }

}
