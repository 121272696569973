import { IMenuTabConfig } from "../../../ui-partial/menu-bar/menu-bar.component";

export interface IView<T> extends IMenuTabConfig<T> {
  imgUrl: string;
  secondaryImgUrl?: string;
  description: string;
  hasIndicator?: boolean;
  disabled?: boolean;
}

export enum BCFSAMinistryAdminView {
  DASHBOARD = "dashboard",
  ASSESSMENTS = "assessment_setup1",
  ASSESSMENT_MATERIALS = "assessment_materials",
  DISTRICT_AND_SCHOOL_ACCOUNTS = "accounts",
  STUDENT_ACCOUNTS = "students",
  FULL_MATERIALS_AND_SUPPLEMENTAL_PRINTS = "print_materials",
  SESSION_PROGRESS_REPORTS = "session_reports",
  SCORE_ENTRY_SESSION = "score-entry",
  FINAL_RESULTS = "final_results",
  MONITORING_MARKING = "monitoring-marking",
}

export const BC_FSA_MINISTRY_ADMIN_VIEWS = [
  {
    id: BCFSAMinistryAdminView.ASSESSMENTS,
    caption: "Assessments", //'Assesements',
    imgUrl: "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/assessments/1610550411907/assessments.svg",
    description: "Use this page to review and schedule secure assessments and score entry."
  },
  {
    id: BCFSAMinistryAdminView.ASSESSMENT_MATERIALS,
    caption: "sa_assessment_material_bc", //'Assesement Materials',
    imgUrl: "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/assessment_materials/1610550458196/assessment_materials.svg",
    description: "ma_assessment_material_desc",
    disabled: false
  },
  {
    id: BCFSAMinistryAdminView.DISTRICT_AND_SCHOOL_ACCOUNTS,
    caption: "ma_districts_school_accounts_title", //'District and School Accounts',
    imgUrl: "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/accounts/1610550490037/accounts.svg",
    description: "ma_districts_school_accounts_desc"
  },
  {
    id: BCFSAMinistryAdminView.STUDENT_ACCOUNTS,
    caption: "sa_students_bc", //'District and School Accounts',
    imgUrl: "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/515714/authoring/student/1654268377651/student.png",
    description: "ma_students_bc_desc"
  },
  {
    id: BCFSAMinistryAdminView.FULL_MATERIALS_AND_SUPPLEMENTAL_PRINTS,
    caption: "Full Materials and Supplemental Prints", //'Full Materials and Supplemental Prints',
    imgUrl: "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/full_materials/1610550525040/full_materials.svg",
    secondaryImgUrl: "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/bell/1610551601274/bell.svg",
    description: "Use this page to review and submit print materials to BC Mail.",
    disabled: false
  },
  {
    id: BCFSAMinistryAdminView.SESSION_PROGRESS_REPORTS,
    caption: "sa_session_reports_bc", //'Session Reports',
    imgUrl: "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/session_progress_reports/1610550572361/session_progress_reports.svg",
    description: "ma_session_reports_desc"
  },
  {
    id: BCFSAMinistryAdminView.SCORE_ENTRY_SESSION,
    caption: "Score Entry", //'Score Entry Session',
    imgUrl: "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/515714/authoring/customer/1660322306834/customer.png",
    description: "Use this page to review and input CR scores into the score entry system."
  },
  {
    id: BCFSAMinistryAdminView.FINAL_RESULTS,
    caption: "Results Reports", //'Final Results',
    imgUrl: "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/final_results/1610550637104/final_results.svg",
    description: "Use this page to review the Student Results and School Level Raw and Scaled Reports for the secure assessments."
  },
  {
    id: BCFSAMinistryAdminView.MONITORING_MARKING,
    caption: "Monitoring", //'Score Entry Session',
    imgUrl: "https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/score_entry_session/1610550608614/score_entry_session.svg",
    description: "Use this page to access FSA Monitoring sessions."
  },
];
