<div>
    <!-- <table class="table is-bordered">
        <tr>
            <th>PEN</th>
        </tr>
        <tr *ngFor="let item of getSelfReflections()"> 
            <td>{{ item.meta.session }}</td>
            <td>{{ item.meta.assessment }}</td>
            <td>{{ item.meta.item }}</td>
            <td><a [href]="item.meta.file_url"><tra [slug]="item.meta.file_link_slug"></tra></a></td>
            <td>
                <span *ngIf="!item.meta.img_url"><tra slug="sa_na" ></tra></span>
                <img *ngIf="item.meta.img_url" class="word-cloud" [src]="item.meta.img_url">
            </td>
        </tr>
    </table> -->
    <!-- <ng-container *ngIf="isGridLoaded"> -->
        <ag-grid-angular
            style="width: 100%; height: 600px; margin-top: 2rem;"
            class="ag-theme-alpine"
            [gridOptions]="gridOptions"
        >
        </ag-grid-angular>
    <!-- </ng-container> -->
</div>