<div class="screen" id="screen">
                <h1 *ngIf="!review" class="block-inserters" style="margin-top:2em; font-size:1em;">
                    Use the buttons below to respond to the question using text, tables, or freeform drawings.
                </h1>
                <h1 *ngIf="review" class="block-inserters" style="margin-top:2em; font-size:1em; padding-left:1em" aria-label="Review mode">
                    <div>
                        On this page you can review your work before moving on to the next question.
                    </div>
                    <div>
                        Go back and re-read each question followed by your answers.
                    </div>
                    <div>
                        If the question has more than one part, ensure you’ve answered each part of the question.
                    </div>
                    <div>
                        If you would like to edit your answers, click the “Go Back” button at the bottom of the screen. Once you are happy with your response, click the "Complete and go to next question" button at the bottom of the screen. You will be able to review your answers to all of the questions before submitting.”
                    </div>
                </h1>

                <div class="blocks" cdkDropList (cdkDropListDropped)="drop(blocks, $event);">
                    <div *ngFor="let block of blocks; let index = index;" cdkDrag id="blocks">
                        <div class="solution-block" id="{{ 'block' + index }}">
                            <button *ngIf="!review" class="button is-white is-handle" cdkDragHandle aria-label="DragBlock">
              <i class="fas fa-sort" aria-hidden="true"></i>
            </button>
                            <div class="solution-block-content" [ngSwitch]="block.blockType">
                                <div *ngSwitchCase="BlockTypes.TEXT" [class.hide-ckeditor-toolbar]="!block.isInFocus" #text class="ckdiv">
                                    <!-- <element-render-text-constr></element-render-text-constr> -->
                                    <ckeditor [editor]="Editor" [config]="ckEditorConfig" (ready)="onReady($event)" (focus)="block.isInFocus=true"></ckeditor>
                                </div>
                                <div class=""></div>
                                <div *ngSwitchCase="BlockTypes.MATH">
                                    <capture-math></capture-math>
                                </div>
                                <div *ngSwitchCase="BlockTypes.TABLE" #table id="{{ 'tableBlock' + index }}">
                                    <table class="table-input" id="table">
                                        <tr *ngIf="block.isEditingTable">
                                            <td></td>
                                            <td *ngFor="let cell of block.rows[0].cells; let col_i = index;" class="edit-cell">
                                                <button *ngIf="!review" class="button  is-small " (click)="removeColumn(block, col_i)">
                        <i class="fa fa-trash" aria-hidden="true" aria-label="DeleteBlock"></i>
                      </button>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let row of block.rows; let row_i = index;">
                                            <td *ngIf="block.isEditingTable" class="edit-cell">
                                                <button *ngIf="!review" class="button is-small " (click)="removeRow(block, row_i)">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                                            </td>
                                            <td *ngFor="let cell of row.cells">
                                                <textarea cdkTextareaAutosize [cdkTextareaAutosize]="true" [readonly]="review"></textarea>
                                            </td>
                                        </tr>
                                        <tr>
                                    </table>
                                    <!-- <img *ngIf="review" [src]="imgCanvas"> -->
                                    <div style="display:flex; flex-direction:row; justify-content: space-between;">
                                        <div>
                                            <div *ngIf="block.isEditingTable">
                                                <button (click)="insertRow(block)" class="button is-small">Add Row</button>
                                                <button (click)="insertColumn(block)" class="button is-small">Add Column</button>
                                            </div>
                                        </div>
                                        <button *ngIf="!review" (click)="block.isEditingTable = !block.isEditingTable" class="button is-white is-small show-on-hover">
                      <i class="fas fa-cog"></i>
                    </button>
                                    </div>
                                </div>
                                <div *ngSwitchCase="BlockTypes.DIAGRAM" [ngClass]="{'drawing': review === false}" #diagram>
                                    <element-render-drawing [mode]="mode" [id]="index"></element-render-drawing>
                                </div>
                            </div>
                            <button *ngIf="!review" class="button is-white is-handle" (click)="removeElement(blocks, index)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="!review" #constrRespInput class="buttons block-inserters">
                    <button *ngFor="let block of availableBlocks" [disabled]="isBlockInsertBuffered(block.id)" (click)="insertBlock(block.id)" class="button btn block-selector">
              <div class="icon"><i  [ngClass]="block.icon"></i></div>
              <div> {{block.caption}} </div>
            </button>
                </div>
                <div *ngIf="blocks.length && !review" style="margin:0 5em;">
                    <hr>
                    <div>
                        <button class="button is-info" (click)="toggleReview()">Review Your Work</button>
                        <!-- <button class="button" (click)="showNext()">Next Question</button> -->
                    </div>
                </div>


            </div>

            <div *ngIf="review" class="review">
                <div class="review__content">
                    <div style="margin:0 2.5em;">
                        <div class="review-button-container">
                            <button class="button is-info review-button" (click)="toggleReview()"><i class="fa fa-chevron-left" aria-hidden="true"></i><span class='left'>Go Back</span></button>
                            <button class="button review-button" (click)="showNext()"><span class='right'>Go To Next Question</span><i class="fa fa-chevron-right" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
            </div>