import { Component, OnInit, Input } from '@angular/core';
import { IContentElementImage, IContentElementDynamicImage, QuestionState, ImageStates, ElementType, IContentElementImageSubText } from '../models';
import { generateDefaultElementImage, generateOldDefaultImage } from '../../ui-item-maker/item-set-editor/models/index'
import { DomSanitizer } from "@angular/platform-browser";
import { state } from '@angular/animations';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { TextToSpeechService } from '../text-to-speech.service';



@Component({
  selector: 'element-render-image',
  templateUrl: './element-render-image.component.html',
  styleUrls: ['./element-render-image.component.scss']
})
export class ElementRenderImageComponent implements OnInit {

  @Input() element:IContentElementDynamicImage;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState; // not used
  @Input() isSelected?: boolean;
  @Input() isHovered?: boolean;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() draggable?: boolean = false;

  constructor(private sanitizer: DomSanitizer,
    private textToSpeech: TextToSpeechService) { }

  ngOnInit() {

  }

  imgExists(img) {
    if (img && img.image && img.image.url && img.image.url != '') return true;
    return false;
  }

  getImage() {
    if (!this.element){
      return;
    }
    const imgs = this.element.images;
    if (!imgs)  {
      if (this.element.url) {
        return generateOldDefaultImage(this.element, ImageStates.DEFAULT);
      }
      return;
    }
    if (this.isSelected == true) {
      if (imgs[ImageStates.SELECTED] && this.imgExists(imgs[ImageStates.SELECTED])) return imgs[ImageStates.SELECTED];
    } else if (this.isHovered == true) {
      if (imgs[ImageStates.HOVERING] && this.imgExists(imgs[ImageStates.HOVERING])) return imgs[ImageStates.HOVERING];
    }
    if (imgs[ImageStates.DEFAULT]) return imgs[ImageStates.DEFAULT];
    else if (this.element.url) return generateOldDefaultImage(this.element, ImageStates.DEFAULT);
    return imgs[ImageStates.DEFAULT];
  }

  getFilter() {
    if (this.element.frameState) return this.sanitizer.bypassSecurityTrustStyle("drop-shadow( "+this.element.frameState.dropShadowX+"px "+this.element.frameState.dropShadowY+"px "+this.element.frameState.blurRadius+"px "+this.element.frameState.shadowColor+" )");
    else return '';
  }

  getSubTextColor(sub:IContentElementImageSubText){
    if (!sub.invisible){
      if (sub.colourScheme){
        return sub.colourScheme.textColor
      }
      return '#000000'
    }
  }
  getTransform(sub:IContentElementImageSubText) {
    if (sub.rotation){
      return `rotate(${sub.rotation}deg)`;
    }
    return '';
  }

  getPadding() {
    if (this.element.frameState) return this.element.frameState.padding;
    else return '';
  }

  getStyle(subText:IContentElementImageSubText) {
    const style = {}
    if ((!this.element.isNoInvertOnHiContrast || subText.invertTextOnHightContrast) &&  this.textToSpeech.isHiContrast) {
      style["filter"] = "invert(1)"
    }
    return style
  }

  /*getOldUrl() {
    const image = { 
      elementType: ElementType.IMAGE,
      url: this.element.url, 
      fileType: this.element.fileType,
      altText: this.element.altText,
      scale: this.element.scale,
      outline: this.element.outline
    }
    return { condition: ImageStates.DEFAULT, image };
  }*/
}
