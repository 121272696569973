<div class="admin-session">
    <div class="left-side">
        <div class="icon">
            <div class="calendar"></div>
            <div class="calendar-inner"></div>
        </div>
    </div>
    <div class="right-side">
        <div class="page-header">
            <div class="window-details">
                <div class="window-title">
                    <tra slug="da_a_assessment_window"></tra>
                </div>
                <div class="date-container">
                    <span class="caption">
                        <tra slug="da_a_from"></tra>
                    </span>
                    <span class="date">{{adminSessionStart}}</span>
                </div>
                <div class="date-container">
                    <span class="caption">
                        <tra slug="da_a_to"></tra>
                    </span>
                    <span class="date">{{adminSessionEnd}}</span>
                </div>
                <tra slug="sa_da_admin_session_weekdays"></tra>
            </div>
            <div class="password-info"><a *ngIf="!is_offshore"
                    (click)="onPasswordSheetClick()">
                    <tra slug="da_a_password_info_sheet"></tra>
                </a>
                <a *ngIf="is_offshore" class="disabled-link">
                    <tra slug="da_a_password_info_sheet"></tra>
                </a>
            </div>
        </div>

        <table class="component-table">
            <tr>
                <th>
                    <tra slug="da_a_component"></tra>
                </th>
                <th>
                    <tra slug="da_a_component_code"></tra>
                </th>
                <th>
                    <tra slug="da_a_session_password"></tra>
                </th>
                <th>
                    <tra slug="da_a_status"></tra> <span (click)="popupNotif()"><i
                            class="fas fa-question-circle"></i></span>
                </th>
            </tr>
            <tr *ngFor="let component of components, index as i">
                <td class="name">{{component.name}}</td>
                <td class="componentCode">{{component.componentCode}}</td>
                <td class="accessCode">
                    <div class="contents">
                        <div class="backgr">{{component.accessCode}}</div>
                    </div>
                </td>
                <td [ngClass]="{'active-tag': component.activityState, 'inactive-tag': !component.activityState }">
                    <tra [slug]="component.activityState ? 'sa_da_ma_active' : 'sa_da_ma_inactive'"></tra>
                </td>
            </tr>
        </table>
    </div>
</div>