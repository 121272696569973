import { Component, OnDestroy, OnInit } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { LoginGuardService } from '../../api/login-guard.service';
import { BreadcrumbsService } from '../../core/breadcrumbs.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutesService } from '../../api/routes.service';
import { AuthService } from '../../api/auth.service';
import { Subscription } from 'rxjs';
import { ITestDesignPayload, ISectionDef } from '../../ui-testtaker/view-tt-test-runner/view-tt-test-runner.component';
import { IQuestionConfig, IContentElementMcq, ElementType, IAssetImpressionConfig } from '../../ui-testrunner/models';
import { ITestDef } from '../../ui-testrunner/sample-questions/data/sections';
import { IAssessmentFrameworkDetail, TestFormConstructionMethod } from '../item-set-editor/models/assessment-framework';
import { getQuestionTitleFromMap } from '../../ui-testrunner/test-runner/util/question-titles';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { DataGuardService } from 'src/app/core/data-guard.service';
import { StyleprofileService, IStyleProfile } from '../../core/styleprofile.service';
import { ItemMakerService } from '../item-maker.service';


const LOCAL_STORAGE_KEY = 'eqao-sample';
const OBF_CONST = 'h928f92d9gd2';
const obf = (o) => {
  return OBF_CONST + btoa(JSON.stringify(o));
};
const deobf = (str) => {
  str = str.substr(OBF_CONST.length, str.length - OBF_CONST.length)  
  return atob ( str )
};

export enum QuestionScore {
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT',
  UNMARKED = 'UNMARKED'
}
@Component({
  selector: 'view-g9-sample',
  templateUrl: './view-g9-sample.component.html',
  styleUrls: ['./view-g9-sample.component.scss']
})
export class ViewG9SampleComponent implements OnInit, OnDestroy{

  constructor(
    public lang:LangService,
    private loginGuard: LoginGuardService, // 
    private breadcrumbsService: BreadcrumbsService,
    private routes: RoutesService,
    private auth: AuthService,
    private whitelabel: WhitelabelService,
    private router:Router,
    private route: ActivatedRoute,
    private dataGuard: DataGuardService,
    private styleProfile: StyleprofileService,
    private itemMaker: ItemMakerService
  ) { }

  public isToolExploration: boolean = this.route.snapshot.queryParams.explore === 'true';
  routeSub:Subscription; 
  itemSetId:string;
  asmtLabel:string;
  asmtCaption: string;
  asmtFmrk:IAssessmentFrameworkDetail;
  isShowingResults = false;
  questions:Map<number, any>;
  testTakerName: string;
  testTakerFirstName: string;
  testTakerLastName: string;
  testForm: {
    currentTestDesign: ITestDef,
    questionSrcDb: Map<number, any>,
    questionStates: {[key: string]: any},
    testLang: string,
  };
  questionTitleMap:any;
  isAuthenticated:boolean;
  isAuthorized: boolean;
  defaultZoom:number;
  questionTimeSpentMap = new Map();
  isSchoolAdminSecureAccess:boolean;
  tw_type_slug:string;
  schoolType:string;
  isPublicPwdProtected: boolean;

  ngOnInit(): void {

    const queryParams = this.route.snapshot.queryParams;
    const params = this.route.snapshot.params;
    
    this.dataGuard.activate();

    if (queryParams['bcedCopyrightNotice']){
      this.loginGuard.quickPopup( this.lang.tra('txt_asmt_mat_copyright') )
    }
    if (queryParams['defaultZoom']){
      this.defaultZoom = +queryParams['defaultZoom'];
    }

    this.testForm = null;
    this.itemSetId = params['itemSetId'];
    this.asmtLabel = params['label'];
    this.asmtCaption = params['asmtCaption'];
    this.isSchoolAdminSecureAccess = !!queryParams['isSchoolAdmin'];
    this.tw_type_slug = queryParams['tw_type_slug'];

    this.auth.apiCreate('public/log', {
      slug: 'SAMPLE_TEST_START',
      data: { 
        itemSetId   : this.itemSetId,
        asmtLabel   : this.asmtLabel,
        tw_type_slug: this.tw_type_slug,
        isSchoolAdminSecureAccess: this.isSchoolAdminSecureAccess,
        lang        : this.lang.c(),
        userPen     : window['PEN'],
        context     : this.whitelabel.context,
      }
    })

    this.initAuthentication();
    this.schoolType = this.route.snapshot.data['schoolType'];

  }

  ngOnDestroy(): void {
    this.dataGuard.deactivate();
  }

  async initAuthentication(){
    if (this.whitelabel.getSiteFlag('IS_EQAO')){
      this.isAuthenticated = true; // don't need auth for EQAO
      this.isAuthorized = true;
    } 
    else if (this.isSchoolAdminSecureAccess){
      this.isAuthenticated = false;
      this.isAuthorized = false;
    }
    else {
      this.isPublicPwdProtected = await this.auth.apiGet(this.routes.TEST_AUTH_PUB_PWD_PROTECTED, this.itemSetId);
      this.isAuthenticated = !(this.isPublicPwdProtected);
    } 

    if (!this.isAuthenticated){
      this.loginGuard.activate();
    }

    this.styleProfile.styleProfileSub.subscribe(loaded => {
      console.log("loaded", loaded)
      if(loaded && !this.isQuestionLoaded){
        this.isQuestionLoaded = true
        this.loadQuestions();
      }
    })
    
    this.auth.user().subscribe(async (userInfo:any) => {
      if (userInfo){
        this.isAuthenticated = true;
        this.testTakerFirstName = userInfo.first_name;
        this.testTakerLastName = userInfo.last_name;
        this.testTakerName = [userInfo.first_name, userInfo.last_name].join(' ');
        if(this.isPublicPwdProtected){
          this.itemMaker.hasAccessToItemSet(+this.itemSetId).then((res) => {
            this.isAuthorized = !!res
            if(!this.isAuthorized){
              this.errorMessage = 'Test is not available for current user.';
            }
          });
        } else {
          this.isAuthorized = true;
        }
        
      }
    })
  }

  isShowingEmbeddedResults(){
    return this.isShowingResults && this.whitelabel.getSiteFlag('IS_BCED')
  }
  isShowingExternalResults(){
    return this.isShowingResults && !this.whitelabel.getSiteFlag('IS_BCED')
  }

  isEqaoWhitelabel(){
    return this.whitelabel.getSiteFlag('IS_EQAO');
  }

  isEnglish(){
    return (this.lang.c() === 'en')
  }

  private objectToNumMap( obj:{[key:number]: any}){
    const map = new Map();
    Object.keys(obj).forEach(key => {
      map.set(+key, obj[key]);
    })
    return map;
  }
  private numMapToObject( map:Map<number, any>){
    const obj:any = {};
    map.forEach((value, key) => {
      obj[key] = value;
    })
    return obj;
  }


  errorMessage:string;
  isQuestionLoaded: boolean;
  isStyleProfileLoadedFromS3: boolean
  loadQuestions(){
    this.loadFromCache(()=>{
      const query:any = {};
      if (this.isSchoolAdminSecureAccess){
        query.isSchoolAdminSecureAccess = 1
        query.tw_type_slug = this.tw_type_slug
      }
      this.auth
      .apiGet(this.routes.ANON_SAMPLE_TEST_DESIGN_FORM, this.itemSetId, {query})
      .then ( (res:{testFormData:any, framework:string, styleProfile: IStyleProfile | undefined}) => {
        // console.log(res.testFormData)
        this.asmtFmrk = JSON.parse(res.framework);
        
        const { styleProfile :slug } = this.asmtFmrk
        
        //Load style profile from S3
        if(res.styleProfile && !this.isStyleProfileLoadedFromS3){
          this.isQuestionLoaded = false;
          const { styleProfile } = res;
          this.styleProfile.setStyleProfileFromS3(styleProfile, slug);
          this.isStyleProfileLoadedFromS3 = true;
          return;
        } 

        // Backward compatibility : Incase of style profile not found in S3 load the latest from the db.
        else if(slug != null && slug !== this.styleProfile.getSelectedStyleProfile() && !this.isStyleProfileLoadedFromS3){
          this.isQuestionLoaded = false;
          this.styleProfile.setStyleProfile(slug);
          return;
        }
        
        this.questions = this.objectToNumMap(res.testFormData.questionDb),
        this.testForm = {
          currentTestDesign: {
            ... res.testFormData,
            questionDb: null,
          },
          questionStates: {},
          testLang: res.testFormData.lang,
          questionSrcDb: this.questions,
        };
      })
      .catch(e => {
        this.errorMessage = 'This sample test is not currently available.'
      })
    })
    return;
  }


  loadFromCache(alt){
    return alt();
    // try {
    //   let cachedState = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    //   if (cachedState && cachedState !== '') {
    //     const save = deobf(cachedState);
    //     console.log('save', save)
    //     if (save.itemSetId !== this.itemSetId) {
    //       // do not restore state if it was a different assessment
    //       this.testState = save.testState
    //       this.testForm = {
    //         ... save.testState,
    //         questionSrcDb: this.numMapToObject(save.testState.questionSrcDb)
    //       }
    //       return;
    //     }
    //     else{
    //       alt();
    //     }
    //   }
    // } catch (e) {
    //   console.error('malformed test state');
    //   alt();
    // }
  }

  updateLocalStorage(data) {
    this.auth.apiCreate('public/log', {
      slug: '__qdatares',
      data: { 
        // uid: this.auth.getUid(),
        response: obf(data),
        context: this.whitelabel.context,
        // userPen: window['PEN'],
      }
    })
    // if(this.route.snapshot.queryParams.withTeacher){
    // }
    const save = {
      itemSetId: this.itemSetId,
      testState: this.testState,
      testForm: {
        ... this.testForm,
        questionSrcDb: this.objectToNumMap(this.testForm)
      }
    }
    window.localStorage.setItem(LOCAL_STORAGE_KEY, obf(save));
  }

  clearLocalStorage() {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, '');
    this.testState = null;
  }


  getQuestionById(id: number) {
    let question: IQuestionConfig;
    this.questions.forEach(_question => {
      if (_question.id === id) {
        question = _question;
      }
    });
    return question;
  }
  getQuestionByLabel(label: string) {
    let question: IQuestionConfig;
    this.questions.forEach(_question => {
      if (_question.label === label) {
        question = _question;
      }
    });
    return question;
  }

  // ANSWER KEY STUFF
  testState = {
    currentSectionIndex: 0,
    currentQuestionIndex: 0,
    itemSetId: null,
  };
  // answerKey:{[key:string]: any} = { "5949" : { 3 : {optionIndex: 0}}};

  showAnswers = () => {
    try {
      this.auth.apiCreate('public/log', {
        slug: 'SAMPLE_TEST_SUBMIT',
        data: { 
          itemSetId: this.itemSetId, 
          asmtLabel:this.asmtLabel, 
          lang: this.lang.c(), 
          states: this.testForm.questionStates,
          userPen: window['PEN'],
          context: this.whitelabel.context,
        }
      })
    } catch(e) {}

    if (this.isToolExploration || this.isSecureProxy()) {
      this.router.navigate([`${this.lang.c()}/student/dashboard/resources`]);
    } 
    else {
      // try {
      //   this.scoreAllQuestions();
      //   // this.feedAnswersToAllQuestions();
      // } catch(e) {}
      this.selectSectionAndQuestion(0, 0);
      // alert('The answer key page is currently not available.')
    }
    this.isShowingResults = true;
  }
  isSecureProxy(){
    return (this.asmtFmrk.testFormType === TestFormConstructionMethod.MSCAT) && !this.testForm.currentTestDesign.isPanelRoutingByNumCorrect;
  }
  getActiveQuestionState() {
    const states = this.testForm.questionStates;
    const qId = this.getActiveQuestionId();
    let qState = states[qId];
    if (!qState) {
      qState = states[qId] = {};
    }
    return qState;
  }
  selectSectionAndQuestion(sectionIndex, questionIndex){
    this.testState.currentSectionIndex = sectionIndex;
    this.testState.currentQuestionIndex = questionIndex;
  }
  getCurrentSectionIndex(): number {
    if (this.testState) {
      return this.testState.currentSectionIndex;
    }
    return -1;
  }
  getCurrentQuestionIndex(): number {
    if (this.testState) {
      return this.testState.currentQuestionIndex;
    }
    return -1;
  }
  getCurrentQuestionStates(): any {
    if (this.testState) {
      return this.testForm.questionStates;
    }
    return {};
  }
  getCurrentSection(): ISectionDef {
    return this.getSection(this.getCurrentSectionIndex());
  }
  getSection(i: number) {
    return this.testForm.currentTestDesign.sections[i] || {hasCalculator: false, hasFormulas: false, questions: []};
  }
  getCurrentQuestions(): number[] {
    return (this.getCurrentSection()).questions || [];
  }
  getQuestionDef(itemId: number) {
    return this.testForm.questionSrcDb.get(itemId);
  }
  getActiveQuestionId() {
    const qId = this.getCurrentQuestions()[this.getCurrentQuestionIndex()];
    if (!qId) {
      console.warn('Null question');
    }
    return qId;
  }
  getActiveQuestionContent() {
    const content = this.getQuestionDef(this.getActiveQuestionId());
    // console.log('content', content)
    return content;
  }
  // feedAnswersToAllQuestions() {
  //   this.testForm.currentTestDesign.sections.forEach(section => {
  //     section.questions.forEach(qId => {
  //       const qAns = this.answerKey[qId];
  //       const q = this.getQuestionById(qId);
  //       // to do : this is fixed to MCQ
  //       Object.keys(qAns).forEach(entryId => {
  //         const eAns = qAns[entryId];
  //         // to do: assuming flat question structures
  //         q.content.forEach((el:IContentElementMcq) => {
  //           if (el.entryId === +entryId) {
  //             el.options.forEach( (option, optionIndex) => {
  //               if (eAns.optionIndex === optionIndex) {
  //                 option.isCorrect = true;
  //               }
  //             });
  //           }
  //         });
  //       });
  //     });
  //   });
  // }
  saveQuestionResponse = (data) => {
    try {
      this.updateLocalStorage(data)
    }
    catch (e){
      try {
        this.updateLocalStorage({
          ... data,
          response_raw: null
        })
      }
      catch(e){}
    }
    return Promise.all([]);
  }

 logAssetView = async (assetsToSave) => {
    if (!this.itemSetId) throw new Error('no itemSetId');
    
    return await Promise.all(assetsToSave.map(async (asset) => {
      const data = <IAssetImpressionConfig> { ...asset, item_set_id: this.itemSetId };
      return await this.auth.apiCreate(this.routes.TEST_AUTH_ITEM_IMPRESSION, data)
    }));
  }

  logItemView = async (itemToSave) => {
    if (!this.itemSetId) throw new Error('no itemSetId');
    const data = <IAssetImpressionConfig> { ...itemToSave, item_set_id: this.itemSetId };
    return await this.auth.apiCreate(this.routes.TEST_AUTH_ITEM_IMPRESSION, data);
  }

  logQuestionTimeSpent = async (data) => Promise.resolve(); 

  getQuestionTimeSpent = () => Promise.resolve([]);

  goHome = () => {
    // this.router.navigate([`${this.lang.c()}/login-router-st`]);
    this.initTags();
    if (this.whitelabel.getSiteFlag('IS_BCED')){
      if(this.checkTag("BC_FSA")){
        alert(this.lang.tra('alert_completed_assessment_BC_FSA'))
      } else {
        alert(this.lang.tra('alert_completed_assessment_BC'))
      }
    } else {
      alert('You have completed the assessment. You may now close this tab.');
    }
  }
  // private isEResCorrect(eRes, eAns) {
  //   if (eRes && eRes.selections) {
  //     if (eRes.selections[0] && eRes.selections[0].i === eAns.optionIndex) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  isReadingSelection(questionId: number) {
    return this.testForm.questionSrcDb.get(questionId).isReadingSelectionPage;
  }

  getSectionTitleSlug() {
    return this.asmtFmrk.testFormType === TestFormConstructionMethod.MSCAT ? "title_stage" : "title_section";
  }

  getQuestionTitleFromIndex(sIndex: number, qIndex: number) {
    if(!this.testForm || !this.testForm.currentTestDesign) {
      return;
    }
    const qId = this.testForm.currentTestDesign.sections[sIndex].questions[qIndex];
    return this.getQuestionTitle(qId);
  }

  getQuestionTitle(qId:number):string {
    return getQuestionTitleFromMap(this.questionTitleMap, qId);
  }

  frameWorkTagsRef = new Map();
  initTags(){
    // console.log('frameWorkTags', this.frameWorkTags);
    if (this.asmtFmrk){
      if (!this.asmtFmrk.tags) return;
      this.asmtFmrk.tags.forEach(tag => {
        const key = (''+tag.slug).trim();
        this.frameWorkTagsRef.set(key, true);
      })
    }
  }

  checkTag(tag:string){
    return this.frameWorkTagsRef.get(tag);
  }

  isUserAuthorized() {
    if(this.isPublicPwdProtected){
      return this.isAuthorized;
    }
    return true;
  }

  activateLogin() {
    // this.loginGuard.getAutoLogout().next(true);
    this.auth.logout();
    window.location.reload();
  }

}
