import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';
import { BcAccountsService, DistrictDetail, Pagination, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { BcAssessmentsService, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { BcReportsService } from 'src/app/bc-reports/bc-reports.service';
import { CognitiveLevelScoresReport, IndividualRawScoresProficiencyLevel, IndividualRawScoresReport, StudentSelfReflectionReport } from 'src/app/bc-reports/types';
import { AccountType } from 'src/app/constants/account-types';
import { LangService } from 'src/app/core/lang.service';

enum Sheet {
  INDIVIDUAL_RAW_SCORES = 'sa_fr_issr_raw_scores',
  INDIVIDUAL_SCALED_SCORES = 'sa_fr_issr_scaled_scores',
  COGNITIVE_LEVEL_SCORES = 'sa_fr_issr_level_scores',
  STUDENT_SELF_REFLECTION = 'sa_fr_issr_self_reflection',
}

@Component({
  selector: 'school-level-data-report',
  templateUrl: './school-level-data-report.component.html',
  styleUrls: ['./school-level-data-report.component.scss']
})
export class SchoolLevelDataReportComponent implements OnInit {
  @Input() testWindow: TestWindow;
  @Input() districtDetail: DistrictDetail;
  @Input() schoolDetail: SchoolDetail;
  @Input() accountType: AccountType = AccountType.BC_FSA_SCHOOL_ADMIN;
  @Input() includeScaled: boolean = false;

  isScaled: boolean = false;

  pagination: Pagination;
  individualRawScoresReport: IndividualRawScoresReport;
  cognitiveLevelScoresReport: CognitiveLevelScoresReport;
  studentSelfReflectionReport: StudentSelfReflectionReport;

  Sheet = Sheet;
  sheet: Sheet = Sheet.INDIVIDUAL_RAW_SCORES;
  sheets = [Sheet.INDIVIDUAL_RAW_SCORES, Sheet.COGNITIVE_LEVEL_SCORES, Sheet.STUDENT_SELF_REFLECTION];

  isLoading: boolean = false;

  isIrtReady: boolean = false;

  refresh: boolean = false;
  currentLang: 'en' | 'fr' = 'en';

  constructor(
    private bcAccounts: BcAccountsService,
    private bcReports: BcReportsService,
    private bcAssessments: BcAssessmentsService,
    private lang: LangService,
    private auth: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.generatesSheets();
    this.pagination = this.getInitialPagination();
    this.updateTable();
    console.log(this.sheets);
    this.route.params.subscribe(params => {
      this.handleLanguageToggle(params.lang == 'en' ? 'en' : 'fr');
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes.schoolDetail || changes.testWindow) {
      this.updateTable();
    }
    if (changes.testWindow) {
    //   this.bcAssessments.isIrtReadyForTestWindow(this.testWindow.id).then(ready => {
    //     // this.isIrtReady = ready;
    //     this.sheets = [Sheet.INDIVIDUAL_SCALED_SCORES, Sheet.INDIVIDUAL_RAW_SCORES, Sheet.COGNITIVE_LEVEL_SCORES, Sheet.STUDENT_SELF_REFLECTION]
    //     // if (ready) {
    //     //   this.sheet = Sheet.INDIVIDUAL_SCALED_SCORES;
    //     //   this.isScaled = true;
    //     // } else {
    //     //   this.sheet = Sheet.INDIVIDUAL_RAW_SCORES;
    //     //   this.isScaled = false;
    //     // }
    //   })
    }
    this.handleLanguageToggle(this.currentLang);
  }

  generatesSheets() {
    if (this.auth.isFsaMinistryAdmin() || this.auth.isFsaEaoAdmin()) {
      this.sheets = [Sheet.INDIVIDUAL_SCALED_SCORES, Sheet.INDIVIDUAL_RAW_SCORES, Sheet.COGNITIVE_LEVEL_SCORES, Sheet.STUDENT_SELF_REFLECTION];
    } else {
      this.sheets = [Sheet.INDIVIDUAL_RAW_SCORES, Sheet.COGNITIVE_LEVEL_SCORES, Sheet.STUDENT_SELF_REFLECTION];
    }
  }

  handleLanguageToggle(lang: 'en' | 'fr') {
    this.refresh = true;
    this.currentLang = lang;
    this.lang.setCurrentLanguage(lang);
    setTimeout(() => this.refresh = false, 1000);
  }

  async updateTable() {
    this.isLoading = true;

    if (!this.pagination) return;

    if (this.schoolDetail) {
      this.pagination.orderBy = 'grade';
      this.pagination.orderDirection = 'asc';

      if (this.sheet === Sheet.INDIVIDUAL_RAW_SCORES) {
        this.individualRawScoresReport = await this.bcReports.getIndividualRawScoresReport({
          school_group_id: this.schoolDetail.groupId,
          school_code: this.schoolDetail.foreignId,
          school_name: this.schoolDetail.name,
          test_window_id: this.testWindow.id,
        }, this.pagination);
        this.pagination.count = this.individualRawScoresReport.data.count;
      } else if (this.sheet === Sheet.INDIVIDUAL_SCALED_SCORES) {
        this.individualRawScoresReport = await this.bcReports.getIndividualScaledScoresReport({
          school_group_id: this.schoolDetail.groupId,
          school_code: this.schoolDetail.foreignId,
          school_name: this.schoolDetail.name,
          test_window_id: this.testWindow.id,
        }, this.pagination);
        this.pagination.count = this.individualRawScoresReport.data.count;
      }
      else if (this.sheet === Sheet.COGNITIVE_LEVEL_SCORES) {
        this.cognitiveLevelScoresReport = await this.bcReports.getCognitiveLevelScoresReport({
          school_group_id: this.schoolDetail.groupId,
          school_code: this.schoolDetail.foreignId,
          school_name: this.schoolDetail.name,
          test_window_id: this.testWindow.id,
        }, this.pagination);
        this.pagination.count = this.cognitiveLevelScoresReport.data.count;
      } else if (this.sheet === Sheet.STUDENT_SELF_REFLECTION) {
        this.studentSelfReflectionReport = await this.bcReports.getStudentSelfReflectionReport({
          school_group_id: this.schoolDetail.groupId,
          school_code: this.schoolDetail.foreignId,
          school_name: this.schoolDetail.name,
          test_window_id: this.testWindow.id,
        }, this.pagination);
        this.pagination.count = this.studentSelfReflectionReport.data.count;
      }
    }

    if (this.pagination.count === 0){
      this.pagination.count = undefined;
    }

    this.isLoading = false;
  }

  selectSheet(sheet: Sheet) {
    if (sheet === Sheet.INDIVIDUAL_SCALED_SCORES) {
      this.isScaled = true;
    } else {
      this.isScaled = false;
    }
    console.log(this.isScaled);
    this.sheet = sheet;
    this.pagination = this.getInitialPagination();
    this.updateTable();
  }

  getSheetName(sheet: Sheet) {
    if (this.isScaled && sheet == Sheet.INDIVIDUAL_SCALED_SCORES) {
      return Sheet.INDIVIDUAL_SCALED_SCORES;
    } else {
      return sheet;
    }
  }

  sumScore(srScore: string | number, crScore1: string | number, crScore2: string | number, crScore3: string | number): string {
    if (srScore == 'NR' && crScore1 == 'NR' && crScore2 == 'NR' && crScore3 == "NR") {
      return 'NR';
    } else {
      return [srScore, crScore1, crScore2, crScore3].filter(score => score != 'NR').reduce((sum: number, score: string | number) => {
        return sum + parseInt(score.toString());
      }, 0).toString();
    }
  }

  proficiencyLevelRange = {
    'English': {
      4: {
        'literacy': [0, 17, 31],
        'numeracy': [0, 16, 34],
      },
      7: {
        'literacy': [0, 16, 34],
        'numeracy': [0, 16, 32],
      },
    },
    'French': {
      4: {
        'literacy': [0, 15, 27],
        'numeracy': [0, 16, 34],
      },
      7: {
        'literacy': [0, 16, 29],
        'numeracy': [0, 16, 32],
      },
    },
  }

  proficiencyLevels = [
    IndividualRawScoresProficiencyLevel.PLU,
    IndividualRawScoresProficiencyLevel.EMERGING,
    IndividualRawScoresProficiencyLevel.ON_TRACK,
    IndividualRawScoresProficiencyLevel.EXTENDING,
  ];

  getProficiencyLevel(language: 'English' | 'French', grade: 4 | 7, component: 'literacy' | 'numeracy', totalScore: string | number) {
    if (totalScore == 'NR') return this.proficiencyLevels[0];
    const _totalScore = parseInt(totalScore.toString());

    let languageRange = this.proficiencyLevelRange[language];
    if (!languageRange) throw new Error;
    let gradeRange = languageRange[grade];
    if (!gradeRange) throw new Error;
    let range = gradeRange[component];
    if (!range) throw new Error;

    let level = this.proficiencyLevels[this.proficiencyLevels.length - 1]; // EX
    for (let i = 0; i < range.length - 1; i++) {
      if (_totalScore >= range[i] && _totalScore < range[i + 1]) {
        level = this.proficiencyLevels[i + 1];
        break;
      }
    }
    return level;
  }

  async export() {
    if (!this.schoolDetail) return;
    await this.bcAccounts.exportSchoolLevelDataReport(this.schoolDetail.groupId, this.testWindow, this.isScaled, this.currentLang);
  }

  private getInitialPagination(): Pagination {
    let pagination = this.bcAccounts.getInitialPagination();
    if (this.sheet === Sheet.INDIVIDUAL_RAW_SCORES) {
      pagination.size = 100;
    }
    return pagination;
  }

  getSchoolLanguage(): 'English' | 'French' {
    if (!this.schoolDetail) return 'English';
    return this.schoolDetail.lang == 'EN' ? 'English' : 'French';
  }


  isSheetDisabled(s: Sheet) {
    const canAccessScaledScores = this.auth.isFsaMinistryAdmin() || this.auth.isFsaEaoAdmin();

    if (s === Sheet.INDIVIDUAL_SCALED_SCORES && !canAccessScaledScores) {
      return true;
    }

    return false;
  }
}
